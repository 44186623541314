import { useInfiniteQuery } from 'react-query';
import { client } from 'utils/api-client';

export function useFetchReviews(params) {
  return useInfiniteQuery(
    'home-services',
    (_, nextPage) => {
      return client(`services/getHomeServiceReview`, {
        params: {
          ...params,
          page: nextPage,
        },
      }).then((res) => {
        return res.service_reviews;
      });
    },
    {
      enabled: params.home_service_id,
      getFetchMore: (lastGroup) => {
        return lastGroup?.meta?.current_page < lastGroup?.meta?.last_page
          ? lastGroup?.meta?.current_page + 1
          : false;
      },
    }
  );
}
