import React from 'react';

const NewConstructionIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.0835 3C9.0835 2.44772 9.53121 2 10.0835 2H14.0835C14.6358 2 15.0835 2.44772 15.0835 3V3.08145C17.253 3.95962 18.949 5.76189 19.6848 8H20.0835C21.1881 8 22.0835 8.89543 22.0835 10V12C22.0835 12.7531 21.6672 13.409 21.0522 13.7502C20.9748 14.675 20.7547 15.5839 20.3984 16.4442C19.9461 17.5361 19.2832 18.5282 18.4475 19.364C17.6117 20.1997 16.6196 20.8626 15.5276 21.3149C14.4357 21.7672 13.2654 22 12.0835 22C10.9016 22 9.73128 21.7672 8.63935 21.3149C7.54741 20.8626 6.55526 20.1997 5.71953 19.364C4.88381 18.5282 4.22087 17.5361 3.76858 16.4441C3.41226 15.5839 3.19217 14.675 3.11481 13.7502C2.49978 13.409 2.0835 12.7531 2.0835 12V10C2.0835 8.89543 2.97893 8 4.0835 8H4.48217C5.21796 5.76189 6.91402 3.95962 9.0835 3.08145V3ZM9.0835 5.30274C7.64666 6.13382 6.58613 7.54349 6.22157 9.2133C6.1213 9.67257 5.71468 10 5.24459 10H4.0835V12H20.0835V10H18.9224C18.4523 10 18.0457 9.67257 17.9454 9.2133C17.5809 7.54349 16.5203 6.13382 15.0835 5.30274V9C15.0835 9.55228 14.6358 10 14.0835 10H10.0835C9.53121 10 9.0835 9.55228 9.0835 9V5.30274ZM5.15529 14C5.23833 14.5753 5.3929 15.1393 5.61634 15.6788C5.96812 16.5281 6.48374 17.2997 7.13375 17.9497C7.78376 18.5998 8.55543 19.1154 9.40471 19.4672C10.254 19.8189 11.1642 20 12.0835 20C13.0027 20 13.913 19.8189 14.7623 19.4672C15.6116 19.1154 16.3832 18.5998 17.0332 17.9497C17.6833 17.2997 18.1989 16.5281 18.5507 15.6788C18.7741 15.1393 18.9287 14.5753 19.0117 14L5.15529 14ZM11.0835 4V8H13.0835V4H11.0835Z"
      fill="#2B2B2B"
    ></path>
  </svg>
);

export default NewConstructionIcon;
