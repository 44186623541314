import React from 'react';
import './FeaturedProperties.css';
import { useLocation } from 'react-router-dom';
import FeaturedPropertyCard from 'components/FeaturedPropertyCard';
import useSearchProperties from 'hooks/useSearchProperties';
import Loader from 'components/Loader';

const FeaturedProperties = ({ isHome = false }) => {
  const [page, setPage] = React.useState(1);
  const { state } = useLocation();
  const [searchParams, setParams] = React.useState({
    property_type_id: undefined,
    property_category_id: undefined,
    state_id: undefined,
    min_price: 1000,
    max_price: 1000000000,
    no_bedroom: 1,
    no_bathroom: 1,
    no_toilet: 1,
    no_car_parking: 1,
    page_type: 'most_recent',
    ...state?.search,
  });

  const { data, isLoading } = useSearchProperties({
    page,
    ...searchParams,
    transaction_type: 'BUY',
    property_type_id: searchParams.property_type_id,
  });

  const properties = data?.featured_properties?.data;

  return (
    <section className="container">
      <div className="featured-properties">
        <h2 className="title">FEATURED PROPERTIES</h2>
        <p className="description">
          Explore our new listings featuring a variety of real estate and property
          types
        </p>
        <div className="property-grid">
          {isLoading ? (
            <Loader color="primary" />
          ) : (
            properties
              .slice(0, 3)
              .map((property) => (
                <FeaturedPropertyCard
                  key={property.propertyID}
                  property={property}
                  isHome={isHome}
                />
              ))
          )}
        </div>
      </div>
    </section>
  );
};

export default FeaturedProperties;
