/* eslint-disable camelcase */
import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

// components
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import JoinHomeServiceProfessional from 'pages/JoinHomeServiceProfessionals/HomeServiceProfessionalForm';
import { useModifyHomeService } from './hooks';

const EditHomeServicesPage = () => {
  const { state } = useLocation();
  const [modifyService, { isLoading }] = useModifyHomeService();

  const history = useHistory();

  const onSubmit = (values) => {
    const homeServiceData = {
      ...values,
      homeServiceID: state?.service?.homeServiceID,
    };

    const onSuccess = () => {
      history.push('/dashboard/myhomeservices');
      toast.success('Home service updated successfully ', {
        style: {
          background: '#d4edda',
          color: '#155724',
        },
      });
    };

    modifyService(homeServiceData, {
      onSuccess,
    });
  };

  return (
    <div className="proformpage">
      <Navbar />
      <div className="container proformpage-card">
        <div className="breadcrumb-wrapper">
          <Link to="/dashboard/myhomeservices">Home service</Link>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span> Edit home service </span>
        </div>
        <h1 className="submitlistingpage-header">Edit Home Services</h1>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <p className="proformpage-subheader">
              Our home affordability payments calculator can help you estimate what
              you can afford to spend on your new home.
            </p>
          </div>
        </div>
        <JoinHomeServiceProfessional onSubmit={onSubmit} isLoading={isLoading} />
      </div>
      <Footer />
    </div>
  );
};

export default EditHomeServicesPage;
