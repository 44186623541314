import React from 'react';
import PropTypes from 'prop-types';

// style
import './CalculatorStyle.css';
import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { Select } from 'components/common/Select';
import { Button } from 'components/common/Button';
import { useForm } from 'react-hook-form';

// images
import CalculatorImage from '../../static/images/calc.svg';

const Calculator = ({ title, decsription, onSubmit, isLoading }) => {
  const methods = useForm();
  return (
    <section className="calculator-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="calculator-image">
              <img src={CalculatorImage} alt="calculator" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="calculator-desc">
              <h1>{title}</h1>
              <p>{decsription}</p>
              <Form
                className="calculator-form"
                onSubmit={onSubmit}
                methods={methods}
              >
                <div className="form-flex">
                  <Input
                    label="Net Salary"
                    type="number"
                    containerClassName="mb-3"
                    className="form-control br-0"
                    name="net_salary"
                    id="net_salary"
                    placeholder=""
                    labelClassName="form-label calc-label"
                    validation={{ required: true }}
                  />
                  <Input
                    label="Down Payment/Equity"
                    type="number"
                    containerClassName="mb-3"
                    className="form-control br-0"
                    name="down_payment"
                    id="down_payment"
                    placeholder=""
                    labelClassName="form-label calc-label"
                    validation={{ required: true }}
                  />
                </div>
                <div className="form-flex">
                  <Select
                    label="Installment Options"
                    containerClassName="mb-3 w-50"
                    className="form-control br-0 mr-4"
                    name="installment_option"
                    id="installment_option"
                    placeholder=""
                    labelClassName="form-label calc-label"
                    validation={{ required: true }}
                    options={[
                      { id: 'off_plan', name: 'Off Plan' },
                      { name: 'Completed', id: 'completed' },
                      { name: 'Construction', id: 'under_construction' },
                    ]}
                  />
                </div>
                <Button
                  className="btn btn-danger br-0"
                  type="submit"
                  isLoading={isLoading}
                >
                  CALCULATE
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Calculator.propTypes = {
  onSubmit: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Calculator;
