import React from 'react';
import './DiscoverHelp.css';
import DiscoverHelpCard from 'components/DiscoverHelpCard';

const DiscoverHelp = () => {
  const helpOptions = [
    {
      title: 'GET HELP WITH YOUR DOWN PAYMENT',
      description:
        'You may be able to buy a home with just 3.5% down. Saving for that can be challenging—down payment assistance programs can help.',
      icon: '/assets/icons/down-payment.png',
      link: '/finance/affordability',
    },
    {
      title: 'TALK TO AN AGENT',
      description:
        'Whether you’ve found the perfect home or you’re just looking, we can help you form a strategy for moving forward. Talk to an Agent today.',
      icon: '/assets/icons/agent.png',
      link: '#',
      contact: '+2347030459037',
    },
    {
      title: 'FIND HOUSES YOU CAN AFFORD',
      description:
        'We can assist you in estimating your budget and recommend houses within your price range.',
      icon: '/assets/icons/houses.png',
      link: '/properties',
    },
    {
      title: 'RENT WITH THE OPTION TO BUY',
      description:
        'Are you paying rent right now? Do you know you can rent properties with an option to buy later with our Rent-To-Own Programs? Click here to find out more.',
      icon: '/assets/icons/Rent_to_own.png',
      link: '#',
      contact: '+2347030459037',
    },
  ];

  return (
    <main className="container">
      <div className="discover-help">
        <h2 className="title">DISCOVER HOW WE CAN HELP</h2>
        <div className="help-cards">
          {helpOptions.map((option, index) => (
            <DiscoverHelpCard
              key={index}
              title={option.title}
              description={option.description}
              icon={option.icon}
              link={option.link}
              contact={option?.contact}
            />
          ))}
        </div>
      </div>
    </main>
  );
};

export default DiscoverHelp;
