import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function useCityList(params) {
  return useQuery(
    ['city-list', params],
    (_, fieldParams) =>
      client(`params/getCity`, { params: fieldParams }).then((res) => res),
    {
      enabled: params.stateID,
    }
  );
}
