import React from 'react';
import './PreApproval.css';
import { useHistory } from 'react-router-dom';

const PreApproval = () => {
  const history = useHistory();
  const handleApproval = () => {
    history.push('/get-approval');
  };

  return (
    <main className="container">
      <div className="pre-approval-container row p-0">
        <div className="pre-approval-image col-md-8 border p-0">
          <img
            src="/assets/pre-approval.jpg"
            className="w-100 h-100"
            alt="Home Loan"
          />
        </div>
        <div className="pre-approval-content col-md-4">
          <h2 className="title mt-4">Need a home loan?</h2>
          <p className="description">
            Find a lender who can offer competitive mortgage rates and help you with
            pre-approval.
          </p>
          <button className="pre-approval-button" onClick={handleApproval}>
            Get pre-approved now
          </button>
        </div>
      </div>
    </main>
  );
};

export default PreApproval;
