export const setAuthUser = (data) => {
  try {
    const { token, userInfo } = data;

    localStorage.setItem('user', JSON.stringify(userInfo));
    localStorage.setItem('token', token.access_token);
    return userInfo;
  } catch (error) {
    return null;
  }
};

export const getUserFromStorage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user;
};

export const updateUser = (data) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const updatedUserDetails = { ...user, ...data };
  localStorage.setItem('user', JSON.stringify(updatedUserDetails));
  return updatedUserDetails;
};

export const removeUserFromStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  window.location.replace('/');
};
