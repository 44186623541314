import React from 'react';
import PropTypes from 'prop-types';

// component
import FilterProperties from 'pages/Home/FilterProperties';
// import SearchBar from '../SearchBar';

// import BannerImage from '../../static/images/buy-sell.png';
import BannerImage from '../../static/images/buy-hero-image.jpg';
// style
import './SecondaryBannerStyle.css';

const SecondaryBanner = ({ title, children, banner }) => {
  return (
    <section className="secondarybanner">
      <img src={BannerImage} alt="banner" />
      <div className="secondarybanner-overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-9">
              <div className="secondarybanner-content">
                <h1>{banner?.title || title}</h1>
                <p>
                  {/* <PopulatePageDescription title={title}/> */}

                  {title === 'BUY' ? (
                    <>
                      Purchasing a home doesn’t have to be difficult or overtly
                      expensive. We are here to guide you all the way. Search our
                      property listings to view all property types for sale. We help
                      you find what you want.
                    </>
                  ) : (
                    <>
                     Here’s where you search for suitable and affordable properties for rent.
                     We are here to guide you all the way to ensure that your needs are met.
                    </>
                  )}
                </p>
                <>
                  {children || (
                    <>
                      {title === 'BUY' || title === 'RENT' ? (
                        <FilterProperties />
                      ) : (
                        null
                      )}
                    </>
                  )}
                </>

                {/* <SearchBar/> */}
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
      </div>
    </section>
  );
};

SecondaryBanner.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  banner: PropTypes.shape({
    primary_image: PropTypes.string,
    title: PropTypes.string,
  }),
};

SecondaryBanner.defaultProps = {
  children: null,
  banner: null,
};

/*function BuyPage(props) {
  return (
    <h1>
      Purchasing a home doesn’t have to be difficult or overtly expensive. We are
      here to guide you all the way. Search our property listings to view all
      property types for sale. We help you find what you want.
    </h1>
  );
}

function SellPage(props) {
  return (
    <h1>Are you looking to sell your Property? We help you sell faster/quickly.</h1>
  );
}*/

/*function PopulatePageDescription(title) {
  if (title == 'BUY') {
    return <BuyPage />;
  }
  if (title == 'SELL') {
    return <SellPage />;
  }
}*/

export default SecondaryBanner;
