import * as React from 'react';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import Pagination from 'components/Pagination';
import SalesCard from 'components/SalesCard';
import useFetchWishlist from 'hooks/useFetchWishlist';
import { formateDate } from 'utils/dateUtils';

export default function MyWishlist() {
  const [page, handlePageChange] = React.useState(1);
  const { data } = useFetchWishlist({ page });
  return (
    <div className="userdashboard-tabcontent">
      <TabProvider defaultTab="saveddate">
        <div className="my-tabs">
          <div className="container">
            <div className="newlistings-flex">
              <span className="userdashboard_tabheader">PROPERTIES BY DATE</span>
              <div className="newlistings-tabs">
                <TabList className="tablist">
                  <Tab tabFor="saveddate" className="tab-btn">
                    Saved Date
                  </Tab>
                  <Tab tabFor="highestprice" className="tab-btn">
                    Highest Price
                  </Tab>
                  <Tab tabFor="lowestprice" className="tab-btn">
                    Lowest Price
                  </Tab>
                </TabList>
              </div>
            </div>
          </div>
          <div className="wrapper userdashboardpage-wrapper">
            <TabPanel tabId="saveddate">
              <div className="container">
                <div className="row">
                  {data?.myWishList?.data?.map((item) => (
                    <div className="col-md-4 aux-margin">
                      <span className="misc-date">
                        {formateDate(item.date_created)}
                      </span>
                      <SalesCard {...item} />
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="highestprice">
              <div className="container">
                <div className="row">
                  {data?.myWishList?.data?.map((item) => (
                    <div className="col-md-4 aux-margin">
                      <span className="misc-date">
                        {formateDate(item.date_created)}
                      </span>
                      <SalesCard image="https://images.unsplash.com/photo-1576941089067-2de3c901e126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1143&q=80" />
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="lowestprice">
              <div className="container">
                <div className="row">
                  {data?.myWishList?.data?.map((item) => (
                    <div className="col-md-4 aux-margin">
                      <span className="misc-date">
                        {formateDate(item.date_created)}
                      </span>
                      <SalesCard image="https://images.unsplash.com/photo-1576941089067-2de3c901e126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1143&q=80" />
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>
          </div>
        </div>
      </TabProvider>
      <div className="centralize-div">
        {data?.myWishList?.meta?.total ? (
          <Pagination
            {...{ ...data?.myWishList?.meta }}
            handlePageChange={handlePageChange}
          />
        ) : null}
      </div>
    </div>
  );
}
