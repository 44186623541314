import React from 'react';
import './Card.css';

const Card = ({ stepNumber, title, description }) => {
  return (
    <div className="how-card">
      <div className="how-card-step-number">{stepNumber}</div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Card;
