import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from 'components/PrivateRoute';

import { routes } from './routes';
import './App.css';
// const tawkTo = require("tawkto-react");

function App(props) {
  React.useEffect(() => {
    var Tawk_API = Tawk_API || {};
    const Tawk_LoadStart = new Date();
    (function () {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6027d8be9c4f165d47c2f2d8/1eudqdako';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);
  return (
    <div>
      <Switch>
        {routes.map(({ path, component: Component, exact, authRequired }) => {
          if (authRequired) {
            return (
              <AuthenticatedRoute path={path} exact={exact} key={path}>
                <Component />
              </AuthenticatedRoute>
            );
          }
          return (
            <Route path={path} key={path} exact={exact}>
              <Component />
            </Route>
          );
        })}
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
