import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

// components
import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { Button } from 'components/common/Button';
import Modal from 'components/Modal';
import { useShareProperty } from './hooks';

export default function ShareProperty(props) {
  const { open, closeModal, propertyId } = props;
  const methods = useForm({
    defaultValues: {
      email: '',
    },
  });

  const [shareProperty, { isLoading }] = useShareProperty();

  const handleSubmit = (values) => {
    shareProperty(
      { ...values, property_id: propertyId },
      {
        onSuccess: () => {
          toast.success('Property has been share successfully', {
            style: {
              background: '#d4edda',
              color: '#155724',
            },
          });
          closeModal();
        },
      }
    );
  };

  return (
    <Modal open={open} closeModal={closeModal} title="Share Via Email">
      <Form className="row g-3" onSubmit={handleSubmit} methods={methods}>
        <Input
          containerClassName="col-md-12"
          className="form-control br-0"
          type="email"
          label="Email"
          name="email"
          validation={{ required: true }}
        />
        <Button className="btn btn-danger br-0" type="submit" isLoading={isLoading}>
          Share
        </Button>
      </Form>
    </Modal>
  );
}

ShareProperty.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
};
