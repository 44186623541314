import React, { useState } from 'react';
import PriceRange from './PriceRange';
import DownPayment from './DownPayment';
import EmploymentStatus from './EmploymentStatus';
import EmailAddress from './EmailAddress';
import PhoneNumber from './PhoneNumber';

const Contact = () => {
  const [detailStep, setDetailStep] = useState(1);

  return (
    <div>
      {detailStep === 1 ? <EmailAddress setDetailStep={setDetailStep} /> : null}
      {detailStep === 2 ? <PhoneNumber setDetailStep={setDetailStep} /> : null}
    </div>
  );
};

export default Contact;
