import React from 'react';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { Link, useLocation } from 'react-router-dom';

const AdvertUploadVerificationPage = () => {
  const { state } = useLocation();
  return (
    <div className="advertuploadsuccess">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6">
            <div className="advertuploadsucess-content">
              <h1>Document Verified & Accepted</h1>
              <p>
                Our home affordability payments calculator can help you estimate what
                you can afford to spend on your new home.
              </p>
              <div className="advertuploadsucess-card">
                <div className="flex-center">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.2857 55.4686L20 41.18L24.0371 37.1429L34.2857 47.3886L55.9571 25.7143L60 29.7572L34.2857 55.4686Z"
                      fill="#cc5500"
                    />
                    <path
                      d="M40 0C32.0888 0 24.3552 2.34596 17.7772 6.74121C11.1992 11.1365 6.07234 17.3836 3.04484 24.6927C0.0173313 32.0017 -0.774802 40.0444 0.768607 47.8036C2.31202 55.5628 6.12164 62.6902 11.7157 68.2843C17.3098 73.8784 24.4372 77.688 32.1964 79.2314C39.9556 80.7748 47.9983 79.9827 55.3073 76.9552C62.6164 73.9277 68.8635 68.8008 73.2588 62.2228C77.654 55.6448 80 47.9112 80 40C80 29.3913 75.7857 19.2172 68.2843 11.7157C60.7828 4.21427 50.6087 0 40 0V0ZM40 74.2857C33.2189 74.2857 26.5901 72.2749 20.9519 68.5075C15.3136 64.7401 10.9192 59.3855 8.32415 53.1206C5.72914 46.8557 5.05017 39.962 6.37309 33.3112C7.69601 26.6604 10.9614 20.5513 15.7564 15.7563C20.5513 10.9614 26.6604 7.696 33.3112 6.37307C39.962 5.05015 46.8557 5.72912 53.1206 8.32413C59.3855 10.9191 64.7402 15.3136 68.5075 20.9519C72.2749 26.5901 74.2857 33.2189 74.2857 40C74.2857 49.0931 70.6735 57.8138 64.2437 64.2436C57.8138 70.6735 49.0931 74.2857 40 74.2857Z"
                      fill="#cc5500"
                    />
                  </svg>
                </div>
                <h1>Advert Document Accepted</h1>
                <p>
                  Our home affordability payments calculator can help you estimate
                  what you can afford to spend on your new home.
                </p>
                <div className="flex-center my-3">
                  <Link
                    to={{
                      pathname: '/join_as_pro/subscription/payment',
                      state: {
                        paymentData: { ...state },
                      },
                    }}
                    className="btn br-0 btn-orange"
                  >
                    PROCEED TO PAYMENT
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdvertUploadVerificationPage;
