import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import React from 'react';

// style
import './TermsPageStyle.css';

const TermsPage = () => {
  return (
    <div className="termspage">
      <Navbar />
      <div className="container">
        <div className="termspage-container">
          <h1>Terms & Conditions</h1>
          <h2>
            ACCESSING THIS PLATFORM OR VIEWING ANY OF ITS CONTENTS SHALL CONSTITUTE
            YOUR AGREEMENT WITH THE TERMS BELOW.
          </h2>
          <h2>
            IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS BELOW, DO NOT ACCESS THIS
            SITE, OR ANY PAGES THEREOF.
          </h2>
          <div className="termspage-content">
            <h1>1. Use of Information and Materials </h1>
            <p>
              Any person is hereby authorized to view the information available from
              this website for informational purposes only. No part of this
              information may be published on any Website or in any other media,
              redistributed, copied, or reproduced without the prior written consent
              of 100Bricks.ng. The information contained on this portal is subject to
              change without notice. We reserve the right to modify, suspend or
              discontinue, temporarily or permanently, the site (or any part thereof)
              from time to time, for any or no reason and without notice.
              100Bricks.ng will not be liable for any modification, suspension or
              discontinuance of the site. The information and materials contained on
              the site is subject to change.
            </p>
            <h1>2. No Warranties</h1>
            <p>
              The information and materials contained on this portal are provided "as
              is" without warranty of any kind, either express or implied, including
              without limitation, any warranty of accuracy, adequacy or completeness
              of the information and materials, title, non-infringement of third
              party rights, merchantability, fitness for a particular purpose and
              freedom from computer virus.
            </p>
            <p>
              Under no circumstance shall 100Bricks.ng, or any of its officers,
              directors and employees, be liable to you for anything arising out of
              or in any way connected with your use of this Platform, whether such
              liability is under contract, tort or otherwise, and 100Bricks.ng
              including its officers, directors and employees shall not be liable for
              any indirect, consequential or special liability arising out of or in
              any way related to your use of this Platform.
            </p>
            <p>
              By purchasing any Property advertised on this website, the Purchaser
              confirms that he has carried out all necessary due diligence on the
              Property and its ownership, and has entered into the purchase
              transaction fully acquainted and satisfied with the title, ownership
              and all other necessary facts relating to the Property.
            </p>
            <p>
              100bricks.ng, its partners, associates and affiliates do not in any way
              guarantee or make any warranty whatsoever as regards the title,
              ownership or any fact or law whatsoever relating to any Property
              advertised herein. All prospective purchasers are advised to conduct
              necessary due diligence and seek appropriate legal advice before
              entering into any transaction on the Properties listed on this platform
            </p>
            <h1>3. Third Party Links</h1>
            <p>
              100Bricks.ng is happy to make available to you links to other partner
              sites Nevertheless, 100Bricks.ng, makes no representations or
              warranties regarding non-100Bricks.ng sites or the companies
              maintaining them. 100Bricks.ng does not control or monitor such sites
              and thus does not endorse the content or the use of such sites. If you
              choose to access non-100Bricks.ng sites through links on our pages, you
              accept responsibility for all related risks.
            </p>
            <h1>4. Member Account; Password and Security</h1>
            <p>
              If you use our services, tools or programs on the sites requiring
              registration or account set up, we may supply you with a password and
              account designation upon completing the registration process. Any user
              ID and password you may have for this Website are confidential and you
              must maintain confidentiality as well. You are responsible for
              maintaining the confidentiality and security of the password and
              account, and are fully responsible for all activities that occur under
              your password or account. You agree to immediately notify us of any
              unauthorized use of your password or account, or any other breach of
              security of which you are aware, and to ensure that you properly close
              out of your account at the end of each session. If your status as a
              user of the site is terminated, you must:
            </p>
            <ul>
              <li>
                Cease using the site and any information obtained from the site.
              </li>
              <li>
                Destroy all copies of your account information, password and any
                information obtained from the site.
              </li>
            </ul>
            <span>
              We assume no liability for any loss or damage arising from your failure
              to comply with this section.
            </span>
            <p className="pt-2">
              You are specifically restricted from using this Website in any way that
              impacts user access to this Website, using this Website contrary to
              applicable laws and regulations, or in any way may cause harm to the
              Website, or to any person or business entity engaging in any data
              mining, data harvesting, data extracting or any other similar activity
              in relation to this Website.
            </p>
            <h1>5. Email</h1>
            <p>
              Email messages sent to 100Bricks.ng over the Internet cannot be
              guaranteed to be completely secure. 100Bricks.ng is not responsible for
              any damages incurred by users if they send a message to the bank, or if
              the bank sends a message to them at their request, over the Internet.
              The bank is not responsible in any way for direct, indirect, special or
              consequential damages arising out of the use of this portal.
            </p>
            <h1>6. How We Can Change These Conditions</h1>
            <p className="pt-2">
              We may change these conditions for any reason at any time by publishing
              the change on this portal.
            </p>
            <p>
              We may send written notices to you using the last e-mail address you
              provided. You must let us know immediately you change your e-mail
              address, to ensure that we have your current e-mail address.
            </p>
            <h2>GENERAL</h2>
            <p>
              <span>100Bricks.ng Virtual Sales Tool</span> is available to all
              irrespective of location, age or gender. For all payments that is made
              on this platform, we have added necessary security features to guard
              against all form of cyber attacks.
            </p>
            <p>
              We strongly recommend that you print a copy of these conditions for
              your reference.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsPage;
