import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setAuthUser,
  getUserFromStorage,
  removeUserFromStorage,
  updateUser,
} from 'utils/auth';
import useLogin from 'hooks/useLogin';
import useLogout from 'hooks/useLogout';
import useSocialLogin from 'hooks/useSocialLogin';
import AuthClientProvider from 'utils/auth-provider';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [loginUser, loginInfo] = useLogin();
  const [logoutCurrentUser] = useLogout();
  const [socialSignIn, socialSignInInfo] = useSocialLogin();
  const [user, setUser] = useState(null);
  const history = useHistory();
  const { state } = useLocation();
  const [redirectAfterLogin, setRedirectAfterLogin] = React.useState('');

  const saveUserData = React.useCallback(
    (data) => {
      const authUser = setAuthUser(data);
      setUser(authUser);
      const redirect = redirectAfterLogin || '/dashboard';
      history.replace(redirect);
    },
    [history, redirectAfterLogin]
  );

  React.useEffect(() => {
    if (state?.from) {
      setRedirectAfterLogin(state.from);
    }
  }, [state]);

  const login = React.useCallback((form) => loginUser(form).then(saveUserData), [
    loginUser,
    saveUserData,
  ]);

  const socialLogin = React.useCallback(
    (form) => {
      const { redirectAfterLogin: redirect, ...rest } = form;
      setRedirectAfterLogin(redirect);
      socialSignIn(rest).then((data) => {
        saveUserData(data);
      });
    },
    [socialSignIn, saveUserData]
  );

  const logout = React.useCallback(
    (form) => {
      logoutCurrentUser(form);
      removeUserFromStorage();
      setUser(null);
      history.push('/');
    },
    [logoutCurrentUser, history]
  );

  const updateUserDetails = React.useCallback((data) => {
    const userData = updateUser(data);
    setUser(userData);
  }, []);

  useEffect(() => {
    const userData = getUserFromStorage();
    if (userData) setUser(userData);
  }, []);

  useEffect(() => {
    if (redirectAfterLogin) {
      AuthClientProvider.redirectAfterLogin = redirectAfterLogin;
    }
  }, [redirectAfterLogin]);

  const userData = user || getUserFromStorage();

  return (
    <AuthContext.Provider
      value={{
        loginInfo,
        login,
        socialLogin,
        user: userData,
        isAuthenticated: !!userData,
        saveUserData,
        updateUserDetails,
        logout,
        provider: AuthClientProvider,
        socialSignInInfo,
        setRedirectAfterLogin,
      }}
      {...props}
    />
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
