import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import usePropertyPaymentHistory from 'hooks/usePropertyPaymentHistory';
import { moneyFormat } from 'utils/moneyFormat';

// components
import Navbar from 'components/Navbar';
import DashboardBanner from 'components/DashboardBanner';
import Footer from 'components/Footer';
import Loader from 'components/Loader';

// style
import './PaymentProfileDetailsPageStyle.css';

const PaymentProfileDetailsPage = () => {
  const { state } = useLocation();
  const { data, isLoading } = usePropertyPaymentHistory({
    property_id: state?.propertyId,
  });

  return (
    <div className="paymentprofiledetailspage">
      <Navbar />
      <DashboardBanner />
      <div className="container">
        <div className="breadcrumb-wrapper">
          <NavLink to="/finance">Manage Finance</NavLink>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/finance/paymentprofile">My Payment Profile</Link>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/">Apartment at 42 Montgomery Road Yaba</Link>
        </div>
        <div className="paymenprofiledetailspage-desc">
          {isLoading ? (
            <Loader color="primary" />
          ) : (
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-10">
                <div className="paymentprofiledetailspage-card">
                  <h1>
                    PAYMENT PROFILE FOR THE RESIDENTIAL APARTMENT AT 42 MONTGOMERY
                    SABO, YABA
                  </h1>
                  <span>property id: 123456bd</span>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" />
                          <th scope="col">Amount(₦)</th>
                          <th scope="col">Status</th>
                          <th scope="col">Date</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.paymentHistory?.map((payment) => (
                          <tr>
                            <th scope="row">Price</th>
                            <td>{moneyFormat(payment.amount)}</td>
                            <td>
                              {payment.payment_status === 1 ? 'Paid' : 'Not Paid'}
                            </td>
                            <td>
                              {new Date(payment.updated_at).toLocaleDateString()}
                            </td>
                            <td>
                              <button className="btn btn-action" type="button">
                                PRINT RECEIPT
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentProfileDetailsPage;
