/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { LoadingIcon } from 'static';
import OldBricksLogo from 'static/images/aso-logo.svg';
import { useAuth } from 'context/auth-context';
import ResetPasswordForm from './ResetPasswordForm';
import useVerifyResetPasswordToken from 'hooks/useVerifyResetPasswordToken';

const ForgotPassword = () => {
  const { isAuthenticated } = useAuth();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const { data, isLoading, error } = useVerifyResetPasswordToken({ token, email });

  if (isAuthenticated) return <Redirect to="/dashboard" />;

  if (error) {
    return <div className="container text-center">Link has expired or invalid</div>;
  }

  if (isLoading) {
    return (
      <div className="confirm-account-page">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <section className="auth">
      <div className="container">
        <div className="row">
          <div className="col-md-4" />
          <div className="col-md-4">
            <div className="register-container">
              <div className="app-logo">
                <Link to="/">
                  <img className="logo-image" src={OldBricksLogo} alt="App Logo" />
                </Link>
              </div>
              <p className="register-intro">Kindly your enter your new password.</p>

              <ResetPasswordForm
                userId={data?.userInfo?.userID}
                email={email}
                token={data?.token?.access_token}
              />
              <p className="already-have-an-account">
                DON'T HAVE AN ACCOUNT?{' '}
                <span>
                  <Link to="/register">SIGN UP</Link>
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-4" />
        </div>
      </div>
    </section>
  );
};
export default ForgotPassword;
