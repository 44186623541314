import React from 'react';
import { NavLink, Link, useLocation, 
//  useHistory 
} from 'react-router-dom';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { TextField } from 'components/common/TextField';
import { Select } from 'components/common/Select';
import { FileInput } from 'components/common/FileInput';
import { Button } from 'components/common/Button';
import { useForm } from 'react-hook-form';
import { useUploadAdvert } from './hooks';
import UploadAvertSuccess from './UploadAvertSuccess';

// style
import './AdvertUploadPageStyle.css';

const AdvertUploadPage = () => {
  const [upload, { //isLoading, 
    isSuccess }] = useUploadAdvert();
  const { state } = useLocation();
  const methods = useForm({
    defaultValues: {
      ...state,
      amount: state.amount,
    },
  });

  console.log(state, 'success page');

  const onSubmit = (values) => {
    upload(
      { ...values, advert_id: state?.subscriptionID },
      {
        onSuccess: () => {},
      }
    );
  };

  return (
    <div className="advertuploadpage">
      <Navbar />
      {isSuccess ? (
        <UploadAvertSuccess />
      ) : (
        <div className="container">
          <div className="breadcrumb-wrapper">
            <NavLink to="/advert">Advertise</NavLink>
            <span>
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 11L6.5 6L1.5 1"
                  stroke="black"
                  strokeOpacity="0.4"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <Link to="/advert/subscription">Subscription Plan</Link>
            <span>
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 11L6.5 6L1.5 1"
                  stroke="black"
                  strokeOpacity="0.4"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <Link to="/advert/upload">Upload advert document</Link>
          </div>
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <div className="advertuploadpage-content">
                <h1>Upload Document For Advert</h1>
                <p>
                  Our home affordability payments calculator can help you estimate
                  what you can afford to spend on your new home.
                </p>
                <Form methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <Select
                      label="SELECT CATEGORY"
                      name="category"
                      id="category"
                      aria-label="Default select example"
                      className="form-select br-0"
                      labelClassName="form-label"
                      options={[
                        { id: 1, name: 'One' },
                        { id: 2, name: 'Two' },
                        { id: 3, name: 'Three' },
                      ]}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        containerClassName="mb-4"
                        label="ENTER TITLE"
                        labelClassName="form-label"
                        name="title"
                        id="title"
                        className="bg-white form-control br-0"
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        containerClassName="mb-4"
                        label="ENTER PRICE"
                        labelClassName="form-label"
                        name="amount"
                        id="title"
                        disabled
                        className="bg-white form-control br-0"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <TextField
                      label="ENTER DESCRIPTION"
                      containerClassName="col-12 mb-4"
                      labelClassName="form-label"
                      className="form-control br-0"
                      name="description"
                    />
                  </div>
                  <div className="row">
                    <FileInput
                      containerClassName="col-12 mb-4"
                      name="file"
                      label="UPLOAD ADVERT DOCUMENT"
                      description="Upload Image"
                      className="submitlist-uploader"
                      labelClassName="form-label"
                      allowedFormats={['PNG, JPG, PDF, MP4, AVL, TXT are supported']}
                    />
                  </div>
                  <div className="justify-center mb-5">
                    <Button type="submit" className="btn btn-orange py-2 px-5 br-0">
                      UPLOAD
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default AdvertUploadPage;
