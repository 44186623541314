/* eslint-disable camelcase */
import React from 'react';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import Carousel from 'react-multi-carousel';
import HomeServicesBanner from 'components/HomeServicesBanner';
import { useHomeServiceCategories } from 'hooks/useHomeServiceCategories';
import { useHomeServices } from 'hooks/useHomeServices';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import './CategoryPageStyle.css';
import { Link } from 'react-router-dom';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CategoryPage = () => {
  const [serviceCategory, setServiceCategory] = React.useState();
  const { data } = useHomeServiceCategories();
  const { data: homeServicesData } = useHomeServices({ category: serviceCategory });

  return (
    <div className="homeservicespage">
      <Navbar />
      <HomeServicesBanner bannerHeader="HOME SERVICES" />
      <div className="container homeservices-container">
        <TabProvider defaultTab="showall">
          <div className="my-tabs">
            <div className="navigation-tabs">
              <TabList className="tab-list">
                <Tab tabFor="showall" className="tab-btn">
                  SHOW ALL
                </Tab>
                <Tab tabFor="findprofessionals" className="tab-btn">
                  FIND PROFESSIONALS
                </Tab>
                <Tab tabFor="findvendord" className="tab-btn">
                  FIND VENDORS
                </Tab>
                <Tab tabFor="propertyagents" className="tab-btn">
                  PROPERTY AGENTS
                </Tab>
                <Tab tabFor="legalpractioners" className="tab-btn">
                  LEGAL PRACTIONERS
                </Tab>
              </TabList>
            </div>
            <div className="wrapper">
              <TabPanel tabId="showall">
                <div className="container">
                  <div className="browse-products">
                    <div className="category-select flex justify-between flex-wrap w-100">
                      <h1>Category</h1>
                      <div className="flex flex-start">
                        <label htmlFor="staticEmail" className="col-form-label mr-2">
                          All Categories:
                        </label>
                        <div className="">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={({ target }) =>
                              setServiceCategory(target.value)
                            }
                          >
                            <option selected>All Categories</option>
                            {data?.categories?.map(({ id, name }) => (
                              <option value={id}>{name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {homeServicesData?.homeServices?.data?.length ? (
                        <Carousel
                          swipeable
                          draggable
                          partialVisible
                          showDots={false}
                          responsive={responsive}
                          ssr
                          keyBoardControl
                          customTransition="all .5s linear"
                          transitionDuration={500}
                          containerClass="carousel-container"
                          removeArrowOnDeviceType={['tablet', 'mobile']}
                        >
                          {homeServicesData?.homeServices?.data?.map(
                            ({ homeServiceID, service_title }) => (
                              <div className="col-md-4" key={homeServiceID}>
                                <div className="deptcard">
                                  <img
                                    src="https://muuto-static.imgix.net/pub/media/pw-product-images/cover-lounge-chair-master-cover-lounge-chair-1546422022-67934488.png?w=600"
                                    alt="card"
                                  />
                                  <Link to="/">{service_title}</Link>
                                </div>
                              </div>
                            )
                          )}
                        </Carousel>
                      ) : null}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel tabId="findprofessionals" />
            </div>
          </div>
        </TabProvider>
      </div>
      <Footer />
    </div>
  );
};

export default CategoryPage;
