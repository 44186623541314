import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

export const CheckBox = ({
  id,
  label,
  name,
  value,
  placeholder,
  containerClassName,
  labelClassName,
  validation,
  ...rest
}) => {
  const { register } = useFormContext();

  return (
    <div className={containerClassName}>
      <label htmlFor={id} className={labelClassName || 'form-label'}>
        {label}
      </label>
      <input
        id={id}
        placeholder={placeholder}
        name={name}
        value={value}
        ref={register}
        {...rest}
        type="checkbox"
        className={`${rest.className}`}
      />
    </div>
  );
};

CheckBox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  validation: PropTypes.shape({}),
  labelClassName: PropTypes.string,
};

CheckBox.defaultProps = {
  id: '',
  label: '',
  value: '',
  placeholder: '',
  containerClassName: '',
  validation: {},
  labelClassName: '',
};
