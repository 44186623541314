/* eslint-disable no-unused-expressions */
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import Carousel from 'react-elastic-carousel';
import { IoIosShareAlt } from 'react-icons/io';
import parse from 'html-react-parser';
import gtag from 'ga-gtag';
// components
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
// import LocationInfo from 'components/LocationInfo';
import SalesCard from 'components/SalesCard';
import { Button } from 'components/common/Button';
import Modal from 'components/Modal';
import { moneyFormat } from 'utils/moneyFormat';
import { useAuth } from 'context/auth-context';

// icons
import CameraIcon from 'static/images/camera.svg';
import YoutubeIcon from 'static/images/youtube.svg';
import { pluraLize } from 'utils/textUtils';
import RegisterForm from 'pages/Register/RegisterForm';
import Loader from 'components/Loader';
import useSignUp from 'hooks/useSignUp';
// import AdvertPlansPage from 'pages/AdvertPlansPage';
import LeadForm from './LeadForm';
import PropertyComment from './PropertyComment';
import MakeOffer from './MakeOffer';
import ShareProperty from './ShareProperty';

import { useAddToWishList, useGetPropertyDetails, useModalState } from './hooks';

// style
import './ProductPageStyle.css';
import LoginForm from '../Login/LoginForm';
import PropertyDetailScript from 'components/PropertyDetailScript';

const ProductPage = () => {
  const auth = useAuth();
  const [signUp, signUpInfo] = useSignUp();
  const [showModal, setShowModal] = React.useState(false);
  const [showRegisterForm, setShowRegisterForm] = React.useState(false);

  const [modalState, updateModalState] = useModalState();

  const closeModal = () => updateModalState({ type: 'close' });

  const toggleModal = () => setShowModal(!showModal);

  const toggleRegisterFormModal = () => {
    setShowModal(false);
    setShowRegisterForm(!showRegisterForm);
  };

  const { id } = useParams();
  const { data, isLoading: loadingDetails, error, status } = useGetPropertyDetails({
    propertyId: id,
  });
  const [addToWishList, { addingToWishList, isSuccess }] = useAddToWishList();

  const history = useHistory();
  const { user } = useAuth();

  const wishlistFunc = () => {
    if (auth.isAuthenticated) {
      addToWishList({ propertyID: property.propertyID });
    } else {
      updateModalState({ type: 'wishlist' });
    }
  };

  const viewBgDocFunc = () => {
    if (auth.isAuthenticated) {
      history.push({
        pathname: `/property-info/viewbgdocs/${property.propertyID}`,
        state: {
          propertyId: property.propertyID,
        },
      });
    } else {
      updateModalState({ type: 'backgroundInfo' });
    }
  };

  const trackProductPage = () => {
    gtag('event', 'product_page', {
      method: 'product_page',
    });
  };

  React.useEffect(() => {
    trackProductPage();
    if (isSuccess) {
      history.push('/dashboard?view=wishlist');
    }
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [isSuccess, history]);

  const property = data?.property;

  const handleBuyProperty = () => {
    updateModalState({ type: 'buyProperty' });
    /* if (auth.isAuthenticated) {
      history.push({
        pathname: '/join_as_pro/subscription/payment',
        state: {
          paymentData: {
            propertyID: property.propertyID,
            payment_plan_id: 1,
            amount: property.price,
            paymentPath: 'payments/propertyPayment',
          },
        },
      });
      return;
    } */
    toggleModal(true);
    history.push({
      pathname: window.location.pathname,
      state: { from: `/property/${id}`, property },
    });
  };

  if (loadingDetails) {
    return <Loader color="primary" className="p-4" />;
  }

  if (error) {
    return (
      <section className="productpage">
        <Navbar />
        {error && (
          <div
            className="alert alert-danger mb-2 mt-4"
            style={{ textAlign: 'center' }}
            role="alert"
          >
            {status === 500 ? error?.message : error?.Detail}
          </div>
        )}
      </section>
    );
  }

  const images = [
    property?.primary_image_link,
    ...property.other_image_links.map(({ link }) => link),
  ];

  const isPropertyOwner = property?.owner?.id === user?.userID;

  return (
    <section className="productpage">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="productpage-image">
              <TabProvider defaultTab="propertyimage">
                <div className="productpage-tabs_content">
                  <span>Property presented by:</span>
                  {/* <span>{property?.owner?.name} & Aso Property</span> */}
                  <span>100 Bricks</span>
                  <div className="wrapper">
                    <TabPanel tabId="propertyimage">
                      <div className="propertyimage">
                        <Carousel itemsToShow={1} disableArrowsOnEnd>
                          {images.map((url, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <img key={index} src={url} alt="property" />
                          ))}
                        </Carousel>
                      </div>
                    </TabPanel>
                    <TabPanel tabId="propertyvideo">
                      <div className="propertyvideo">
                        <iframe
                          width="100%"
                          height="500"
                          title="house inspection"
                          src={property?.video_link?.replace('watch?v=', 'embed/')}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    </TabPanel>
                  </div>
                  <div className="productpage-tabs">
                    <TabList className="productpage-tablist">
                      <Tab
                        tabFor="propertyimage"
                        className="productpage-tab_btn tabbtn-divider"
                        onClick={() => updateModalState({ type: 'share' })}
                      >
                        <IoIosShareAlt
                          className="productpage-icon"
                          style={{ fontSize: '22px' }}
                        />{' '}
                        Share
                      </Tab>
                      <Tab
                        tabFor="propertyimage"
                        className="productpage-tab_btn tabbtn-divider"
                      >
                        <img
                          className="productpage-icon"
                          src={CameraIcon}
                          alt="Camera Icon"
                        />{' '}
                        {images?.length} Images
                      </Tab>
                      {property.video_link && (
                        <Tab tabFor="propertyvideo" className="productpage-tab_btn">
                          <img
                            className="productpage-icon"
                            src={YoutubeIcon}
                            alt="Camera Icon"
                          />{' '}
                          1 Video
                        </Tab>
                      )}
                    </TabList>
                  </div>
                </div>
              </TabProvider>
            </div>
            <div className="property-features">
              {property.no_bedroom ? (
                <span>{pluraLize('Bedrooms', property.no_bedroom)} </span>
              ) : null}
              {property.no_bathroom ? (
                <span>{pluraLize('Bathrooms', property.no_bathroom)}</span>
              ) : null}
              {property.no_kitchen ? (
                <span>{pluraLize('Kitchens', property.no_kitchen)} </span>
              ) : null}
              {property.no_toilet ? (
                <span>{pluraLize('Toilet', property.no_toilet)}</span>
              ) : null}
              {property.car_parking ? (
                <span>{pluraLize('Car parking space', property.car_parking)}</span>
              ) : null}
            </div>
            <div>
              <div className="paymentplan">
                <h4>
                  Statistics of price of houses in this location on our platform:
                </h4>
                <div className="paymentplan-flex">
                  <div className="paymentplan-item">
                    <span>Average Sales Price</span>
                    <p>₦{moneyFormat(data?.avgSalesPrice)}</p>
                  </div>
                  <div className="paymentplan-item">
                    <span>Average Rental Price</span>
                    <p>₦{moneyFormat(data?.avgRentalPrice)}</p>
                  </div>
                </div>
                <div className="paymentplan-flex">
                  <div className="paymentplan-item">
                    <span>Minimum sales price</span>
                    <p>₦{moneyFormat(data?.minSalePrice)}</p>
                  </div>
                  <div className="paymentplan-item">
                    <span>Maximum sales price</span>
                    <p>₦{moneyFormat(data?.maxSalePrice)}</p>
                  </div>
                </div>
                <div className="paymentplan-flex">
                  <div className="paymentplan-item">
                    <span>No. Property sold in this location</span>
                    <p>{data?.totalSold}</p>
                  </div>
                  <div className="paymentplan-item">
                    <span>Total rental</span>
                    <p>{data?.totalRental}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="paymentplan">
              <h1>PAYMENT PLAN</h1>
              {property?.payment_plan?.[0] ? (
                <>
                  <div className="paymentplan-flex">
                    <div className="paymentplan-item">
                      <span>Flexible Payment Plan?</span>
                      <p>Yes</p>
                    </div>
                    <div className="paymentplan-item">
                      <span>Minimun Initial Deposite</span>
                      <p>₦{moneyFormat(property?.payment_plan?.[0]?.amount ?? 0)}</p>
                    </div>
                  </div>
                  <div className="paymentplan-flex">
                    <div className="paymentplan-item">
                      <span>Value Added Tax(VAT)</span>
                      <p>₦{moneyFormat(property?.vat ?? 0)}</p>
                    </div>
                    <div className="paymentplan-item">
                      <span>Financial Adversory Fee</span>
                      <p>₦{moneyFormat(property?.financial_advisory_fee ?? 0)}</p>
                    </div>
                  </div>
                  <div className="paymentplan-total">
                    <span>Total Amount To Be Paid</span>
                    <p>₦{moneyFormat(property?.price ?? 0)}</p>
                  </div>
                </>
              ) : (
                <div className="paymentplan-flex">
                  Not available for rental property
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="productpage-details">
              <div className="btn-flex">
                {isPropertyOwner ? (
                  <button
                    type="button"
                    className="btn btn-danger br-0"
                    onClick={() =>
                      history.push({
                        pathname: `/dashboard/listing/${property.propertyID}`,
                        state: {
                          property,
                        },
                      })
                    }
                  >
                    EDIT PROPERTY
                  </button>
                ) : (
                  <>
                    <Button
                      type="button"
                      className="btn btn-danger br-0"
                      onClick={handleBuyProperty}
                    >
                      BUY PROPERTY
                    </Button>
                    <Button
                      // disabled={addingToWishList}
                      // isLoading={addingToWishList}
                      type="button"
                      className="btn btn-outline-danger br-0"
                      isLoading={addingToWishList}
                      onClick={wishlistFunc}
                    >
                      ADD TO WISHLIST
                    </Button>
                  </>
                )}
              </div>
              <h1>{property.name}</h1>
              <h2>{property.address}</h2>
              {/* <p className="fs-14">
                Does it have pet-friendly rentals? What are the crime rates? How are
                the schools
              </p> */}
              <span>NGN {moneyFormat(property.price)}</span>
              <h2>PROPERTY DESCRIPTION</h2>
              <p className="fs-14">{parse(property.description || '')}</p>
              <div className="keyfeatures">
                <h3>KEY FEATURES</h3>
                {
                  // eslint-disable-next-line no-shadow
                  property.property_features.map(({ id, name }) => (
                    <span key={id}>{name}</span>
                  ))
                }
              </div>
              <div className="btn-flex">
                <button
                  type="button"
                  className="btn btn-danger fs-12 br-0 text-white"
                  onClick={viewBgDocFunc}
                >
                  VIEW BACKGROUND DOCUMENT
                </button>
                <button
                  type="button"
                  className="btn btn-danger fs-12 br-0 text-white"
                  onClick={() => updateModalState({ type: 'floorPlan' })}
                >
                  VIEW FLOORPLAN
                </button>
              </div>
              <div className="btn-flex">
                {/* <button
                  onClick={() => updateModalState({ type: 'offer' })}
                  type="button"
                  className="btn btn-outline-danger fs-12 br-0"
                >
                  MAKE OFFER
                </button> */}
                <button
                  onClick={() => updateModalState({ type: 'comment' })}
                  type="button"
                  className="btn btn-danger fs-12 br-0 text-white"
                >
                  COMMENT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {id == '123' ? <PropertyDetailScript id="8Do0u5qRe5JF" /> : null}
      {id == '125' ? <PropertyDetailScript id="5doZ7GNap" /> : null}
      {['126', '127'].includes(id) ? <PropertyDetailScript id="8lLuKAWhZ" /> : null}

      {/* <LocationInfo
        header="THE ENVIRONMENT"
        longitude={property.longitude}
        latitude={property.latitude}
        content="This apartment is an inviting choice. Created as a space to spend time in, there's extra room in the open-plan living area, allowing guests to stretch out; whether relaxing on the sofa or enjoying the kitchen. The bedroom and bathroom are also spacious, and continue the sense of warm yet contemporary design, with touches of colour. This apartment is an inviting choice. Created as a space to spend time in, there's extra room in the open-plan living area, allowing guests to stretch out; whether relaxing on the sofa or enjoying the kitchen. The bedroom and bathroom are also spacious, and continue the sense of warm yet contemporary design, with touches of colour."
      /> */}
      <div className="container">
        <div className="productpage-cta">
          {!isPropertyOwner ? (
            <div className="productpage-cta_item">
              <p>Interested in this property?</p>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => updateModalState({ type: 'buyProperty' })}
              >
                Buy Property
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="container">
        <div className="similar-property">
          <h1>MORE SIMILAR PROPERTIES BY LOCATION</h1>
          <span>The surroundings are best for your lifestyle</span>
          <div className="row">
            {data?.related_property?.data?.map((item) => (
              <div className="col-md-4">
                <SalesCard {...item} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Register modal */}
      <Modal
        open={showRegisterForm && !auth.isAuthenticated}
        closeModal={toggleRegisterFormModal}
        title="Please Sign up to Continue"
      >
        <RegisterForm
          signUp={signUp}
          error={signUpInfo.error}
          isLoading={signUpInfo.isLoading}
          authProvider={auth.provider}
        />
      </Modal>

      <Modal
        open={modalState.buyProperty}
        closeModal={closeModal}
        title="Contact Us"
      >
        <LeadForm />
      </Modal>

      <Modal
        open={modalState.wishlist}
        closeModal={closeModal}
        title="Please Register or Sign in"
      >
        <LoginForm redirectPath="/dashboard?view=wishlist" />
      </Modal>

      <PropertyComment
        auth={auth}
        propertyId={property.propertyID}
        open={modalState.comment}
        closeModal={closeModal}
      />

      <MakeOffer
        auth={auth}
        propertyId={property.propertyID}
        open={modalState.offer}
        closeModal={closeModal}
      />

      <ShareProperty
        open={modalState.share}
        closeModal={closeModal}
        propertyId={property.propertyID}
      />

      {/* View floor plan */}
      <Modal open={modalState.floorPlan} closeModal={closeModal} title="Floor Plan">
        <div className="propertyimage">
          {property?.floor_plan ? (
            <Carousel itemsToShow={1} disableArrowsOnEnd>
              {property?.floor_plan.map(({ link }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <img key={index} src={link} alt="property floor plan" />
              ))}
            </Carousel>
          ) : (
            <div />
          )}
        </div>
      </Modal>

      <Modal
        open={modalState.backgroundInfo}
        closeModal={closeModal}
        title="Please Login to View Background Document"
      >
        <LoginForm
          redirectPath={`/property-info/viewbgdocs/${property.propertyID}`}
        />
      </Modal>
      <Footer />
    </section>
  );
};

export default ProductPage;
