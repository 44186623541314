/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

// style
import './TipsCardStyle.css';

const TipsCard = ({ image_link, date_posted, title, body, ...rest }) => {
  return (
    <div className="tipscard">
      <Link className="text-decoration-none"
          to={{
            pathname: '/tipsandguides/details',
            state: {
              tipsAndGuide: {
                body,
                title,
                image_link,
                date_posted,
                ...rest,
              },
            },
          }}

        >
      <div className="tipscard-image">
        <img src={image_link} alt="tips and guide" />
      </div>
      <div className="tipscard-desc">
        <span className="tipscard-date">{date_posted}</span>
        <div className="tipscard-header">
        {title}
        </div>
        {/*<p className="tipscard-content">{parse(body || '')}</p>*/}
      </div>
      </Link>
    </div>
  );
};

TipsCard.propTypes = {
  image_link: PropTypes.string.isRequired,
  date_posted: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default TipsCard;
