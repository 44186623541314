import { useReducer } from 'react';

const initialState = {
  offerModal: false,
  commentModal: false,
  paymentEvidenceModal: false,
  propertyId: null,
};

const propertyActionReducer = (state = initialState, action) => {
  switch (action?.type) {
    case 'viewOffers':
      return {
        ...state,
        offerModal: true,
        propertyId: action.propertyId,
      };
    case 'viewComment':
      return {
        ...state,
        commentModal: true,
        propertyId: action.propertyId,
      };
    case 'paymentEvidence':
      return {
        ...state,
        paymentEvidenceModal: true,
        propertyId: action.propertyId,
      };
    default:
      return {
        ...state,
        ...initialState,
      };
  }
};

export const usePropertyAction = () => {
  const [propertyAction, dispatch] = useReducer(propertyActionReducer, initialState);
  return [propertyAction, dispatch];
};
