/* eslint-disable camelcase */
import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

// components
import { Button } from 'components/common/Button';
import Modal from 'components/Modal';
import { FileInput } from 'components/common/FileInput';
import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { useUploadPaymentEvidence } from 'hooks/useUploadPaymentEvidence';

export default function PaymentEvidenceModal(props) {
  const { open, closeModal, propertyId } = props;
  const methods = useForm({
    defaultValues: {},
  });

  const [uploadPaymentEvidence, { isLoading }] = useUploadPaymentEvidence();

  const onSubmit = (values) => {
    const { payment_evidence, amount, description } = values;
    const formData = new FormData();
    formData.append('amount', amount);
    formData.append('description', description);
    const paymentEvidence = Array.from(payment_evidence)[0];
    formData.append('payment_evidence', paymentEvidence, paymentEvidence.name);
    formData.append('property_id', propertyId);
    uploadPaymentEvidence(formData, {
      onSuccess: () => {
        toast.success('Purchase evidence uploaded successfully', {
          style: {
            background: '#d4edda',
            color: '#155724',
          },
        });
        closeModal();
      },
    });
  };

  return (
    <div>
      <Modal open={open} closeModal={closeModal} title="Upload Payment Evidence">
        <div className="row">
          <Form methods={methods} onSubmit={onSubmit}>
            <Input
              containerClassName="col-md-12"
              className="form-control br-0"
              label="Amount"
              id="amount"
              name="amount"
              validation={{ required: true }}
            />
            <Input
              containerClassName="col-md-12"
              className="form-control br-0"
              label="Description"
              id="description"
              name="description"
              validation={{ required: true }}
            />
            <FileInput
              label=""
              description="Click to upload payment evidence"
              name="payment_evidence"
              multiple
              validation={{ required: true }}
              accept="image/*"
            />
            <p />
            <Button
              type="submit"
              isLoading={isLoading}
              className="ml-2 btn btn-danger app-bg-red br-0 white mb-3 fs-14"
            >
              SAVE CHANGES
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

PaymentEvidenceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  propertyId: PropTypes.number,
};

PaymentEvidenceModal.defaultProps = {
  propertyId: 0,
};
