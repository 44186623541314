import React from 'react';

const SingleFamilyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 4C20 3.44772 19.5523 3 19 3C18.4478 3 18 3.44772 18 4V6.39981L14.9163 4.00131C13.201 2.66721 10.7991 2.6672 9.08383 4.00131L2.38611 9.21065C1.95016 9.54972 1.87162 10.178 2.21069 10.6139C2.54976 11.0499 3.17804 11.1284 3.61399 10.7894L4.00005 10.4891V18C4.00005 19.6569 5.34319 21 7.00005 21H17C18.6569 21 20 19.6569 20 18V10.4891L20.3861 10.7894C20.8221 11.1284 21.4503 11.0499 21.7894 10.6139C22.1285 10.178 22.0499 9.54972 21.614 9.21065L20 7.95536V4ZM18 8.93353L13.6884 5.58001C12.6953 4.80764 11.3048 4.80764 10.3117 5.58001L5.99798 8.93514C5.99935 8.95658 6.00005 8.97821 6.00005 9V18C6.00005 18.5523 6.44776 19 7.00005 19H17C17.5523 19 18 18.5523 18 18V8.93353Z"
      fill="#2B2B2B"
    ></path>
  </svg>
);

export default SingleFamilyIcon;
