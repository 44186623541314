import React from 'react';
import usePageBanner from 'hooks/usePageBanner';


// Style
import './ManageFinanceStyle.css';
import '../../pages/Home/BannerSection/BannerStyle.css';

const ManageFinanceBanner = () => {
  const { data } = usePageBanner('getManageFinanceBanner');

  return (
    <div className="financebanner">
      {data?.banner?.primary_image && (
        <img src={data?.banner?.primary_image} alt="banner" />
      )}
      <div className="overlay">
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="financebanner-content">
              <h1>{data?.banner?.title}</h1>
              <p>
                Our home affordability payments calculator can help you estimate what
                you can afford to spend on your new home.
              </p>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      </div>
      
    </div>
  );
}


export default ManageFinanceBanner;
