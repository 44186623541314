import React, { useState } from 'react';
import PriceRange from './PriceRange';
import DownPayment from './DownPayment';
import EmploymentStatus from './EmploymentStatus';

const Details = ({ setFormStep }) => {
  const [detailStep, setDetailStep] = useState(1);
  

  return (
    <div>
      {detailStep === 1 ? (
        <PriceRange setDetailStep={setDetailStep}  />
      ) : null}
      {detailStep === 2 ? <DownPayment setDetailStep={setDetailStep} /> : null}
      {detailStep === 3 ? (
        <EmploymentStatus setFormStep={setFormStep} setDetailStep={setDetailStep} />
      ) : null}
    </div>
  );
};

export default Details;
