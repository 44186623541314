import React from 'react';
import { NavLink } from 'react-router-dom';
import Loader from 'components/Loader';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import useGetPaymentOptions from 'hooks/useGetPaymentOptions';

// components
import Navbar from 'components/Navbar';
import Calculator from 'components/Calculator';
import Footer from 'components/Footer';

// style
import './PaymentOptionsPageStyle.css';
import ManageFinanceBanner from 'components/ManageFinanceBanner';

const PaymentOptionsPage = () => {
  const { data, isLoading } = useGetPaymentOptions();
  return (
    <div className="paymentoptionspage">
      <Navbar />
      <ManageFinanceBanner />
      <div className="container">
        <div className="breadcrumb-wrapper">
          <NavLink to="/finance">Manage Finance</NavLink>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span>Payment Options/Mortage</span>
        </div>
      </div>
      <div className="">
        <h1 className="paymentoptionspage-header">PAYMENT OPTIONS</h1>
        <TabProvider defaultTab="mortgageoptions">
          <section className="my-tabs">
            <div className="flex-tab">
              <TabList className="tablist payment-tablist">
                <Tab tabFor="mortgageoptions" className="tab-btn">
                  Mortgage Option
                </Tab>
                <Tab tabFor="instalmentoptions" className="tab-btn">
                  Instalment Option
                </Tab>
              </TabList>
            </div>
            <div className="wrapper">
              <TabPanel tabId="mortgageoptions">
                <div className="container">
                  <div className="mortgage-header">
                    <h1>MORTGAGE RATES</h1>
                    <span>
                      Get today's mortgage rates, connect with local lenders, and
                      stay informed.
                    </span>
                  </div>
                  <div className="row">
                    {data?.mortgageCompanies?.map((mortgage) => (
                      <div className="col-md-3">
                        <div className="mortgagerate-card">
                          <span>{mortgage?.tenure?.split('')[0]}-Year fixed</span>
                          <h1>{mortgage.rates}%</h1>
                          <div className="mortgagerate-indicator">
                            <svg
                              width="172"
                              height="102"
                              viewBox="0 0 172 102"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.40495 14.2498C5.09421 18.3459 19.6949 25.7204 21.467 15.376C23.3537 4.36317 25.8485 17.3207 27.1796 21.7916C28.3207 25.6247 31.9388 28.6034 35.9477 28.9522C39.5989 29.2698 46.7778 21.7345 47.9538 24.4298C48.9452 26.7019 48.5613 30.2617 48.7242 32.7304C49.1162 38.6732 48.7868 41.0035 55.8214 40.725C62.6513 40.4546 59.9606 45.8617 64.7998 48.5398C67.9921 50.3065 75.6203 48.3667 78.4995 47.7474C81.509 47.1 85.7263 48.5457 86.0651 51.8694C86.1702 52.9007 85.9914 55.5053 86.9674 56.2639C87.9809 57.0517 88.3248 54.845 89.3877 56.661C92.1927 61.4536 97.8299 52.6286 100.709 54.4109C103.001 55.83 113.9 49.179 114.269 52.3471C114.706 56.1042 114.949 53.5121 113.193 57.9857C112.306 60.2457 117.358 55.0385 119.781 54.8786C121.221 54.7836 123.324 56.6137 124.598 57.0106C129.14 58.4254 133.164 56.5523 137.636 57.1526C141.586 57.683 147.342 60.3431 150.677 62.4166C152.572 63.595 153.187 64.486 153.949 66.4322C154.171 67.001 153.95 68.423 154.509 68.1769C155.204 67.8711 155.288 66.1297 156.011 65.628C156.974 64.9606 156.34 68.2043 157.374 67.7095C161.832 65.5758 158.071 68.9828 159.374 71.8647C159.685 72.5531 162.186 72.4422 162.703 72.5358C165.208 72.9893 165.641 75.5719 166.602 77.6236C168.486 81.6455 170.421 84.8807 170.711 89.2672"
                                stroke="#cc5500"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="centralize-div ratesbtn-wrapper">
                    <button className="btn btn-outline-orange br-0">
                      SEE ALL RATES
                    </button>
                  </div>
                </div>
                <Calculator
                  title="MORTGAGE CALCULATOR"
                  decsription="As the downturn from coronavirus pandemic squeezes the economy, higher-end and luxury real estate markets have become the hardest."
                />
              </TabPanel>
              <TabPanel tabId="instalmentoptions">
                <div className="instalmentoptions-content">
                  <Calculator
                    title="INSTALMENT CALCULATOR"
                    decsription="As the downturn from coronavirus pandemic squeezes the economy, higher-end and luxury real estate markets have become the hardest."
                  />
                </div>
              </TabPanel>
            </div>
          </section>
        </TabProvider>
      </div>

      {/*<div className="container mortgageoffering-companies">
        <div className="mortgage-header">
          <h1>COMPANIES OFFERING MORTGAGES</h1>
          <span>
            Get today's mortgage rates, connect with local lenders, and stay
            informed.
          </span>
        </div>
        <div className="row">
          {isLoading ? (
            <Loader color="primary" />
          ) : (
            <>
              {data?.mortgageCompanies?.map((mortgage) => (
                <div className="col-md-3" key={mortgage.id}>
                  <div className="mortgage-companies_card">
                    <img src={mortgage.image} alt="company mortgage preview" />
                    <p>{mortgage.name}</p>
                    <p>{mortgage.rates}</p>
                    <p>{mortgage.tenure}</p>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>*/}
      <Footer />
    </div>
  );
};

export default PaymentOptionsPage;
