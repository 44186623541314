import React from 'react';
import gtag from 'ga-gtag';
import { Link, NavLink } from 'react-router-dom';

// components
import Navbar from 'components/Navbar';
import Calculator from 'components/Calculator';
import Footer from 'components/Footer';
import ManageFinanceBanner from 'components/ManageFinanceBanner';

// style
import './AffordablityStyle.css';
import SalesCard from 'components/SalesCard';
import { moneyFormat } from 'utils/moneyFormat';
import { useAffordabilityCalculator } from './hooks';

const AffordabilityCalculatorPage = () => {
  const [calculate, { data, isLoading, isSuccess }] = useAffordabilityCalculator();
  const [values, setValues] = React.useState({});

  const handleSubmit = (formValues) => {
    setValues(formValues);
    calculate(
      { ...formValues, per_page: 3 },
      {
        onSuccess: () => {},
      }
    );
  };

  const getNextAffordableProperties = (nextPage) => {
    calculate(
      { ...values, per_page: 10 },
      {
        onSuccess: () => {},
      }
    );
  };

  const trackAffordabilityCalculatorPage = () => {
    gtag('event', 'affordabilityCalculator_page', {
      method: 'affordabilityCalculator_page',
    });
  };

  React.useEffect(() => {
    trackAffordabilityCalculatorPage();
  }, []);

  return (
    <div className="paymentoptionspage">
      <Navbar />
      <ManageFinanceBanner />
      <div className="container">
        <div className="breadcrumb-wrapper">
          <NavLink to="/finance">Manage Finance</NavLink>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/finance/affordability">Affordability Calculator</Link>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <div className="affordability-header">
              <h1>AFFORDABILITY CALCULATOR</h1>
              <p>
                Our home affordability payments calculator can help you estimate what
                you can afford to spend on your new home. Here’s the info you’ll need
                to enter into the affordability calculator:
              </p>
            </div>
          </div>
          <div className="col-md-2" />
        </div>
      </div>
      <Calculator onSubmit={handleSubmit} isLoading={isLoading} />

      <div className="container">
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6" />
          {isSuccess ? (
            <div className="calculated-outcome">
              <span>You can afford a house up to</span>
              <span className="calculated-price">
                ₦{moneyFormat(data?.amount_affordable)}
              </span>
              <p>
                Based on your income, a house at this price should fit comfortably
                within your budget.
              </p>
            </div>
          ) : null}
          <div className="col-md-3" />
        </div>
      </div>
      <div className="container mortgageoffering-companies">
        <div className="mortgage-header">
          <h1>AFFORDABLE PROPERTIES FOR YOU</h1>
          <span>
            Get todays mortgage rates, connect with local lenders, and stay informed.
          </span>
        </div>
        <div className="row">
          {data?.affordable_property?.data?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="col-md-4" key={index}>
              <SalesCard {...item} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AffordabilityCalculatorPage;
