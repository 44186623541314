/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';

// hooks
import usePropertyCategoryCount from 'hooks/usePropertyCategoryCount';

// style
import './CategoryFilterStyle.css';

const CategoryFilter = ({ setPropertyType }) => {
  const { data, isLoading } = usePropertyCategoryCount();
  return (
    <div className="categoryfilter">
      <h1>Category</h1>
      <>
        {isLoading ? (
          <Loader color="primary" className="p-4" />
        ) : (
          <>
            <label className="filtergroup-item">
              <input
                type="radio"
                name="shipping"
                value=""
                onChange={(e) => setPropertyType(undefined)}
                className="filtergroup-input-radio"
              />
              <span className="filtergroup-button">
                All Properties(
                {data?.property_categories?.reduce((curr, next) => {
                  return curr + next.total;
                }, 0)}
                )
              </span>
              <span className="filter-checkmark">
                <svg
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3333 1.5L6.25 11.5833L1.66667 7"
                    stroke="#cc5500"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
            {data?.property_categories?.map(({ id, name, total }) => (
              <label className="filtergroup-item" key={id}>
                <input
                  type="radio"
                  name="shipping"
                  value={id}
                  onChange={(e) => setPropertyType(e.target.value)}
                  className="filtergroup-input-radio"
                />
                <span className="filtergroup-button">
                  {name} ({total})
                </span>
                <span className="filter-checkmark">
                  <svg
                    width="18"
                    height="13"
                    viewBox="0 0 18 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.3333 1.5L6.25 11.5833L1.66667 7"
                      stroke="#cc5500"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </label>
            ))}
          </>
        )}
      </>
    </div>
  );
};
CategoryFilter.propTypes = {
  setPropertyType: PropTypes.func.isRequired,
};

export default CategoryFilter;
