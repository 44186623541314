import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function useAccountConfirmation(reference) {
  return useQuery(['confirmation-link', reference], (_, referenceParams) =>
    client('account/confirm', { params: { reference: referenceParams } }).then(
      (res) => res
    )
  );
}
