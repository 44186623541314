import React, { useState } from 'react';
import {
  CondominiumIcon,
  MobileManifacturedIcon,
  MultiFamilyIcon,
  NewConstructionIcon,
  SingleFamilyIcon,
  TownHomeIcon,
} from 'static/vector';
import { useFormData } from 'context/approval-form-context';
import './HomeTypeStyle.css';
import Footer from '../Footer';

const HomeType = () => {
  const { data, setFormStep, setFormValues } = useFormData();
  const [activeTab, setActiveTab] = useState(data.loanType || 'Home Purchase');

  const handleSelect = (value) => {
    setFormValues({
      loanType: activeTab,
      homeType: value,
    });
    setFormStep(1);
  };

  console.log({ activeTab, data });

  return (
    <main className="">
      <div className="home-type-container">
        <div className="tab-header">
          {approvalTab.map((tab, index) => (
            <label
              className={`${activeTab == tab ? 'active-tab' : ''}`}
              key={index}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </label>
          ))}
        </div>
        <main className="col-12 col-md-9 mx-auto">
          <div className="flex flex-column justify-content-center align-items-center">
            <h2 className="approval-title app-red my-2">
              {approvalMenu[activeTab].header.title}
            </h2>
            <p className="approval-description">
              {approvalMenu[activeTab].header.description}
            </p>
          </div>
          <section>
            <p className="approval-label">{approvalMenu[activeTab].label}</p>

            <div className="row justify-content-center gap-4">
              {homeTypeOption.map((home, index) => (
                <div
                  key={index}
                  className={`col-5 col-md-5 home-type-card ${
                    data.homeType == home.title ? 'selected' : ''
                  }`}
                  onClick={() => handleSelect(home.title)}
                >
                  <div className="icon">{home.icon}</div>
                  <h2 className="title">{home.title}</h2>
                </div>
              ))}
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </main>
  );
};

export default HomeType;

const approvalTab = ['Home Purchase', 'Refinance', 'Home Equity'];
const approvalMenu = {
  'Home Purchase': {
    header: {
      title: 'Get pre-approved',
      description: 'Connect with a lender who can help you with pre-approval',
    },
    label: 'What type of home are you looking for?',
  },
  Refinance: {
    header: {
      title: 'Refinance your home',
      description: 'Connect with a lender who can help you with pre-approval',
    },
    label: 'What type of home are you refinancing?',
  },
  'Home Equity': {
    header: {
      title: 'Find home equity offers',
      description: 'Connect with a lender who can help you with pre-approval',
    },
    label: 'What type of home do you have?',
  },
};

const homeTypeOption = [
  {
    title: 'Single Family',
    icon: <SingleFamilyIcon />,
  },
  {
    title: 'Home Town',
    icon: <TownHomeIcon />,
  },
  {
    title: 'Condoninium',
    icon: <CondominiumIcon />,
  },
  {
    title: 'Multi-Family',
    icon: <MultiFamilyIcon />,
  },
  {
    title: 'Mobile/Manifactured',
    icon: <MobileManifacturedIcon />,
  },
  {
    title: 'New Construction',
    icon: <NewConstructionIcon />,
  },
];
