import React, { useState } from 'react';
import { Input } from 'components/common/Input';
import { Button } from 'components/common/Button';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useFormData } from 'context/approval-form-context';
import usePreApproval from 'hooks/usePreApproval';

const PhoneNumber = ({ setDetailStep }) => {
  const [submitApproval, { isLoading, error: approvalError }] = usePreApproval();
  const { data, setFormStep, setFormValues } = useFormData();
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber);

  const handleChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
  };

  const handleNext = () => {
    setFormValues({ phoneNumber });
    const payload = {
      values: {
        location: data.location,
        priceRange: `${data.price_range.min} - ${data.price_range.max}`,
        downPayment: data.down_payment.toString(),
        employmentStatus: data.employment_status,
        homeType: data.homeType,
        loanType: data.loanType,
        email: data.email,
        phoneNumber,
      },
    };
    submitApproval(payload, {
      onSuccess: () => {
        setFormStep(4);
      },
    });
  };

  const handleBack = () => {
    setDetailStep(1);
  };

  return (
    <main>
      <div>
        <p className="input-label">What is your phone number?</p>
        <Input
          label=""
          type="text"
          defaultValue={data.phoneNumber}
          name="phoneNumber"
          placeholder="Enter phone number"
          labelClassName="form-label"
          onChange={handleChange}
          className="form-control"
        />

        {approvalError ? <p className="form-error">{approvalError}</p> : null}
      </div>
      <div className="d-flex flex-column justify-content-center mx-auto col-12 col-md-9 gap-5">
        <Button
          onClick={handleNext}
          className="btn btn-dark br-0 w-100 rounded-pill mt-5 no-border"
          type="submit"
        >
          {isLoading ? 'Processing...' : 'Next'}
        </Button>

        <button
          onClick={handleBack}
          className="back-button text-center d-flex gap-2 justify-content-center align-items-center"
        >
          <IoIosArrowRoundBack />
          <span className="text-underline">Back</span>
        </button>
      </div>
    </main>
  );
};

export default PhoneNumber;
