import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

export function useAddHomeService() {
  const cache = useQueryCache();
  return useMutation((values) =>
    client('services/addServiceProvider', {
      data: values,
    }).then((res) => {
      cache.invalidateQueries('my-services');
      return res;
    })
  );
}
