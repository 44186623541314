import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export function useHomeServiceCategoryTypes(params) {
  return useQuery(['home-service-category-types', params], (_, fieldParams) =>
    client(`services/serviceCategoryTypes`, { params: fieldParams }).then(
      (res) => res
    )
  );
}
