import * as React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

import Modal from 'components/Modal';
import './PlacesAutocomplete.css';

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    const { address, lat, lng } = props.value;
    this.state = {
      address,
      open: false,
      error: false,
      latLng: {
        lat: lat || null,
        lng: lng || null,
      },
    };
  }

  componentDidMount() {
    const { address } = this.props.value;
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ latLng, address, error: false });
      })
      .catch((error) => {
        this.setState({ error: true });
        process.env.NODE_ENV !== 'development' && console.error('Error', error);
      });
  }

  handleChange = (address) => {
    const { handleSelected } = this.props;
    const { latLng } = this.state;
    handleSelected({ address, ...latLng });
    this.setState({ address });
  };

  addressByGeoCode = ({ google }, _, e) => {
    const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    const geocoder = new google.maps.Geocoder();
    this.setState({
      latLng,
    });
    const { handleSelected } = this.props;
    geocoder.geocode(
      {
        latLng,
      },
      (responses) => {
        if (responses && responses.length > 0) {
          this.setState({ address: responses[0].formatted_address });
          handleSelected({
            lat: latLng.lat,
            lng: latLng.lng,
            address: responses[0].formatted_address,
          });
        }
      }
    );
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ latLng, address, open: true });
      })
      .catch(
        (error) =>
          process.env.NODE_ENV !== 'development' && console.error('Error', error)
      );
  };

  confirmLocation = () => {
    const { handleSelected } = this.props;
    const { latLng, address } = this.state;
    handleSelected({
      ...latLng,
      address,
    });
    this.setState({ open: false });
  };

  render() {
    const { label, google } = this.props;
    const { address, open, latLng } = this.state;
    return (
      <>
        <label className="form-label">{label}</label>
        <input
          className="form-control br-0"
          placeholder="Search Places ..."
          value={address}
          onClick={() => this.setState({ open: true })}
        />
        <Modal
          open={open}
          closeModal={() => this.setState({ open: false })}
          containerClassName="places-auto-complete-modal"
          confirmAction={this.confirmLocation}
          confirmActionText="Confirm Location"
        >
          <PlacesAutocomplete
            value={address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div style={{ marginBottom: '20px' }}>
                <input
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'form-control br-0',
                  })}
                />

                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <div style={{ height: '70vh', width: '100vw' }}>
            {latLng?.lat && (
              <Map
                google={google}
                initialCenter={latLng}
                center={latLng}
                zoom={14}
                style={{ height: '100%' }}
              >
                <Marker
                  onClick={this.onMarkerClick}
                  name="Current location"
                  draggable
                  mapCenter={latLng}
                  position={latLng}
                  onDragend={this.addressByGeoCode}
                />
              </Map>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

LocationSearchInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.shape({
    address: PropTypes.string,
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  handleSelected: PropTypes.func.isRequired,
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(LocationSearchInput);
