import { useMutation } from 'react-query';
import { client } from 'utils/api-client';

export function useCreateCustomerAdvert(convertAdvertValues) {
  return useMutation((values) =>
    client('params/createCustomerAdvert', {
      data: convertAdvertValues(values),
      isFormData: true,
    }).then((res) => {
      return res;
    })
  );
}
