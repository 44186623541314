import React from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import Loader from 'components/Loader';
import usePaymentProfile from 'hooks/usePaymentProfile';

// components
import Navbar from 'components/Navbar';
import SalesCard from 'components/SalesCard';
import Footer from 'components/Footer';

// style
import './PaymentProfilePageStyle.css';
import ManageFinanceBanner from 'components/ManageFinanceBanner';

const PaymentProfilePage = () => {
  const { data, isLoading } = usePaymentProfile();
  const history = useHistory();
  return (
    <div className="paymentprofilepage">
      <Navbar />
      <ManageFinanceBanner />
      <div className="container">
        <div className="breadcrumb-wrapper">
          <NavLink to="/finance">Manage Finance</NavLink>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/finance">My Payment Profile</Link>
        </div>
        <div className="paymentprofilepage-desc">
          <h1>MY PAYMENT PROFILE</h1>
          <p>
            Our home affordability payments calculator can help you estimate what you
            can afford to spend on your new home. Here’s the info you’ll need to
            enter into the affordability calculator:
          </p>
        </div>
        <div className="paymentprofilepage-card">
          <div className="row">
            {isLoading ? (
              <Loader color="primary" />
            ) : (
              <>
                {data.myProperties?.data?.map((property) => (
                  <div className="col-md-4" key={property.propertyID}>
                    <SalesCard {...property} disableCardNavigation>
                      <button
                        className="btn-action"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push({
                            pathname: '/dashboard/paymentprofile/details',
                            state: {
                              propertyId: property.propertyID,
                            },
                          });
                        }}
                      >
                        VIEW PAYMENT PROFILE
                        <span>
                          <svg
                            width="25"
                            height="12"
                            viewBox="0 0 25 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24.5303 6.53033C24.8232 6.23744 24.8232 5.76256 24.5303 5.46967L19.7574 0.696699C19.4645 0.403806 18.9896 0.403806 18.6967 0.696699C18.4038 0.989593 18.4038 1.46447 18.6967 1.75736L22.9393 6L18.6967 10.2426C18.4038 10.5355 18.4038 11.0104 18.6967 11.3033C18.9896 11.5962 19.4645 11.5962 19.7574 11.3033L24.5303 6.53033ZM0 6.75H24V5.25H0V6.75Z"
                              fill="#cc5500"
                              fillOpacity="0.9"
                            />
                          </svg>
                        </span>
                      </button>
                    </SalesCard>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentProfilePage;
