import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function useStateList(params) {
  return useQuery(
    ['state-list', params],
    (_, fieldParams) =>
      client(`params/getStateWithProperties`, { params: fieldParams }).then(
        (res) => res
      ),
    {
      enabled: params.countryID,
    }
  );
}
