export const formateDate = (dateString) => {
  return new Date(dateString).toLocaleDateString({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

function truncateText(text, maxLength = 300) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

export { truncateText };
