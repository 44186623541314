import React from 'react';
import './CustomerFeedback.css';
import FeedbackCard from '../FeedbackCard';

const CustomerFeedback = () => {
  const feedbackData = [
    {
      title: "QUANTITY SURVEYOR",
      feedback: "Outstanding service! They connected us with the most reasonable surveyor I have ever worked with for our renovation project."
    },
    {
      title: "BRICKLAYER & CARPENTER",
      feedback: "Highly recommend! We found very skilled and easy-going artisans quickly and easily."
    },
    {
      title: "BRICKLAYERS",
      feedback: "Neat Job! Exceptional experience from start to finish. Their speed and perfection service saved us so much time."
    },
    {
      title: "BUILDING TEAM",
      feedback: "Fantastic platform! The quality of the service providers exceeded our expectations."
    },
    {
      title: "THE PLATFORM",
      feedback: "Amazing experience! The platform connected us with the best in the business."
    },
    {
      title: "ESTATE PROJECT",
      feedback: "Efficient and reliable! We found exactly who we needed for our project."
    }
  ];

  return (
    <section className="customer-feedback">
      <h2 className="feedback-header">HEAR FROM OUR SATISFIED CUSTOMERS</h2>
      <div className="feedback-container">
        {feedbackData.map((item, index) => (
          <FeedbackCard 
            key={index} 
            title={item.title} 
            feedback={item.feedback} 
          />
        ))}
      </div>
    </section>
  );
};

export default CustomerFeedback;
