import React from 'react';

import './CommentStyle.css';

const Comment = ({ imageUrl, name, date, text }) => {
  return (
    <>
      <p />
      <div className="ui comments">
        <div className="comment">
          <img src={imageUrl} alt="comment author" />

          <div className="content">
            <div className="author">{name}</div>
            <div className="date">{date}</div>
            <div className="text">{text}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comment;
