import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'context/auth-context';

import BricksLogo from '../../static/images/logo100.svg';
// import OldBricksLogo from '../../static/images/aso-logo.svg';

// style
import './NavbarStyle.css';

const Navbar = () => {
  const [click, setClick] = useState(false);
  const { user, logout } = useAuth();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    offset > 50 ? setScrolled(true) : setScrolled(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => setClick(!click);
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light bg-white ${
        scrolled ? 'is-scrolled' : ''
      }`}
    >
      <div className="container">
        <NavLink exact className="navbar-brand mr-0 pt-0 pb-0" to="/">
          <img
            className="logo-image"
            src={BricksLogo}
            style={{
              height: '129.8px',
              width: '250px',
            }}
            alt="100Bricks Logo"
          />
        </NavLink>{' '}
        <button
          className="navbar-toggler"
          onClick={handleClick}
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>{' '}
        <div
          className={
            click ? 'collapse navbar-collapse active' : 'collapse navbar-collapse'
          }
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto mb-1 mb-lg-0">
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="nav-link active"
              >
                HOME{' '}
              </NavLink>{' '}
            </li>{' '}
            <li className="nav-item">
              <NavLink
                to="/properties"
                className="nav-link"
                activeClassName="nav-link active"
              >
                PROPERTIES{' '}
              </NavLink>{' '}
            </li>{' '}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/sell"
                activeClassName="nav-link active"
              >
                SELL
              </NavLink>
            </li>{' '}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/services"
                activeClassName="nav-link active"
              >
                HOME SERVICES
              </NavLink>
            </li>{' '}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/finance"
                activeClassName="nav-link active"
              >
                MANAGE FINANCE{' '}
              </NavLink>{' '}
            </li>{' '}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/mortgage"
                activeClassName="nav-link active"
              >
                MORTGAGE EDUCATION{' '}
              </NavLink>{' '}
            </li>{' '}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/advert"
                activeClassName="nav-link active"
              >
                ADVERTISE{' '}
              </NavLink>{' '}
            </li>{' '}
            {/* <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/tipsandguides"
                      activeClassName="nav-link active"
                    >
                      TIPS & GUIDES
                    </NavLink>
                </li> */}{' '}
            {!user ? (
              <>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/login">
                    LOGIN{' '}
                  </NavLink>{' '}
                </li>{' '}
              </>
            ) : (
              <>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/dashboard">
                    MY HOME VIEW{' '}
                  </NavLink>{' '}
                </li>{' '}
                <li className="nav-item ml-2">
                  <button
                    className="nav-link logout-btn btnn text-white"
                    type="button"
                    onClick={logout}
                  >
                    LOGOUT{' '}
                  </button>{' '}
                </li>{' '}
              </>
            )}{' '}
          </ul>{' '}
        </div>{' '}
      </div>{' '}
    </nav>
  );
};

export default Navbar;
