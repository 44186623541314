import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function useTownList(params) {
  return useQuery(
    ['town-list', params],
    (_, fieldParams) =>
      client(`params/getTown`, { params: fieldParams }).then((res) => res),
    {
      enabled: params.cityID,
    }
  );
}
