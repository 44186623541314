import React, { useState } from 'react';
import { Input } from 'components/common/Input';
import { Button } from 'components/common/Button';
import { useFormData } from 'context/approval-form-context';
import { IoIosArrowRoundBack } from 'react-icons/io';

const PropertyLocation = () => {
  const { data, formStep, setFormStep, setFormValues } = useFormData();
  const [location, setLocation] = useState(data.location);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) {
      setLocation(value);
      setError('');
    } else {
      setError('Enter a location');
    }
  };

  const handleNext = () => {
    if (location) {
      setFormValues({ location });
      setFormStep(2);
    } else {
      setError('Enter a location');
    }
  };
  const handleBack = () => {
    setFormStep(0);
  };

  return (
    <main>
      <div>
        <p className="input-label">Where are you looking to buy land?</p>
        <Input
          label=""
          type="text"
          name="location"
          defaultValue={data.location}
          labelClassName="form-label"
          onChange={handleChange}
          className="form-control"
        />
        {error ? <p className="form-error">{error}</p> : null}
      </div>
      <div className="d-flex flex-column justify-content-center mx-auto col-12 col-md-9 gap-5">
        <Button
          onClick={handleNext}
          className="btn btn-dark br-0 w-100 rounded-pill mt-5 no-border"
          type="submit"
        >
          Next
        </Button>

        <button
          onClick={handleBack}
          className="back-button text-center d-flex gap-2 justify-content-center align-items-center"
        >
          <IoIosArrowRoundBack />
          <span className="text-underline">Back</span>
        </button>
      </div>
    </main>
  );
};

export default PropertyLocation;
