import { useQuery } from 'react-query';
import { client } from 'utils/api-client';
import { useAuth } from 'context/auth-context';

export function useFetchPropertyComments(params) {
  const { isAuthenticated } = useAuth();
  return useQuery(
    ['property-comments', params],
    (_, fieldParams) =>
      client(`comment/getComments`, { params: fieldParams }).then((res) => res),
    { enabled: params.property_id && isAuthenticated }
  );
}
