import * as React from 'react';
import PropTypes from 'prop-types';
import { LoadingIcon } from 'static';
import './LoaderStyle.css';

export default function Loader({ color, className, ...rest }) {
  return (
    <div className={`loader ${color} ${className}`} {...rest}>
      <LoadingIcon />
    </div>
  );
}

Loader.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Loader.defaultProps = {
  color: '',
  className: '',
};
