/* eslint-disable camelcase */
import React from 'react';
import {
  useForm, // Controller
} from 'react-hook-form';
// import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';

import {
  // useHistory,
  useRouteMatch,
  // useLocation,
} from 'react-router-dom';
// import { useAuth } from 'context/auth-context';

// components
// import { useCreatePropertySale } from 'hooks/useCreatePropertySale';
import { useCreateCustomerAdvert } from 'hooks/useCreateCustomerAdvert';

import Form from '../../components/common/Form';
import { Input } from '../../components/common/Input';
// import { DatePicker } from '../../components/common/DatePicker';
// import { Radio } from '../../components/common/Radio';
// import { Select } from '../../components/common/Select';
import { TextField } from '../../components/common/TextField';
// import { CheckBox } from '../../components/common/CheckBox';
import { FileInput } from '../../components/common/FileInput';
import { Button } from '../../components/common/Button';
// import PlacesAutocomplete from '../../components/PlacesAutocomplete';

// hooks

import {
  usePreloadData,
  // useUpdateListing,
  // useSubmitListing,
} from '../SubmitListing/hooks';
import {
  // convertSellFormValues,
  convertAdvertValues,
  // getFormDefaultValues,
} from '../SubmitListing/utils';

const AdvertiseForm = () => {
  // const { state } = useLocation();
  /* const [createSellData, { isLoading, error }] = useCreatePropertySale(
    convertSellFormValues
  ); */

  const [createAdvert, { isLoading, error }] = useCreateCustomerAdvert(
    convertAdvertValues
  );

  /* const defaultValues = React.useMemo(() => {
    return getFormDefaultValues(state?.property);
  }, [state]); */

  const methods = useForm({
    defaultValues: {
      kitchen: 1,
      bathroom: 1,
      year_built: null,
    },
  });

  // const { setError, reset } = methods;
  // const { user } = useAuth();
  const watchFields = methods.watch([
    'property_type_id',
    'country_id',
    'state_id',
    'city_id',
    'featureIDs',
  ]);

  const preloadData = usePreloadData(watchFields);

  /* React.useEffect(() => {
    if (preloadData.countryList) {
      reset({ ...defaultValues });
    }
  }, [preloadData.countryList, reset, defaultValues]); */

  /* const [submitListing, { error, isLoading }] = useSubmitListing(
    convertFormValues,
    user.userID
  ); */

  // const propertyId = state?.property?.propertyID;

  /* const [
    // updateListing,
    { isLoading: 
     // isUpdating, 
      // error: updateError },
  ] = useUpdateListing(convertFormValues, user.userID, 
    // propertyId
    ); */

  const isEdit = useRouteMatch('/dashboard/listing/:id');

  // const history = useHistory();

  const onSubmit = async (values) => {
    // console.log(values);

    createAdvert(
      { ...values },
      {
        onSuccess: () => {
          methods.reset();
          toast.success(
            'Advert request was successfully created, an admin will contact you.',
            {
              style: {
                background: '#d4edda',
                color: '#155724',
              },
            }
          );
        },
      }
    );
  };

  /* React.useEffect(() => {
    const responseError =
   //   error?.ResponseMessage || updateError?.ResponseMessage || {};
   // if (!responseError) return;
    Object.keys(responseError).forEach((key) => {
      if (['latitude', 'longitude'].includes(key)) {
        setError('propertyLocation', {
          type: 'manual',
          message: 'Property location was not provided',
        });
      } else {
        setError('propertyLocation', {
          type: 'manual',
         // message: error.ResponseMessage[key][0],
        });
      }
    });
  }, [error, updateError, setError]); */

  // const onSubmit = () => { };

  return (
    <Form className="row g-3" onSubmit={onSubmit} methods={methods}>
      {error ||
        (error && (
          <div
            className="alert alert-danger mb-2"
            style={{ textAlign: 'center' }}
            role="alert"
          >
            {error?.Detail || 'Sever Error, please try again'}
          </div>
        ))}
      <div className="col-12 form-block">
        <div className="h5 mb-2 mt-1">Contact Person</div>
        <div className="row">
          <Input
            containerClassName="col-md-6"
            className="form-control br-0"
            label="First Name"
            name="firstName"
            validation={{ required: true }}
          />
          <Input
            containerClassName="col-md-6"
            className="form-control br-0"
            label="Last Name"
            name="lastName"
            validation={{ required: true }}
          />
          <Input
            containerClassName="col-md-6"
            className="form-control br-0"
            label="E-mail"
            placeholder="Please enter e-mail"
            name="email"
            validation={{ required: true }}
          />
          <Input
            containerClassName="col-md-6"
            className="form-control br-0"
            label="Phone Number"
            name="phoneNumber"
            validation={{ required: true, pattern: /[0-9]/ }}
          />
          <Input
            containerClassName="col-md-6"
            className="form-control br-0"
            label="Company Name"
            name="companyName"
            validation={{ required: true }}
          />
          <Input
            containerClassName="col-md-6"
            className="form-control br-0"
            label="Company Website"
            name="companyWebsite"
          />
        </div>
      </div>
      <div className="row" />
      <FileInput
        label="Attach Image"
        description="Choose File"
        accept="image/*"
        id="advert_image"
        name="advert_image"
        validation={{ required: true }}
      />
      <div className="row" />
      <TextField
        containerClassName="col-12 mb-3"
        className="form-control br-0"
        label="Information about your product"
        placeholder="Maximum of 400 Characters"
        id="product_info"
        rows="3"
        name="productInformation"
        validation={{ required: true }}
        maxLength="400"
      />
      <div className="col-12">
        <div className="submitlistingpage-btns">
          <div className="flexend-btns mb-3">
            <Button className="btn btn-outline br-0" type="button">
              CANCEL
            </Button>
            <Button
              className="btn btn-danger br-0"
              type="submit"
              isLoading={isLoading}
            >
              {isEdit ? 'UPDATE' : 'SUBMIT'}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AdvertiseForm;
