/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { moneyFormat } from 'utils/moneyFormat';
import Heart from '../../static/images/heart.svg';
import Eye from '../../static/images/eye.svg';

// style
import './PropertyCardStyle.css';

const PropertyCard = (props) => {
  const {
    date_diff,
    state,
    address,
    price,
    property_type,
    primary_image_link,
    propertyID,
    view_count,
    like_count,
    no_bedroom,
    no_bathroom,
    square_feet,
    availability_status,
    likeProperty,
  } = props;
  return (
    <div className="propertycard">
      <div className="propertycard-image">
        <img src={primary_image_link} alt="card" />
        <div className="propertycard-overlay">
          <span className="propertycard-overlay_posted-time">{date_diff}</span>
          <div className="propertycard-overlay_desc">
            <p className="propertycard-overlay_desc-city">{state?.name}</p>
            <p className="propertycard-overlay_desc-type">{property_type?.name}</p>
            <p className="propertycard-overlay_desc-price">
              NGN {moneyFormat(price)}
            </p>
          </div>
          <div className="propertycard-reaction">
            <span>
              <img src={Eye} alt="Eyes" />
              <span className="ml-1">{view_count || 0} Views</span>
            </span>
            <span
              className="ml-3"
              onClick={(e) => {
                e.stopPropagation();
                likeProperty(propertyID);
              }}
              role="presentation"
            >
              <img src={Heart} alt="Eyes" />
              <span className="ml-1">{like_count || 0} Likes</span>
            </span>
          </div>
        </div>
      </div>
      <div className="propertycard-desc">
        {/* <h1 className="propertycard-header">{address}</h1> */}
        <div className="propertycard-features">
          <span>{no_bedroom} Bedrooms</span>
          <span>{no_bathroom} Bathrooms</span>
          <span>{square_feet} sqft.</span>
        </div>
        <span className="propert-status">
          Property status:
          <span className="ml-1 availability-status">{availability_status}</span>
        </span>
        <div className="propertycard-btn">
          <Link
            exact
            to={{
              pathname: `/property/${propertyID}`,
              state: {
                property: { ...props },
              },
            }}
            className="btn btn-outline-danger br-0"
          >
            VIEW PROPERTY
          </Link>
        </div>
      </div>
    </div>
  );
};

PropertyCard.propTypes = {
  date_diff: PropTypes.string.isRequired,
  state: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  address: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  property_type: PropTypes.string.isRequired,
  primary_image_link: PropTypes.string.isRequired,
  propertyID: PropTypes.number.isRequired,
  view_count: PropTypes.number.isRequired,
  like_count: PropTypes.number.isRequired,
  no_bedroom: PropTypes.number.isRequired,
  no_bathroom: PropTypes.number.isRequired,
  square_feet: PropTypes.number.isRequired,
  availability_status: PropTypes.string.isRequired,
  likeProperty: PropTypes.func.isRequired,
};

export default PropertyCard;
