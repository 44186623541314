import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useVerifyPayment } from 'hooks/useVerifyPayment';
import { toast } from 'react-toastify';
import gtag from 'ga-gtag';

export default function HandlePaymentCallback() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reference = searchParams.get('reference');
  const flwref = searchParams.get('flwref');
  const trxref = searchParams.get('trxref');
  const txref = searchParams.get('txref');

  const { isError, isLoading, data } = useVerifyPayment({
    trxref: trxref || txref,
    reference: reference || flwref,
  });

  const history = useHistory();

  const showSuccessToast = () => {
    toast.success('Your payment is successfully ', {
      style: {
        background: '#d4edda',
        color: '#155724',
      },
    });
  };

  const trackSuccessfulSubscriptionPayment = () => {
    gtag('event', 'successful_sub_payment', {
      method: 'subscription_payment',
    });
  };

  const redirectAfterPayment = React.useCallback(() => {
    let path = '';
    if (data?.paymentDetail?.payment_type === 'Subscription_Payment') {
      path = '/join_as_pro/subscription/payment/success';
      trackSuccessfulSubscriptionPayment();
    } else {
      path = '/dashboard';
    }
    if (path) history.push(path);
  }, [data, history]);

  useEffect(() => {
    if (data?.paymentStatus === 'Successful') {
      showSuccessToast();
      redirectAfterPayment();
    }
  }, [history, data, redirectAfterPayment]);

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Problem completing payment, please try again</div>;
  return null;
}
