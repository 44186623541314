import React from 'react';

const TownHomeIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.08351 8.50003C9.08351 8.22389 9.30737 8.00003 9.58351 8.00003H10.5835C10.8597 8.00003 11.0835 8.22389 11.0835 8.50003V9.50003C11.0835 9.77617 10.8597 10 10.5835 10H9.58351C9.30737 10 9.08351 9.77617 9.08351 9.50003V8.50003Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M13.5835 8.00003C13.3074 8.00003 13.0835 8.22389 13.0835 8.50003V9.50003C13.0835 9.77617 13.3074 10 13.5835 10H14.5835C14.8597 10 15.0835 9.77617 15.0835 9.50003V8.50003C15.0835 8.22389 14.8597 8.00003 14.5835 8.00003H13.5835Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M9.08351 12.5C9.08351 12.2239 9.30737 12 9.58351 12H10.5835C10.8597 12 11.0835 12.2239 11.0835 12.5V13.5C11.0835 13.7762 10.8597 14 10.5835 14H9.58351C9.30737 14 9.08351 13.7762 9.08351 13.5V12.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M13.5835 12C13.3074 12 13.0835 12.2239 13.0835 12.5V13.5C13.0835 13.7762 13.3074 14 13.5835 14H14.5835C14.8597 14 15.0835 13.7762 15.0835 13.5V12.5C15.0835 12.2239 14.8597 12 14.5835 12H13.5835Z"
      fill="#2B2B2B"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.08351 22C6.87437 22 5.08351 20.2092 5.08351 18V9.4538L4.74201 9.75261C4.32638 10.1163 3.69462 10.0742 3.33093 9.65853C2.96725 9.2429 3.00937 8.61114 3.42501 8.24745L8.95562 3.40817C10.7465 1.84116 13.4205 1.84116 15.2114 3.40817L20.742 8.24745C21.1576 8.61114 21.1998 9.2429 20.8361 9.65853C20.4724 10.0742 19.8406 10.1163 19.425 9.75261L19.0835 9.4538V18C19.0835 20.2092 17.2926 22 15.0835 22H9.08351ZM13.8944 4.91333C12.8576 4.00611 11.3094 4.00611 10.2726 4.91332L7.08351 7.7038V18C7.08351 19.1046 7.97894 20 9.08351 20L9.08351 17.5C9.08351 16.6716 9.75508 16 10.5835 16H13.5835C14.4119 16 15.0835 16.6716 15.0835 17.5V20C16.1881 20 17.0835 19.1046 17.0835 18V7.7038L13.8944 4.91333ZM13.0835 20V18H11.0835V20H13.0835Z"
      fill="#2B2B2B"
    ></path>
  </svg>
);

export default TownHomeIcon;
