/* eslint-disable camelcase */
import * as React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

// style
import './PaginationStyle.css';

const Pagination = ({ handlePageChange, total = 1, per_page = 1 }) => {
  const totalPages = Math.ceil(total / per_page);

  return (
    <ReactPaginate
      previousLabel="previous"
      nextLabel="next"
      breakLabel="..."
      breakClassName="break-me"
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageChange}
      containerClassName="pagination"
      subContainerClassName="pagination-item"
      activeClassName="active"
    />
  );
};

Pagination.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  per_page: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Pagination;
