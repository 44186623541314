export const moneyFormat = (num) => {
  if (num) {
    const numParts = num?.toString().split('.');
    numParts[0] = numParts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return numParts.join('.');
  }

  return num;
};

export const formatAmount = (amount, currencySymbol = '₦') => {
  if (isNaN(amount)) {
    return 'NAN';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const formattedAmount = formatter.format(amount).replace(/\$/g, currencySymbol);

  return formattedAmount;
};
