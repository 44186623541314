import React from 'react';
import gtag from 'ga-gtag';

// components
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';

// styles
import './PrivacyPageStyle.css';

const PrivacyPage = () => {
  const trackPrivacyPage = () => {
    gtag('event', 'privacy_page', {
      method: 'privacy_page',
    });
  };

  React.useEffect(() => {
    trackPrivacyPage();
  }, []);

  return (
    <div className="privacypage">
      <Navbar />
      <div className="container">
        <div className="privacypage-container">
          <h1>Privacy Policy</h1>
          <p>
            The protection of customer information is one of the most important
            responsibilities of VST. This policy represents our commitment to you
            regarding the safeguard and use of your information. When you entrust us
            with your information, it shall only be used within our strict
            guidelines. VST has developed its Privacy Policy to help you understand
            what data we collect, why we collect it, and what we do with it.
          </p>
          <p>
            The 100bricks team is collectively referred to as “we,” “us,” or “our”
            while users or visitors on the platform are referred to as “you” or
            “yours”.{' '}
          </p>
          <div className="termspage-content">
            {/* <h1>1. Personal Data </h1>
            <p>
              Personal data is defined under the Nigerian Data Protection and
              Compliance Regulations (NDPR) as: “Any information relating to an
              identified or identifiable natural person ('data subject'); an
              identifiable natural person is one who can be identified, directly or
              indirectly, in particular by reference to an identifier such as a name,
              an identification number, location data, an online identifier or to one
              or more factors specific to the physical, physiological, genetic,
              mental, economic, cultural or social identity of that natural person”.
            </p> */}
            <h1>The Information We Collect</h1>
            <p>
              With the use of this platform, your personal information, such as name,
              email address, interests, gender, age, and profile information may be
              requested. As Professional or Business owner, information related to
              your business, including a business description and location may also
              be provided. Also, information and or contents provided such as your
              photos, comments, reviews, searches, photo notes, information provided
              on our communication channels such as phone, emails, SMS, and other
              social medial channels (Facebook, Instagram etc.) are used to customize
              your experience on this platform to suit your individual needs. The
              information provided will only be used in response to your requests for
              our services.
            </p>
            <h1>Automatically Collected Information</h1>
            <p>
              We collect information about your use of our Platform, including your
              search activity, the pages you view, the date and time of your visit,
              and if you use our browser extensions, the content you interact with
              and how you navigate and interact with a page or screen. We also
              collect information that your device provides to us in connection with
              your use of our Platform, such as your browser type, type of device,
              browser language, IP address, mobile carrier, unique device identifier,
              and requested and referring URLs. This information is sent to our
              server by your Internet browser or app and stored in our log files.
            </p>
            {/* <h1>
              4. The Reasons ASO Savings and Loans Plc Collects and Stores Personal
              Data
            </h1>
            <p>
              To provide you with suitable services, ASO needs to collect personal
              data. When you sign up for any of our Online Solutions or other ASO
              products and services or promotions that require registration, we ask
              you for personal information (such as your name, email address and an
              account password). For certain services, such as our payment services,
              we require your debit card information. We typically do not store this
              information and when we do, we maintain the data in encrypted form on
              secure servers. We may combine the information you submit under your
              account with information from other ASO services or third parties in
              order to provide you with a better experience and to improve the
              quality of our services. In any event, ASO is committed to ensuring
              that the information we collect, and use is appropriate for this
              purpose, and does not constitute an invasion of your
              privacy.Additionally, once you open an account with ASO, you have
              automatically signed up to receive marketing materials. However,
              customers who are not interested may decide to unsubscribe.
            </p> */}
            <h1>How We Use Information</h1>
            <p>
              When you sign up and accept our privacy policy, we use personal
              information you submitted to provide, support, and communicate with you
              about our products and services (online or offline the Platform). We do
              this in various ways highlighted below:
            </p>
            <ul>
              <li>
                we use your information to improve our Platform’s functionality,
                display content you post publicly, complete your purchase/order,
                process, store, and deliver requests communicated to us via our
                communication channels (email, phone and chat-box), provide customer
                and support services;
              </li>
              <li>
                we use your information to satisfy any requirement under the law or
                to protect our rights or prevent any concerns relating to fraud or
                security;
              </li>
              <li>
                to display relevant advertising content on our Platform and from
                other third parties subscribed to our platform and evaluate the
                success of such advertising;
              </li>
              <li>
                to meet our compliance obligations, to comply with laws and
                regulations and to share with regulators when necessary;
              </li>
              <li>to perform research and analysis for our business purposes;</li>
              <li>
                to send you general announcements or important notifications about
                our products and services and contents that we thought might meet
                your search criteria on the platform and request your feedback when
                necessary;
              </li>
              <li>
                your log-in information, cookie information, device information, IP
                addresses, and other information may be used to identify you and log
                your activities on our platform in order to personalize your
                experience, such as tailoring the content and information to meet
                your need.
              </li>
            </ul>
            <h1>Sharing Your Information</h1>
            <p>
              We do not rent or sell your personal contact information (such as your
              profile details associated with your account registration) to third
              parties to use for their direct marketing purposes. We only share
              personal information in compliance with the relevant as follows:
            </p>
            <ul>
              <li>
                with visitors and users on our Platform- our goal is to enable
                visitor and users on the website buy, sell and or rent, property(ies)
                posted on the platform. your information may be shared with vendors
                and purchasers who have shown interest in the content uploaded on the
                site, such information as contact details may be shared to aid user
                search more pleasurable.
              </li>
              <li>
                with advertisers and Networks, including interest-based advertising,
                third-party advertiser. we may provide advertising networks,
                advertising partners, and businesses advertising on our platform with
                information but other than personally identifiable information or
                device identifiers, we do not disclose your name, contact
                information. We have also made available the option to opt out of
                such services as provided in our contact email address;
              </li>
              <li>
                when required, we may disclose information about you, as permitted by
                applicable law, if we believe that such disclosure is reasonably
                necessary to;
                <div>
                  <span className="fs-12 block">
                    <span>(a)</span>
                    <span className="ml-1">
                      comply with legal process and law enforcement instructions and
                      orders, such as a search warrant, summons, statute, judicial
                      proceeding, or other legal process served on us, a service
                      provider, or a partner (which may include disclosure to law
                      enforcement, courts, or governmental authorities);
                    </span>
                  </span>
                  <span className="fs-12 block">
                    <span>(b)</span>
                    <span className="ml-1">
                      prevent, investigate, or identify possible wrongdoing in
                      connection with our Platform;
                    </span>
                  </span>
                  <span className="fs-12 block">
                    <span>(c)</span>
                    <span className="ml-1">
                      protect our rights, reputation, property, or that of our
                      visitors, users, corporate affiliates, or the public; or
                    </span>
                  </span>
                  <span className="fs-12 block">
                    <span>(d)</span>
                    <span className="ml-1">
                      enforce compliance with our agreements with you
                    </span>
                  </span>
                  <span className="fs-12 block">
                    <span>(e)</span>
                    <span className="ml-1">
                      comply with AML/CFT guideline and other industry regulators.
                    </span>
                  </span>
                </div>
              </li>
            </ul>

            <h1>Safety and Security</h1>
            <p>
              Personal information is processed and stored in our databases and we
              have taken reasonable steps to secure and protect the information under
              our control, including establishing processes to prevent unauthorized
              access or disclosure of this information. However, whilst we make every
              effort to ensure the security of your information, we are unable to
              guarantee the protection of the information provided from misuse,
              accidental disclosure or unauthorised acts by users. Information
              provided by you may be stored or processed within or outside Nigeria.
            </p>
            <h1>Children</h1>
            <p>
              Our Platform is built for general audiences and is not intended for use
              to children under the age of 18. We do not collect personal information
              from children under 18. If we learn that we have collected personal
              information from a child under age 18, we will take appropriate steps
              to delete that information. If you become aware or believe that a child
              has provided us with personal information, please contact us as
              provided in the contact via our contact address and email provided on
              this platform.
            </p>
            <h1>Updates to this Privacy Policy</h1>
            <p>
              We may revise this Privacy Policy from time to time. The most current
              version of our Privacy Policy will govern our use of information about
              you. If we make any important changes to our Privacy Policy, we will
              notify you by posting it on our Platform.
            </p>

            <h1>More information</h1>
            <p>
              Please do not hesitate to get in touch with us via the address on our
              contact page on this site if you have any questions or queries
              regarding our privacy policy.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPage;
