import * as React from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';
import useFetchPropertyTypes from 'hooks/useFetchPropertyTypes';
import useFetchFeatures from 'hooks/useFetchFeatures';
import useCountryList from 'hooks/useCountryList';
import useStateList from 'hooks/useStateList';
import useCityList from 'hooks/useCityList';
import useTownList from 'hooks/useTownList';
import usePropertyCategories from 'hooks/usePropertyCategories';
import useCurrencyList from 'hooks/useCurrencyList';
import { fieldNumberOptions } from 'mock/fieldOptions';

export const usePreloadData = (watchFields) => {
  const propertyTypes = useFetchPropertyTypes();
  const propertyFeatures = useFetchFeatures();
  const countryList = useCountryList();
  const stateList = useStateList({
    countryID: watchFields.country_id,
  });
  const cityList = useCityList({ stateID: watchFields.state_id });
  const townList = useTownList({ cityID: watchFields.city_id });
  const propertyCategory = usePropertyCategories({
    propertyTypeID: watchFields.property_type_id,
  });
  const currencyList = useCurrencyList();

  return {
    propertyTypes: propertyTypes?.data?.property_types,
    propertyCategory: propertyCategory?.data?.property_categories,
    fieldNumberOptions,
    countryList: countryList?.data?.countries,
    stateList: stateList?.data?.states,
    cityList: cityList?.data?.cities,
    townList: townList?.data?.towns,
    propertyFeatures: propertyFeatures?.data?.property_features,
    currencyList: currencyList?.data?.currencies,
  };
};

export function useSubmitListing(convertFormValues, userID) {
  return useMutation((values) =>
    client('profile/submitListing', {
      data: convertFormValues(values, userID),
      isFormData: true,
    }).then((res) => res)
  );
}

export function useUpdateListing(convertFormValues, userId, propertyId) {
  const cache = useQueryCache();
  return useMutation((values) =>
    client('profile/modifyProperty', {
      data: convertFormValues(values, userId, propertyId),
      isFormData: true,
    }).then((res) => {
      cache.invalidateQueries(['city-list', { userID: userId }]);
      return res;
    })
  );
}

export function useAddToWishList() {
  return useMutation((values) =>
    client('profile/addToWishlist', { data: values }).then((res) => res)
  );
}

export function useGetPropertyDetails(params) {
  return useQuery(['featured-listing', params], (_, fieldParams) =>
    client(`params/getPropertyDetail`, { params: fieldParams }).then((res) => res)
  );
}

export function useMakeComment() {
  const cache = useQueryCache();
  return useMutation((values) =>
    client('comment/saveComment', { data: values }).then((res) => {
      cache.invalidateQueries();
    })
  );
}

export function useMakePropertyOffer() {
  const cache = useQueryCache();
  return useMutation((values) =>
    client('offer/saveOffer', { data: values }).then((res) => {
      cache.invalidateQueries();
    })
  );
}

export function useShareProperty() {
  return useMutation((values) =>
    client('profile/sharePropertyByEmail', { data: values })
  );
}

const initialState = {
  comment: false,
  offer: false,
  share: false,
  backgroundInfo: false,
  floorPlan: false,
  buyProperty: false,
  wishlist: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'comment': {
      return {
        comment: true,
      };
    }
    case 'share': {
      return {
        ...state,
        share: true,
      };
    }
    case 'offer': {
      return {
        ...state,
        offer: true,
      };
    }

    case 'backgroundInfo': {
      return {
        ...state,
        backgroundInfo: true,
      };
    }
    case 'floorPlan': {
      return {
        ...state,
        floorPlan: true,
      };
    }
    case 'buyProperty': {
      return {
        ...state,
        buyProperty: true,
      };
    }
    case 'wishlist': {
      return {
        ...state,
        wishlist: true,
      };
    }
    case 'close': {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export function useModalState() {
  const [modalState, dispatch] = React.useReducer(reducer, initialState);
  return [modalState, dispatch];
}
