/* eslint-disable camelcase */
import React from 'react';
import { toast } from 'react-toastify';

// hooks
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useCreatePropertyLead } from 'hooks/useCreatePropertyLead';
// import useCountryList from 'hooks/useCountryList'; all countries of the world
// import useStateList from 'hooks/useStateList';
import useNigerianState from 'hooks/useNigerianState';

// components
import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { Select } from 'components/common/Select';
import { Button } from 'components/common/Button';

const LeadForm = () => {
  const [createLead, { isLoading, error }] = useCreatePropertyLead();
  const methods = useForm({
    defaultValues: {
      kitchen: 1,
      bathroom: 1,
      // countryId: 1; select AFGANISTAN
      country_id: 161, // Selects Nigeria
    },
  });
  const { id } = useParams();
  const countryId = methods.watch('country_id');

  // const { data: countryData } = useCountryList();
  // const { data: stateData } = useStateList({ countryID: countryId });
  const { data: stateData } = useNigerianState({ countryID: countryId });

  const onSubmit = async (values) => {
    createLead(
      { ...values, property_id: id },
      {
        onSuccess: () => {
          methods.reset();
          toast.success('Property leads created successfully ', {
            style: {
              background: '#d4edda',
              color: '#155724',
            },
          });
        },
      }
    );
  };

  return (
    <Form className="row g-3" onSubmit={onSubmit} methods={methods}>
      {error ||
        (error && (
          <div
            className="alert alert-danger mb-2"
            style={{ textAlign: 'center' }}
            role="alert"
          >
            {error?.Detail || 'Sever Error, please try again'}
          </div>
        ))}

      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="Title"
        options={[
          { id: 'Mr.', name: 'Mr.' },
          { id: 'Mrs.', name: 'Mrs.' },
          {
            id: 'Miss.',
            name: 'Miss.',
          },
          { id: 'Dr.', name: 'Dr.' },
          { id: 'Hon.', name: 'Hon.' },
          { id: 'Engr.', name: 'Engr.' },
          {
            id: 'Bar.',
            name: 'Bar.',
          },
          {
            id: 'Prof.',
            name: 'Prof.',
          },
        ]}
        name="title"
        validation={{ required: true }}
      />

      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="Name"
        name="name"
        validation={{ required: true }}
      />

      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="Email"
        name="email"
        validation={{ required: true }}
      />

      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="Phone"
        name="phone"
        validation={{ required: true, pattern: /[0-9]/ }}
      />

      {/* <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="Country"
        options={countryData?.countries}
        name="country_id"
        validation={{ required: true }}
      /> */}
      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="STATE"
        options={stateData?.states}
        name="state_id"
        validation={{ required: true }}
      />

      {/* <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="Outside Nigeria"
        name="oversea_address"
      /> */}

      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="Where did you learn about us?"
        options={[
          {
            id: 'search',
            name: 'Online Search',
          },
          {
            id: 'instagram',
            name: 'Instagram',
          },
          {
            id: 'headOffice',
            name: 'ASO Savings Head Office',
          },
          {
            id: 'branch',
            name: 'ASO Savings Branch Office',
          },
          {
            id: 'facebook',
            name: 'Facebook',
          },
          {
            id: 'newspaper',
            name: 'Newspaper',
          },

          {
            id: 'radio',
            name: 'Radio',
          },
          {
            id: 'television',
            name: 'Television',
          },
        ]}
        name="platform"
        validation={{ required: true }}
      />

      <div className="col-12">
        <div className="submitlistingpage-btns">
          <div className="flexend-btns">
            <Button
              className="btn btn-danger br-0"
              type="submit"
              isLoading={isLoading}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default LeadForm;
