/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { moneyFormat } from 'utils/moneyFormat';
import { pluraLize } from 'utils/textUtils';
import { useLikeProperty } from 'hooks/useLikeProperty';

// style
import './SalesCardStyle.css';

const SalesCard = (props) => {
  const history = useHistory();
  // console.log(`prop typeee`, props.property_type.name)

  const {
    propertyID,
    primary_image_link,
    price,
    address,
    description,
    date_diff,
    view_count,
    like_count,
    no_bedroom,
    no_bathroom,
    square_feet,
    availability_status,
    queryCacheParams,
    disableCardNavigation,
    prop_type,
    handleAction,
    children,
  } = props;

  const handleClickAction = () => {
    if (disableCardNavigation) return;
    history.push({
      pathname: `/property/${propertyID}`,
      state: {
        propertyID,
      },
    });
  };

  const [likeProperty] = useLikeProperty(queryCacheParams);
  const handleLikeProperty = (e) => {
    e.stopPropagation();
    likeProperty({
      propertyId: propertyID,
    });
  };

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      layout
      className={`salescard ${disableCardNavigation ? 'navigation-disabled' : ''}`}
      onClick={handleClickAction}
      role="presentation"
    >
      <div className="salescard-image">
        <img src={primary_image_link} alt="card" />
        <span className="salescard-posted_time">{date_diff}</span>
        {/* <div className="salescard-header">
          <p className="salescard-header_price">NGN {moneyFormat(price)}</p>
          <div className="salescard-reaction">
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0)">
                  <path
                    d="M0.833252 10C0.833252 10 4.16658 3.33337 9.99992 3.33337C15.8333 3.33337 19.1666 10 19.1666 10C19.1666 10 15.8333 16.6667 9.99992 16.6667C4.16658 16.6667 0.833252 10 0.833252 10Z"
                    stroke="rgba(19, 21, 22, 0.6)"
                    strokeOpacity="0.9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                    stroke="rgba(19, 21, 22, 0.6)"
                    strokeOpacity="0.9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="20" height="20" fill="rgba(19, 21, 22, 0.6)" />
                  </clipPath>
                </defs>
              </svg>
              <span className="ml-1"> {view_count || 0} Views </span>
            </span>
            <span className="ml-3" onClick={handleLikeProperty} role="presentation">
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.4983 3.64956C16.094 3.24503 15.6139 2.92412 15.0855 2.70517C14.5571 2.48623 13.9907 2.37354 13.4188 2.37354C12.8468 2.37354 12.2804 2.48623 11.752 2.70517C11.2236 2.92412 10.7435 3.24503 10.3392 3.64956L9.50001 4.48873L8.66085 3.64956C7.84409 2.83281 6.73633 2.37396 5.58126 2.37396C4.4262 2.37396 3.31844 2.83281 2.50168 3.64956C1.68492 4.46632 1.22607 5.57408 1.22607 6.72915C1.22607 7.88421 1.68492 8.99197 2.50168 9.80873L3.34085 10.6479L9.50001 16.8071L15.6592 10.6479L16.4983 9.80873C16.9029 9.40438 17.2238 8.92429 17.4427 8.39589C17.6617 7.86748 17.7744 7.30112 17.7744 6.72915C17.7744 6.15718 17.6617 5.59081 17.4427 5.06241C17.2238 4.534 16.9029 4.05391 16.4983 3.64956V3.64956Z"
                  stroke="rgba(19, 21, 22, 0.6)"
                  strokeOpacity="0.9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-1"> {like_count || 0} Likes</span>
            </span>
          </div>
        </div> */}
      </div>
      <div className="container">
        <div className="d-flex flex-row justify justify-content-between mt-3">
          <div>
            <span className="ml-1 money">
              <span>NGN {moneyFormat(price)}</span>
            </span>
          </div>
          <div>
            <span className="ml-3" onClick={handleLikeProperty} role="presentation">
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.4983 3.64956C16.094 3.24503 15.6139 2.92412 15.0855 2.70517C14.5571 2.48623 13.9907 2.37354 13.4188 2.37354C12.8468 2.37354 12.2804 2.48623 11.752 2.70517C11.2236 2.92412 10.7435 3.24503 10.3392 3.64956L9.50001 4.48873L8.66085 3.64956C7.84409 2.83281 6.73633 2.37396 5.58126 2.37396C4.4262 2.37396 3.31844 2.83281 2.50168 3.64956C1.68492 4.46632 1.22607 5.57408 1.22607 6.72915C1.22607 7.88421 1.68492 8.99197 2.50168 9.80873L3.34085 10.6479L9.50001 16.8071L15.6592 10.6479L16.4983 9.80873C16.9029 9.40438 17.2238 8.92429 17.4427 8.39589C17.6617 7.86748 17.7744 7.30112 17.7744 6.72915C17.7744 6.15718 17.6617 5.59081 17.4427 5.06241C17.2238 4.534 16.9029 4.05391 16.4983 3.64956V3.64956Z"
                  stroke="rgba(19, 21, 22, 0.6)"
                  strokeOpacity="0.9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-1"> {like_count || 0} </span>
            </span>
          </div>
        </div>
        <div className="d-flex flex-row justify justify-content-between mt-2">
          <div>
            <div className="justify-content-start text-dark name">
              {props.name != null && props.name.length > 15
                ? `${props.name.substring(0, 13)}..`
                : props.name}
            </div>
          </div>
          <div>
            <div className="justify-content-end address">
              {address != null && address.length > 15
                ? `${address.substring(0, 13)}..`
                : address}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify justify-content-between mt-2">
          <div>
            <span>{square_feet} </span>
          </div>
          <div>
            <span className="ml-1">{props.property_type?.name}</span>
          </div>
        </div>
        <div className="d-flex flex-row justify justify-content-between mt-2">
          <div className="bed-bath">
            <span>
              {pluraLize('Bedrooms', no_bedroom)}/
              {pluraLize('Bathrooms', no_bathroom)}
            </span>
          </div>
          <div>
            <span className="availability-status">{availability_status}</span>
          </div>
        </div>
        <div className="d-flex flex-row justify justify-content-between mt-2">
          <div className="views">
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0)">
                  <path
                    d="M0.833252 10C0.833252 10 4.16658 3.33337 9.99992 3.33337C15.8333 3.33337 19.1666 10 19.1666 10C19.1666 10 15.8333 16.6667 9.99992 16.6667C4.16658 16.6667 0.833252 10 0.833252 10Z"
                    stroke="rgba(19, 21, 22, 0.6)"
                    strokeOpacity="0.9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                    stroke="rgba(19, 21, 22, 0.6)"
                    strokeOpacity="0.9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="20" height="20" fill="rgba(19, 21, 22, 0.6)" />
                  </clipPath>
                </defs>
              </svg>
              <span className="ml-1"> {view_count || 0} Views </span>
            </span>
          </div>
        </div>
      </div>

      {/* <h1 className="salescard-desc_header">{address}</h1> */}
      {/* <p className="salescard-desc_text">{description}</p> */}
      {/* <span className="propert-status">
          Status:
          <span className="ml-1 availability-status"> {availability_status}</span>
        </span> */}
      {/* <span className="propert-status">
          Property type:
          <span className="ml-1"> {props.property_type?.name}</span>
        </span> */}

      <div className="salescard-btn">{children}</div>
    </motion.div>
  );
};

SalesCard.propTypes = {
  price: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  description: PropTypes.string,
  date_diff: PropTypes.string,
  primary_image_link: PropTypes.string,
  prop_type: PropTypes.string,
  propertyID: PropTypes.number.isRequired,
  handleAction: PropTypes.func.isRequired,
};

SalesCard.defaultProps = {
  price: 120000000,
  address: '47 MONTGOMERY ROAD, WUSE, ABUJA',
  description: `Does it have pet-friendly rentals? What are the crime rates? How are the
  schools`,
  date_diff: '25days ago',
  primary_image_link:
    'https://images.unsplash.com/photo-1576941089067-2de3c901e126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1143&q=80"',
};

export default SalesCard;
