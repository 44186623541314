import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import useAccountConfirmation from 'hooks/useAccountConfirmation';
import { useAuth } from 'context/auth-context';

import AsoLogo from 'static/images/logo.jpg';
import { LoadingIcon } from 'static';
import './ConfirmAccountStyle.css';

export default function ConfirmEmail() {
  const { search } = useLocation();
  const reference = new URLSearchParams(search).get('reference');
  const { data, isLoading, error, isSuccess } = useAccountConfirmation(reference);

  const { saveUserData } = useAuth();

  const handleUserLogin = () => {
    if (isSuccess && data) {
      saveUserData(data);
    }
  };

  if (error) {
    return <div className="container text-center">Link has expired or invalid</div>;
  }

  if (isLoading) {
    return (
      <div className="confirm-account-page">
        <LoadingIcon />
      </div>
    );
  }

  if (isSuccess) {
    return (
      <section className="auth">
        <div className="container">
          <div className="row">
            <div className="col-md-4" />
            <div className="col-md-4">
              <div className="register-container">
                <div className="app-logo">
                  <Link to="/">
                    <img src={AsoLogo} alt="App Logo" />
                  </Link>
                </div>
                <p className="register-intro">
                  <>
                    <div
                      className="alert alert-success mb-2"
                      style={{ textAlign: 'center' }}
                      role="alert"
                    >
                      Account Verification Successful
                    </div>
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleUserLogin}
                      >
                        Click to login
                      </button>
                    </div>
                  </>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  return null;
}
