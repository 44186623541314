const responseType = process.env.REACT_APP_GOOGLE_AUTH_RESPONSE_TYPE;
// const state = process.env.REACT_APP_SOCIAL_AUTH_STATE;

// const facebookBaseURL = process.env.REACT_APP_FACEBOOK_AUTH_BASE_URL;
// const googleBaseURL = process.env.REACT_APP_FACEBOOK_AUTH_BASE_URL;
// const scope = process.env.REACT_APP_GOOGLE_AUTH_SCOPE;
// const responseType = process.env.REACT_APP_GOOGLE_AUTH_RESPONSE_TYPE;
// const state = process.env.REACT_APP_SOCIAL_AUTH_STATE;
// const facebookRedirectUri = process.env.REACT_APP_FACEBOOK_AUTH_REDIRECT;
// const googleRedirectUri = process.env.REACT_APP_GOOGLE_AUTH_REDIRECT;
// const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// const faceBookClientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;

// const handleGoogleLogin = () => {
//   // window.location.href = `https://accounts.google.com/signin/oauth/oauthchooseaccount?client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid%20profile%20email&response_type=code&state=V0NM5rBNGvg8tztb6vM3pi8xEYSDD22pdy1tbd5o&o2v=1&as=WedeWDI7WZIbmwcpQVCsLw&flowName=GeneralOAuthFlow`;
//   window.location.href = `${facebookBaseURL}?client_id=${faceBookClientId}&redirect_uri=${facebookRedirectUri}&scope=email&response_type=code&state=NFEBeOwo9zHtVx4CxE9I1YvD4wJnIVK8j8qfhQwP`;
// };

const AuthProvider = {
  redirectAfterLogin: '',
};

const GoogleSignin = () => {
  const googleBaseURL = process.env.REACT_APP_GOOGLE_AUTH_BASE_URL;
  const googleAuthScope = process.env.REACT_APP_GOOGLE_AUTH_SCOPE;
  const googleRedirectUri = `${window.location.origin}/social_auth/google`;
  const googleAuthClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  window.location.href = `${googleBaseURL}?client_id=${googleAuthClientId}&redirect_uri=${googleRedirectUri}&scope=${googleAuthScope}&response_type=${responseType}&state=V0NM5rBNGvg8tztb6vM3pi8xEYSDD22pdy1tbd5ologin-redirect=${AuthProvider.redirectAfterLogin}&o2v=1&as=WedeWDI7WZIbmwcpQVCsLw&flowName=GeneralOAuthFlow`;
};

const FacebookSignin = () => {
  const facebookBaseURL = process.env.REACT_APP_FACEBOOK_AUTH_BASE_URL;
  const facebookAuthScope = process.env.REACT_APP_FACEBOOK_AUTH_SCOPE;
  const facebookRedirectUri = `${window.location.origin}/social_auth/facebook`;
  const facebookAuthClientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
  window.location.href = `${facebookBaseURL}?client_id=${facebookAuthClientId}&redirect_uri=${facebookRedirectUri}&scope=${facebookAuthScope}&response_type=${responseType}&state=NFEBeOwo9zHtVx4CxE9I1YvD4wJnIVK8j8qfhQwPlogin-redirect=${AuthProvider.redirectAfterLogin}`;
};

AuthProvider.FacebookSignin = FacebookSignin;
AuthProvider.GoogleSignin = GoogleSignin;

export default AuthProvider;
