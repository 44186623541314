/* eslint-disable camelcase */
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

// components
import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { DatePicker } from 'components/common/DatePicker';
import { Radio } from 'components/common/Radio';
import { Select } from 'components/common/Select';
import { TextField } from 'components/common/TextField';
import { CheckBox } from 'components/common/CheckBox';
import { FileInput } from 'components/common/FileInput';
import { Button } from 'components/common/Button';
import PlacesAutocomplete from 'components/PlacesAutocomplete';

// hooks
import { useAuth } from 'context/auth-context';
import { usePreloadData, useUpdateListing, useSubmitListing } from './hooks';
import { convertFormValues, getFormDefaultValues } from './utils';

const SubmitListingForm = () => {
  const { state } = useLocation();

  const defaultValues = React.useMemo(() => {
    return getFormDefaultValues(state?.property);
  }, [state]);

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      kitchen: 1,
      bathroom: 1,
      year_built: null,
    },
  });

  const { setError, reset } = methods;
  const { user } = useAuth();
  const watchFields = methods.watch([
    'property_type_id',
    'country_id',
    'state_id',
    'city_id',
    'featureIDs',
  ]);

  const preloadData = usePreloadData(watchFields);

  React.useEffect(() => {
    if (preloadData.countryList) {
      reset({ ...defaultValues });
    }
  }, [preloadData.countryList, reset, defaultValues]);

  const [submitListing, { error, isLoading }] = useSubmitListing(
    convertFormValues,
    user.userID
  );

  const propertyId = state?.property?.propertyID;

  const [
    updateListing,
    { isLoading: isUpdating, error: updateError },
  ] = useUpdateListing(convertFormValues, user.userID, propertyId);

  const isEdit = useRouteMatch('/dashboard/listing/:id');

  const history = useHistory();

  const onSubmit = async (values) => {
    const fn = isEdit ? updateListing : submitListing;
    const onSuccess = (data) => {
      history.push({
        pathname: `/property/${data?.property.propertyID}`,
        state: {
          property: data.property,
        },
      });
    };
    fn(values, { onSuccess });
  };

  React.useEffect(() => {
    const responseError =
      error?.ResponseMessage || updateError?.ResponseMessage || {};
    if (!responseError) return;
    Object.keys(responseError).forEach((key) => {
      if (['latitude', 'longitude'].includes(key)) {
        setError('propertyLocation', {
          type: 'manual',
          message: 'Property location was not provided',
        });
      } else {
        setError('propertyLocation', {
          type: 'manual',
          message: error.ResponseMessage[key][0],
        });
      }
    });
  }, [error, updateError, setError]);

  return (
    <Form className="row g-3" onSubmit={onSubmit} methods={methods}>
      {error || updateError ? (
        <div
          className="alert alert-danger mb-2"
          style={{ textAlign: 'center' }}
          role="alert"
        >
          {error?.Detail || updateError?.Detail || 'Sever Error'}
        </div>
      ) : null}
      <div className="col-12 form-block">
        <Radio
          containerClassName="form-check form-check-inline"
          type="radio"
          name="sale_or_rent"
          id="sale_or_rent-radio1"
          label="For Sale"
          defaultValue="on"
          validation={{ required: true }}
          labelClassName="form-check-label"
        />

        <Radio
          containerClassName="form-check form-check-inline"
          type="radio"
          name="sale_or_rent"
          id="sale_or_rent-radio2"
          label="For Rent"
          defaultValue="off"
          labelClassName="form-check-label"
          validation={{ required: true }}
        />
      </div>
      <div className="col-12 form-block">
        <Radio
          containerClassName="form-check form-check-inline"
          type="radio"
          name="for_sale_aso_or_owner"
          id="sale_or_rent-radio3"
          label="For Sale Or Rent By 100 Bricks"
          labelClassName="form-check-label"
          validation={{ required: true }}
          defaultValue="on"
        />

        <Radio
          containerClassName="form-check form-check-inline"
          type="radio"
          name="for_sale_aso_or_owner"
          id="sale_or_rent-radio3"
          label="For Sale Or Rent By Owner"
          labelClassName="form-check-label"
          defaultValue="off"
          validation={{ required: true }}
        />
      </div>
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="PROPERTY NAME"
        name="name"
        validation={{ required: true }}
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="SELLING PRICE"
        name="price"
        validation={{ required: true, pattern: /[0-9]/ }}
      />
      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="PAYMENT PLAN"
        options={preloadData?.paymentPlanOptions}
        name="payment_plan_option_id"
        validation={{ required: true }}
      />
      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="CURRENCY"
        options={[{ id: 1, name: 'NGN' }]}
        // options={preloadData?.currencyList}
        name="currency_id"
        defaultValue="1"
        // validation={{ required: true }}
      />
      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="PROPERTY TYPE"
        options={preloadData?.propertyTypes}
        name="property_type_id"
        validation={{ required: true }}
      />
      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="PROPERTY CATEGORY TYPE"
        options={preloadData?.propertyCategory}
        name="property_category_id"
        validation={{ required: true }}
      />
      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="NUMBER OF ROOMS"
        options={preloadData?.fieldNumberOptions}
        name="bedrooms"
        validation={{ required: true }}
      />
      <Input
        containerClassName="col-md-6"
        className="form-select br-0"
        label="NUMBER OF BATHROOMS"
        name="bathrooms"
        type="number"
        min={1}
        validation={{ required: true }}
      />
      <Input
        containerClassName="col-md-6"
        className="form-select br-0"
        label="NUMBER OF KITCHENS"
        name="kitchen"
        type="number"
        min={1}
        validation={{ required: true }}
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="SQUARE FOOT"
        name="square_feet"
        type="number"
        min={1}
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="CAR PARKING"
        name="car_parking"
      />
      <DatePicker
        containerClassName="col-md-6"
        label="YEAR BUILT"
        className="form-control br-0"
        name="year_built"
      />

      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="COUNTRY"
        options={preloadData?.countryList}
        name="country_id"
        validation={{ required: true }}
        defaultValue="161"
      />
      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="STATE"
        options={preloadData?.stateList}
        name="state_id"
        validation={{ required: true }}
      />
      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="CITY"
        options={preloadData?.cityList}
        name="city_id"
        validation={{ required: true }}
      />
      <Select
        containerClassName="col-md-6"
        className="form-select br-0"
        label="TOWN"
        options={preloadData?.townList}
        name="town_id"
      />

      <Controller
        name="propertyLocation"
        control={methods.control}
        render={({ onChange, value }) => (
          <div className="col-md-6">
            <PlacesAutocomplete
              handleSelected={(location) => {
                onChange(location);
              }}
              value={value}
              containerClassName="col-md-6"
              label="PROPERTY ADDRESS"
            />
            <span style={{ color: '#dc3545' }}>
              {methods.errors && (
                <ErrorMessage name="propertyLocation" errors={methods.errors} />
              )}
            </span>
          </div>
        )}
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="VIDEO LINK"
        name="video_link"
        validation={{ required: false }}
      />
      <div className="row" />

      <TextField
        containerClassName="col-12 mb-3"
        className="form-control br-0"
        label="PROPERTY DESCRIPTION"
        id="PROPERTY DESCRIPTION"
        rows="3"
        name="description"
        validation={{ required: true }}
      />
      <div className="col-12">
        {preloadData?.propertyFeatures?.map(({ id, name }) => (
          <CheckBox
            key={id}
            containerClassName="form-check form-check-inline"
            className="form-check-input"
            id="inlineCheckbox1"
            value={id}
            name="featureIDs[]"
            labelClassName="form-check-label"
            label={name}
          />
        ))}
      </div>
      <FileInput
        label="UPLOAD PROPERTY IMAGE"
        description="Click to upload property images"
        name="primary_image"
        accept="image/*"
        preview={
          state?.property?.primary_image_link
            ? [state?.property?.primary_image_link]
            : []
        }
        validation={{
          required: !isEdit || (isEdit && !state?.property?.primary_image_link),
        }}
      />
      <FileInput
        label="UPLOAD OPTIONAL IMAGES"
        description="Click to upload property documents"
        name="optional_images"
        multiple
        accept="image/*"
        preview={state?.property?.other_image_links?.map(({ link }) => link)}
      />
      <FileInput
        label="UPLOAD PROPERTY DOCUMENTS"
        description="Click to upload property documents"
        name="property_docs"
        validation={{
          required: !isEdit,
        }}
      />
      <FileInput
        label="UPLOAD FLOOR PLAN"
        description="Click to upload property floor plan"
        name="floor_plans"
        preview={
          state?.property?.floor_plan
            ? state?.property?.floor_plan?.map(({ link }) => link)
            : []
        }
        validation={{
          required: !isEdit || (isEdit && !state?.property?.floor_plan),
        }}
      />
      <div className="col-12">
        <div className="submitlistingpage-btns">
          <div className="flexend-btns">
            <Button className="btn btn-outline br-0" type="button">
              CANCEL
            </Button>
            <Button
              className="btn btn-danger br-0"
              type="submit"
              isLoading={isLoading || isUpdating}
            >
              {isEdit ? 'UPDATE' : 'SUBMIT'}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default SubmitListingForm;
