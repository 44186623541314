import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function usePropertyCategoryCount(params) {
  return useQuery(['property-category-count', params], (_, fieldParams) =>
    client('params/getPropertyByCategory', { params: fieldParams }).then(
      (res) => res
    )
  );
}
