import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export function useFetchPropertyOffers(params) {
  return useQuery(
    ['property-offers', params],
    (_, fieldParams) =>
      client(`offer/getOffers`, { params: fieldParams }).then((res) => res),
    { enabled: params.property_id }
  );
}
