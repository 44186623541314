/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { LoadingIcon } from 'static';

export function Button({ isLoading, children, type, ...rest }) {
  return (
    <button type={type || 'button'} disabled={rest.disabled || isLoading} {...rest}>
      {isLoading ? <LoadingIcon style={{ height: '30px' }} /> : children}
    </button>
  );
}

Button.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
};

Button.defaultProps = {
  isLoading: false,
  children: null,
  type: 'button',
};
