/* eslint-disable react/button-has-type */
import React from 'react';

import './Filters.css';

// eslint-disable-next-line react/prop-types
const Filters = ({ properties, setFiltered, activeButton, setActiveButton }) => {
  React.useEffect(() => {
    if (activeButton === '') {
      setFiltered(properties);
    }
    // eslint-disable-next-line react/prop-types
    const filtered = properties.filter((property) =>
      property.property_type?.name.includes(activeButton)
    );
    setFiltered(filtered);
  }, [activeButton, properties, setFiltered]);

  return (
    <div className="filter__container">
      <button className="btn btn-dark" onClick={() => setActiveButton('')}>
        All
      </button>
      <button className="btn btn-dark" onClick={() => setActiveButton('Land')}>
        Land
      </button>
      <button className="btn btn-dark" onClick={() => setActiveButton('Commercial')}>
        Commercial
      </button>
      <button
        className="btn btn-dark"
        onClick={() => setActiveButton('Residential')}
      >
        Residential
      </button>
    </div>
  );
};

export default Filters;
