import React, { useState } from 'react';
import InputRange from 'react-input-range';
import { Button } from 'components/common/Button';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { formatAmount } from 'utils/moneyFormat';
import { useFormData } from 'context/approval-form-context';

const DownPayment = ({ setDetailStep }) => {
  const { data, setFormValues } = useFormData();
  const [error, setError] = useState('');
  const minValue = 7000000;
  const maxValue = 95000000;
  const [sliderValue, setSliderValue] = useState(data?.down_payment);

  const handleSliderChange = (value) => {
    if (value > 0) {
      setSliderValue(value);
      setError('');
    } else {
      setError('Select a price range');
    }
  };

  const handleNext = () => {
    if (sliderValue) {
      setFormValues({ down_payment: sliderValue });
      setDetailStep(3);
    } else {
      setError('Down payment is required');
    }
  };

  const handleBack = () => {
    setDetailStep(1);
  };

  return (
    <div>
      <div>
        <h3 className="input-label">How much do you have for a down payment?</h3>
        <p className="input-label-description">(it's OK to estmate)</p>
      </div>
      <div className="slider-container">
        <div className="d-flex justify-content-center gap-3">
          <p className="input-range-label">{formatAmount(minValue)}</p>
          <span className="input-range-label">{'-'}</span>
          <p className="input-range-label">{formatAmount(sliderValue)}</p>
        </div>
        <InputRange
          maxLabel=""
          minLabel=""
          step={500000}
          maxValue={maxValue}
          minValue={minValue}
          value={sliderValue}
          onChange={handleSliderChange}
        />
        {error ? <p className="form-error">{error}</p> : null}
        <p className="tip">Why do you ask for this?</p>
      </div>
      <div className="d-flex flex-column justify-content-center mx-auto col-12 col-md-9 gap-5">
        <Button
          onClick={handleNext}
          className="btn btn-dark br-0 w-100 rounded-pill mt-5 no-border"
          type="submit"
        >
          Next
        </Button>

        <button
          onClick={handleBack}
          className="back-button text-center d-flex gap-2 justify-content-center align-items-center"
        >
          <IoIosArrowRoundBack />
          <span className="text-underline">Back</span>
        </button>
      </div>
    </div>
  );
};

export default DownPayment;
