import React from 'react';

const HomeServiceCard = ({ title, services }) => {
  return (
    <section className="service-directory-card">
      <h3 className="title">{title}</h3>
      {services.map((service, index) => (
        <p className="service-item" key={index}>
          <span className="count">{service.count}</span> {service.name}
        </p>
      ))}
    </section>
  );
};

export default HomeServiceCard;
