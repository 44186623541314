import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

export function useLikeProperty(queryCacheParam) {
  const cache = useQueryCache();
  return useMutation((values) =>
    client('params/likeProperty', {
      data: values,
    }).then((res) => {
      const propertyQueryName = queryCacheParam[0];
      console.log(queryCacheParam);
      const data = cache.getQueryData(queryCacheParam);
      const propertyIndex = data?.[propertyQueryName]?.data?.findIndex(
        ({ propertyID }) => propertyID === values.propertyId
      );

      if (propertyIndex !== -1) {
        const propertyToUpdate = data[propertyQueryName].data;
        propertyToUpdate[propertyIndex] = res.property;
        cache.setQueryData(queryCacheParam, {
          ...data,
          [propertyQueryName]: {
            ...data[propertyQueryName],
            data: propertyToUpdate,
          },
        });
      }

      return res;
    })
  );
}
