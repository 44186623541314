/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useAuth } from 'context/auth-context';
import useSignUp from 'hooks/useSignUp';
// import Logo from 'static/images/logo.jpeg';
import RegisterForm from './RegisterForm';
// import OldBricksLogo from '../../static/images/aso-logo.svg';
import BricksLogo from '../../static/images/logo.jpg';
// style
import './RegisterStyle.css';

const Register = () => {
  const auth = useAuth();
  const [signUp, { isLoading, error, isSuccess, data }] = useSignUp();

  if (auth.isAuthenticated) return <Redirect to="/dashboard" />;

  return (
    <section className="auth">
      <div className="container">
        <div className="row">
          <div className="col-md-4" />
          <div className="col-md-4">
            <div className="register-container">
              <div className="app-logo">
                <Link to="/">
                  <img className="logo-image" src={BricksLogo} alt="App Logo" />
                </Link>
              </div>
              <p className="register-intro">
                {isSuccess
                  ? 'Please confirm account to continue'
                  : 'Welcome to 100Bricks, please create an account to continue'}
              </p>
              {isSuccess ? (
                <>
                  <div
                    className="alert alert-success mb-2"
                    style={{ textAlign: 'center' }}
                    role="alert"
                  >
                    {data?.Detail}
                  </div>
                  <div className="text-center">
                    <Link to="/">Back home</Link>
                  </div>
                </>
              ) : (
                <>
                  <RegisterForm
                    signUp={signUp}
                    error={error}
                    isLoading={isLoading}
                    authProvider={auth.provider}
                    fullWidth
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-md-4" />
        </div>
      </div>
    </section>
  );
};
export default Register;
