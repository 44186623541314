import React from 'react';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import gtag from 'ga-gtag';
import Navbar from 'components/Navbar';
import SalesCard from 'components/SalesCard';
import Loader from 'components/Loader';
import Filters from 'components/PropertyFilters/Filters';

// Stylesheet
import './AllPropertiesStyle.css';

const AllProperties = () => {
  const [properties, setProperties] = React.useState([]);
  const [filtered, setFiltered] = React.useState([]);
  const [activeButton, setActiveButton] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const getProperties = async () => {
    setLoading(true);
    gtag('event', 'all_properties', {
      all_properties: 'all_properties',
    });
    try {
      const response = await axios.get(
        'https://admin.100bricks.ng/api/vst/property/all'
      );
      const { data } = response.data[0].properties;
      setProperties(data);
      setFiltered(data);
     
      // eslint-disable-next-line no-console
      console.log(data.map((property) => property.property_type)); // gives you an array of property types
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getProperties();
  }, []);

  return (
    <div className="AllProperties">
      <Navbar />
      {loading ? (
        <Loader color="primary" className="p-4" />
      ) : (
        <div className="container">
          <div className="row">
            <Filters
              properties={properties}
              setFiltered={setFiltered}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
            />
            {filtered.map((property) => (
              <motion.div layout className="col-md-4">
                <SalesCard
                  key={property?.propertyID}
                  propertyID={property?.propertyID}
                  name={property?.name}
                  price={property?.price}
                  primary_image_link={property?.primary_image_link}
                  status={property?.status}
                  property_type={property?.property_type}
                  address={property?.address}
                  description={property?.description}
                  date_diff={property?.date_diff}
                  view_count={property?.view_count}
                  like_count={property?.like_count}
                  no_bedroom={property?.no_bedroom}
                  no_bathroom={property?.no_bathroom}
                  square_feet={property?.square_feet}
                  availability_status={property?.availability_status}
                  likeProperty={property?.likeProperty}
                />
              </motion.div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProperties;
