import React from 'react';
import { NavLink, Link } from 'react-router-dom';

// components
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import useAvertSubscriptionTypes from './hooks';

// style
import '../SubscriptionPlanPage/SubscriptionPlanStyle.css';

const SubscriptionPlanPage2 = () => {
  const { data } = useAvertSubscriptionTypes({ type: 'background_info' });
  return (
    <div className="proformpage">
      <Navbar />
      <div className="container proformpage-card">
        <div className="breadcrumb-wrapper">
          <NavLink to="/advert">Property Background Document</NavLink>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/">Subscription Plan</Link>
        </div>
        <h1 className="submitlistingpage-header">Select Subscription Type</h1>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            {/* <p className="proformpage-subheader">
              Our home affordability payments calculator can help you estimate what
              you can afford to spend on your new home.
            </p> */}
          </div>
        </div>
        <div className="row">
          {data?.subscription_types?.map(
            ({ description, duration, amount, subscriptionID }) => (
              <div className="col-md-3" key={subscriptionID}>
                <div className="advertplanscard mt-5">
                  <div className="advertplanscard-content">
                    <span>{description}</span>
                    <div className="justify-center">
                      <div className="subscriptionncard-price">
                        <span>₦</span>
                        <span className="amount">{amount}</span>
                      </div>
                    </div>
                    <p className="advertplanscard-center">{duration} views</p>
                    <div className="subscription-btn">
                      <Link
                        to={{
                          pathname: '/join_as_pro/subscription/payment',
                          state: {
                            paymentData: {
                              subscription_id: subscriptionID,
                              amount,
                              paymentPath: 'payments/subscriptionPayment',
                            },
                          },
                        }}
                        className="btn btn-danger"
                        type="button"
                      >
                        SUBSCRIBE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SubscriptionPlanPage2;
