import React from 'react';

const MobileManifacturedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.61399 9.78862L4.00006 9.48834V15C4.00006 16.6569 5.34321 18 7.00006 18H17.0001C18.6569 18 20.0001 16.6569 20.0001 15V9.48837L20.3861 9.78862C20.8221 10.1277 21.4503 10.0492 21.7894 9.61321C22.1285 9.17726 22.0499 8.54899 21.614 8.20992L14.9163 3.00058C13.201 1.66648 10.7991 1.66647 9.08383 3.00058L2.38611 8.20992C1.95016 8.54899 1.87162 9.17726 2.21069 9.61321C2.54976 10.0492 3.17804 10.1277 3.61399 9.78862ZM13.6884 4.57928C12.6953 3.80691 11.3048 3.80691 10.3117 4.57928L5.99795 7.93443C5.99935 7.95611 6.00006 7.97797 6.00006 8V15C6.00006 15.5523 6.44778 16 7.00006 16H17.0001C17.5523 16 18.0001 15.5523 18.0001 15V8C18.0001 7.97798 18.0008 7.95612 18.0022 7.93445L13.6884 4.57928Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M5.00006 20C4.44778 20 4.00006 20.4477 4.00006 21C4.00006 21.5523 4.44778 22 5.00006 22H19.0001C19.5523 22 20.0001 21.5523 20.0001 21C20.0001 20.4477 19.5523 20 19.0001 20H5.00006Z"
      fill="#2B2B2B"
    ></path>
  </svg>
);

export default MobileManifacturedIcon;
