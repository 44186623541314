import { FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import React from 'react';

export default function Form({ onSubmit, methods, children, isDisabled, ...rest }) {
  return (
    <FormProvider {...methods} isDisabled={isDisabled}>
      <form {...rest} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  methods: PropTypes.shape({
    handleSubmit: PropTypes.func,
  }).isRequired,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
};

Form.defaultProps = {
  isDisabled: true,
};
