import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import useSearchProperties from 'hooks/useSearchProperties';
import gtag from 'ga-gtag';

// components
import Navbar from 'components/Navbar';
import SecondaryBanner from 'components/SecondaryBanner';
// import PropertyCard from 'components/PropertyCard';
import CategoryFilter from 'components/CategoryFilter';
import PriceFilter from 'components/PriceFilter';
import SalesCard from 'components/SalesCard';
import Loader from 'components/Loader';
import Footer from 'components/Footer';

// style
import './PropertiesPage.css';
import Pagination from 'components/Pagination';
import usePageBanner from 'hooks/usePageBanner';
import FilterProperties from 'pages/Home/FilterProperties';
import getPaginationStats from 'utils/getPaginationStats';
import StateFilter from '../../components/StateFilter/index';
import FeaturedPropertyCard from 'components/FeaturedPropertyCard';
import TipsAndGuides from 'components/TipsAndGuides';
import usePropertyCategoryCount from 'hooks/useAllProperties';

const tabPanelIds = ['most_recent', 'highest_price', 'lowest_price'];

const PropertiesPage = () => {
  const [page, setPage] = React.useState(1);
  const { state } = useLocation();
  // eslint-disable-next-line camelcase
  const current_page = useRouteMatch('/properties/buy')
    ? 'getBuyPageBanner'
    : 'getRentPageBanner';
  const { data: pageData } = usePageBanner(current_page);

  const [searchParams, setParams] = React.useState({
    property_type_id: undefined,
    property_category_id: undefined,
    state_id: undefined,
    min_price: 1000,
    max_price: 1000000000,
    no_bedroom: 1,
    no_bathroom: 1,
    no_toilet: 1,
    no_car_parking: 1,
    page_type: 'most_recent',
    ...state?.search,
  });

  const { data, isLoading } = useSearchProperties({
    page,
    ...searchParams,
    transaction_type: 'BUY',
    property_type_id: searchParams.property_type_id,
  });

  const { to, from, total } = getPaginationStats(data?.properties?.meta);

  const trackBuyPage = () => {
    gtag('event', 'buy_page', {
      method: 'buy_page',
    });
  };

  React.useEffect(() => {
    trackBuyPage();
  }, []);

  console.log({ data });

  return (
    <>
      <Navbar />
      <section className="properties-page">
        <main className="mx-auto">
          <div className="filter-section">
            <FilterProperties
              onSearch={(filterValues) =>
                setParams({ ...searchParams, ...filterValues })
              }
            />
          </div>
        </main>
        <section className="affordability-banner col-md-11  mx-auto">
          <h2 className="title">Check for properties you can afford</h2>
          <p className="description">
            Use our Affordability Calculator to learn about houses you can afford
          </p>
        </section>

        <div className="container p-0 mx-auto">
          <div className="row p-0">
            <div className="col-lg-8 col-md-8 p-0 ">
              <div className="row p-0">
                <div className="col-md-6 p-0">
                  <div className="properties-page-header mb-4" />
                </div>
              </div>
              <TabProvider
                defaultTab="most_recent"
                onChange={(pageType) =>
                  setParams({ ...searchParams, page_type: pageType })
                }
              >
                <div className="my-tabs">
                  <div className="container">
                    <div className="newlistings-flex">
                      <span className="newlistings-subheading">
                        {isLoading ? (
                          'Loading...'
                        ) : (
                          <span>
                            {total ? (
                              <>
                                Displaying {from} - {to} results of {total}
                              </>
                            ) : null}
                          </span>
                        )}
                      </span>
                      <div className="newlistings-tabs">
                        <TabList className="tablist">
                          <Tab tabFor="most_recent" className="tab-btn">
                            Most Recent
                          </Tab>
                          <Tab tabFor="highest_price" className="tab-btn">
                            Highest Price
                          </Tab>
                          <Tab tabFor="lowest_price" className="tab-btn">
                            Lowest Price
                          </Tab>
                        </TabList>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    {tabPanelIds.map((tabId) => (
                      <TabPanel tabId={tabId} key={tabId}>
                        {isLoading ? (
                          <Loader color="primary" />
                        ) : (
                          <div className="container">
                            {data?.properties?.data?.length > 0 ? (
                              <div className="property-grid">
                                {data?.properties?.data?.map((property) => (
                                  <FeaturedPropertyCard
                                    key={property?.propertyID}
                                    property={{
                                      ...property,
                                      image: property?.primary_image_link,
                                      id: property?.propertyID,
                                    }}
                                  />
                                ))}
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center">
                                No Properties available
                              </div>
                            )}
                          </div>
                        )}
                      </TabPanel>
                    ))}
                  </div>
                </div>
              </TabProvider>
              <div className="centralize-div">
                <Pagination
                  {...{ ...data?.properties?.meta }}
                  handlePageChange={() => setPage((prev) => prev + 1)}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 relative  p-0">
              <section className="map-section mt-5">
                <img src="/assets/map.jpg" alt="map" />
              </section>
              <div className="filter-container">
                <div className="col p-0">
                  <div className="mb-2">
                    <div className="col p-0 w-100">
                      <CategoryFilter
                        setPropertyType={(propertyCategory) =>
                          setParams({
                            ...searchParams,
                            property_category_id: propertyCategory,
                          })
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <StateFilter
                        setStateType={(stateList) =>
                          setParams({ ...searchParams, state_id: stateList })
                        }
                      />
                    </div>
                  </div>
                  <PriceFilter
                    setPriceParams={setParams}
                    searchParams={searchParams}
                  />
                </div>
              </div>
            </div>
          </div>

          <TipsAndGuides />
        </div>
        <Footer />
      </section>
    </>
  );
};

export default PropertiesPage;
