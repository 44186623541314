import React from 'react';
import AsoLogo from '../../../static/images/ASO Logo.jpeg';
import BricksLogo from '../../../static/images/100Bricks Logo New.jpg';
import './FooterStyle.css';

const Footer = () => {
  return (
    <section className="approval-footer">
      <div className="logo-container d-flex flex-column justify-content-center">
        <p className="text-center">Powered by:</p>
        <div className="d-flex gap-5 justify-content-center align-items-center">
          <img src={AsoLogo} className="logo" alt="aso logo" />
          <img src={BricksLogo} className="logo" alt="100bricks" />
        </div>
      </div>
    </section>
  );
};

export default Footer;
