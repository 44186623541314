import React from 'react';
import { Link } from 'react-router-dom';

// style
import BricksLogo from '../../static/images/logo.jpg';
import Aso from '../../static/images/aso_white_logo.svg';
import './FooterStyle.css';

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-col-divider" />
            <div className="footer-col">
              <h1 className="footer-col-header">OUR COMPANY</h1>
              <div className="footer-links">
                {/* <Link to="/">ABOUT US</Link> */}
                <Link target="_blank" to="/advert">
                  ADVERTISE WITH US
                </Link>
                <Link target="_blank" to="/sell">
                  SELL YOUR PROPERTY
                </Link>
                <Link target="_blank" to="/properties/buy">
                  BUY A PROPERTY
                </Link>
                <Link target="_blank" to="/register">
                  REGISTER
                </Link>
                <Link target="_blank" to="/login">
                  ACCESS YOUR ACCOUNT
                </Link>
                {/* <Link target="_blank" to="/finance">
                  WANT TO BUY OR RENT?
  </Link> */}
                {/* <Link to="/">CONTACT US</Link> */}
                {/* <Link to="/">FAQs</Link> */}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-col-divider" />
            <div className="footer-col">
              <h1 className="footer-col-header">POLICIES</h1>
              <div className="footer-links">
                <Link target="_blank" to="/terms">
                  TERMS & CONDITIONS
                </Link>
                <Link target="_blank" to="/privacy">
                  PRIVACY POLICIES
                </Link>
                {/* <Link to="/">CANCELLATION POLICIES</Link> */}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-col-divider" />
            <div className="footer-col">
              <h1 className="footer-col-header">CONNECT WITH US</h1>
              {/* <span>Plot 266 FMBN building, Central Business District, Abuja</span> 
              <a href="mailto:customerservice@asoplc.com" className="email">
                <span>customerservice@asoplc.com</span>
              </a>

              <span>
                <a href="tel:+07003300000" className="email">
                  +234-070-0330-0000
                </a>
              </span> */}
              <a href="mailto:customerservice@asoplc.com" className="email">
                <span>customerservice@100bricks.ng</span>
              </a>
              <span>
                <a href="tel:+234096233232" className="email">
                  +234-096-233-232
                </a>
              </span>
              <span>
                <a href="tel:+234096233231" className="email">
                  +234-096-233-231
                </a>
              </span>

              <div className="footer-socials">
                <a href="https://www.facebook.com/100bricksng">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5744 0H2.42555C1.08598 0 0 1.08594 0 2.42555V15.5745C0 16.9141 1.08594 18 2.42555 18H8.9105L8.92156 11.5678H7.25046C7.03329 11.5678 6.85703 11.3922 6.85619 11.175L6.84818 9.10165C6.84734 8.88329 7.02413 8.70584 7.24249 8.70584H8.91055V6.70243C8.91055 4.37749 10.3305 3.11154 12.4045 3.11154H14.1063C14.3241 3.11154 14.5007 3.28807 14.5007 3.50586V5.25414C14.5007 5.47184 14.3242 5.64832 14.1066 5.64846L13.0621 5.64894C11.9342 5.64894 11.7158 6.18491 11.7158 6.97147V8.70588H14.1942C14.4304 8.70588 14.6136 8.91209 14.5858 9.14661L14.34 11.22C14.3165 11.4184 14.1483 11.5679 13.9485 11.5679H11.7269L11.7158 18.0001H15.5745C16.9141 18.0001 18 16.9142 18 15.5746V2.42555C18 1.08594 16.914 0 15.5744 0Z"
                      fill="white"
                      fillOpacity="0.6"
                    />
                  </svg>
                </a>
                <a href="a">
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.6819 1.93532C19.2375 2.13236 18.7763 2.28782 18.3029 2.40036C18.8245 1.90474 19.2166 1.28483 19.4379 0.592898C19.501 0.395363 19.2865 0.224549 19.1081 0.330314C18.4496 0.720898 17.7403 1.01391 16.9999 1.2013C16.9569 1.21218 16.9126 1.21769 16.8681 1.21769C16.7332 1.21769 16.6021 1.16761 16.499 1.07672C15.7112 0.382392 14.6979 0 13.646 0C13.1908 0 12.7312 0.0709488 12.28 0.210896C10.8819 0.644537 9.80311 1.79781 9.46465 3.22069C9.33768 3.75439 9.3033 4.28858 9.3624 4.80838C9.36918 4.86811 9.34094 4.9098 9.32353 4.92936C9.29296 4.96364 9.24961 4.98329 9.2046 4.98329C9.19958 4.98329 9.19441 4.98304 9.18929 4.98256C6.12889 4.69823 3.36915 3.22381 1.41842 0.830954C1.31895 0.708903 1.12819 0.723873 1.0489 0.85987C0.666902 1.51523 0.465027 2.2648 0.465027 3.02754C0.465027 4.19646 0.934654 5.29805 1.75039 6.10111C1.40735 6.01977 1.07533 5.89168 0.766913 5.72038C0.617701 5.63748 0.434112 5.74403 0.432015 5.91469C0.410657 7.65121 1.42735 9.19638 2.95038 9.89217C2.91971 9.8929 2.88904 9.89324 2.85832 9.89324C2.6169 9.89324 2.37245 9.86993 2.13186 9.82395C1.96383 9.79186 1.82266 9.95127 1.87484 10.1142C2.36919 11.6575 3.69805 12.7952 5.28828 13.0535C3.96848 13.9394 2.43155 14.4068 0.831913 14.4068L0.333223 14.4065C0.179574 14.4065 0.0496227 14.5066 0.0104668 14.6554C-0.028104 14.802 0.0429423 14.9566 0.173966 15.0329C1.9765 16.0819 4.03256 16.6363 6.12074 16.6363C7.94859 16.6363 9.65838 16.2736 11.2027 15.5582C12.6184 14.9024 13.8694 13.965 14.921 12.7719C15.9007 11.6605 16.6667 10.3775 17.1978 8.95866C17.7041 7.60615 17.9717 6.16299 17.9717 4.78517V4.71953C17.9717 4.4983 18.0717 4.29014 18.2463 4.14843C18.9087 3.61059 19.4856 2.97746 19.9608 2.26666C20.0863 2.07897 19.8884 1.84374 19.6819 1.93532Z"
                      fill="white"
                      fillOpacity="0.6"
                    />
                  </svg>
                </a>
                <a href="https://instagram.com/asosavings?igshid=YmMyMTA2M2Y=">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM9.03516 13.7109C6.41808 13.7109 4.28906 11.5819 4.28906 8.96484C4.28906 6.34776 6.41808 4.21875 9.03516 4.21875C11.6522 4.21875 13.7812 6.34776 13.7812 8.96484C13.7812 11.5819 11.6522 13.7109 9.03516 13.7109ZM14.3086 5.27344C13.4363 5.27344 12.7266 4.56372 12.7266 3.69141C12.7266 2.81909 13.4363 2.10938 14.3086 2.10938C15.1809 2.10938 15.8906 2.81909 15.8906 3.69141C15.8906 4.56372 15.1809 5.27344 14.3086 5.27344Z"
                      fill="white"
                      fillOpacity="0.6"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-line">
          <div className="footer-bottom-logo">
            <center>
              <span className="powered text-secondary">Powered by </span> <br />
              <span>
                <img
                  src={Aso}
                  alt="Aso Savings Logo"
                  style={{ height: '174px', width: '175.09px' }}
                />
              </span>
            </center>
            <span className="all-rights-reserved">
              <span>&copy;</span>2021 all rights reserved
            </span>
          </div>
        </div>
       
      </div>
    </section>
  );
};

export default Footer;
