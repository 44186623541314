import React from 'react';
import { NavLink, Link } from 'react-router-dom';

// components
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

// style
import './SubscriptionPlanStyle.css';

const ProFormPage = () => {
  return (
    <div className="proformpage">
      <Navbar />
      <div className="container proformpage-card">
        <div className="breadcrumb-wrapper">
          <NavLink to="/services">Home service</NavLink>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/join_as_pro">Join as a professional</Link>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/">Subscription Plan</Link>
        </div>
        <h1 className="submitlistingpage-header">Choose Subscription Plan</h1>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <p className="proformpage-subheader">
              Our home affordability payments calculator can help you estimate what
              you can afford to spend on your new home.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6">
            <div className="subscriptioncard">
              <div className="subscriptioncard-content">
                <span>
                  ASO property plan to join the home service as a professional
                </span>
                <div className="subscriptionncard-price">
                  <span>₦</span>
                  <span>8,000</span>
                  <span>/year.</span>
                </div>
                <div className="subscriptioncard-ul">
                  <div className="subscriptioncard-merit">
                    <span>
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.6654 1L5.4987 10.1667L1.33203 6"
                          stroke="#131516"
                          strokeOpacity="0.8"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span>Unlimited access to premium clients</span>
                  </div>
                  <div className="subscriptioncard-merit">
                    <span>
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.6654 1L5.4987 10.1667L1.33203 6"
                          stroke="#131516"
                          strokeOpacity="0.8"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span>Unlimited access to premium clients</span>
                  </div>
                  <div className="subscriptioncard-merit">
                    <span>
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.6654 1L5.4987 10.1667L1.33203 6"
                          stroke="#131516"
                          strokeOpacity="0.8"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span>Unlimited access to premium clients</span>
                  </div>
                  <div className="subscriptioncard-merit">
                    <span>
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.6654 1L5.4987 10.1667L1.33203 6"
                          stroke="#131516"
                          strokeOpacity="0.8"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span>Unlimited access to premium clients</span>
                  </div>
                </div>
                <div className="subscription-btn">
                  <Link
                    to={{
                      pathname: '/join_as_pro/subscription/payment',
                      state: {
                        paymentData: {
                          subscription_id: 2,
                          amount: 8000,
                        },
                      },
                    }}
                    className="btn btn-danger"
                    type="button"
                  >
                    SUBSCRIBE
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProFormPage;
