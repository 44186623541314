/* eslint-disable camelcase */
export const transformData = (values) => {
  const { image_link, ...rest } = values;
  const formData = new FormData();
  Object.keys(rest).forEach((key) => {
    if (rest[key]) formData.append(key, rest[key]);
  });
  Array.from(image_link).forEach((file) => {
    formData.append('image_link', file, file.name);
  });
  return formData;
};
