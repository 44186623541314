import React from 'react';
import { useForm } from 'react-hook-form';
import useStateList from '../../hooks/useStateList';
// import { Select } from 'components/common/Select';

import '../CategoryFilter/CategoryFilterStyle.css';
// import Form from 'components/common/Form';

function StateFilter({ setStateType }) {
  const countryIdForNigeria = 161;

  const { data: stateData } = useStateList({ countryID: countryIdForNigeria });
  const methods = useForm();

  return (
    <div className="categoryfilter">
      <h1>State</h1>
      <div>
        <select
          className="form-control form-control br-0"
          className="form-select form-select-lg br-0 searchformfields"
          aria-label=".form-select-lg example"
          name="state_id"
          ref={methods.register}
          onChange={(e) => setStateType(e.target.value)}
        >
          <option value="">SELECT</option>
          {stateData?.states?.map(({ id, name }) => (
            <option value={id} key={id}>
              {name?.toUpperCase()}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default StateFilter;
