import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import Carousel from 'react-multi-carousel';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import ProfessionalBanner from 'components/ProfessionalBanner';
import Email from '../../static/images/email.svg';
import Phone from '../../static/images/phone.svg';
import Pin from '../../static/images/pin.svg';
import Facebook from '../../static/images/facebook.svg';
import Twitter from '../../static/images/twitter.svg';
import Instagram from '../../static/images/instagram.svg';
import DoubleQuote from '../../static/images/doublequote.svg';
import RateProfessionalForm from './RateProfessionalForm';
import SendMessageForm from './SendMessageForm';
import { useFetchReviews } from './hooks/useFetchReviews';
// style
import './SingleProfessionalPageStyle.css';

const ProfessionalDetailsPage = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const { state } = useLocation();
  const { data, isFetchingMore, canFetchMore, fetchMore } = useFetchReviews({
    home_service_id: state?.service?.homeServiceID,
    per_page: 3,
  });

  return (
    <div className="SingleProfessionalPage">
      <Navbar />
      <ProfessionalBanner service={state?.service} />
      <TabProvider defaultTab="aboutus">
        <div className="my-tabs">
          <div className="singlepropage-tab">
            <div className="container">
              <TabList className="tab-list">
                <Tab tabFor="aboutus" className="tab-btn">
                  ABOUT US
                </Tab>
                <Tab tabFor="projects" className="tab-btn">
                  PROJECTS
                </Tab>
                <Tab tabFor="reviews" className="tab-btn">
                  REVIEWS
                </Tab>
              </TabList>
            </div>
          </div>
          <div className="wrapper">
            <TabPanel tabId="aboutus">
              <div className="container mb-100 mt-80">
                <div className="row">
                  <div className="col-md-4">
                    <div className="singlepropage-user">
                      <img
                        src="https://images.unsplash.com/photo-1478039543847-9cf7608e8f6e?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDF8fHByb2Zlc3Npb25hbHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                        alt="user"
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="singlepropage-user">
                      <h1>{state?.service?.owner?.name}</h1>
                      <div className="flex mt-5">
                        <div className="flex">
                          <img src={Email} alt="email icon" />
                          <div className="user-contact-details">
                            <span>EMAIL:</span>
                            <span>{state?.service?.business_email}</span>
                          </div>
                        </div>
                        <div className="flex ml-5">
                          <img src={Phone} alt="email icon" />
                          <div className="user-contact-details">
                            <span>PHONE:</span>
                            <span>{state?.service?.business_phone}</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-4 mb-3">
                        <div className="flex">
                          <img src={Pin} alt="email icon" />
                          <div className="user-contact-details">
                            <span>ADDRESS:</span>
                            <span>{state?.service?.business_address}</span>
                          </div>
                        </div>
                      </div>
                      <p className="pt-2">{state?.service?.business_description}</p>
                      <div className="flex">
                        <a
                          href={`${state?.service?.facebook_link}`}
                          target="_blank"
                          rel="noreferrer"
                          className="undeline-none"
                        >
                          <img src={Facebook} alt="Facebook Icon" />
                        </a>
                        <a
                          href={`${state?.service?.twitter_link}`}
                          target="_blank"
                          rel="noreferrer"
                          className="undeline-none ml-4"
                        >
                          <img src={Twitter} alt="Twitter Icon" />
                        </a>
                        <a
                          href={`${state?.service?.instagram_link}`}
                          target="_blank"
                          rel="noreferrer"
                          className="undeline-none ml-4"
                        >
                          <img src={Instagram} alt="Instagram Icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="singlepropage-slider">
                <div className="container">
                  <h1>50 Best Projects By Daniel Williams</h1>
                  <div className="singlepropage-carousel">
                    <Carousel
                      swipeable
                      draggable
                      partialVisible
                      showDots={false}
                      responsive={responsive}
                      keyBoardControl
                      customTransition="all .5s linear"
                      transitionDuration={500}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={['tablet', 'mobile']}
                      itemClass="singlepropage-item"
                    >
                      <div className="singlepropage-card">
                        <img
                          src="https://images.unsplash.com/photo-1584738766473-61c083514bf4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                          alt="card"
                        />
                        <div className="singlepropage-overlay">
                          <h1>Honorable Babatunde’s Residence, Lekki</h1>
                          <Link to="/">15 photos</Link>
                        </div>
                      </div>
                      <div className="singlepropage-card">
                        <img
                          src="https://images.unsplash.com/photo-1567493756992-e2b6227cddc0?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDJ8fGhvdXNlfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                          alt="card"
                        />
                        <div className="singlepropage-overlay">
                          <h1>Honorable Babatunde’s Residence, Lekki</h1>
                          <Link to="/">15 photos</Link>
                        </div>
                      </div>
                      <div className="singlepropage-card">
                        <img
                          src="https://images.unsplash.com/photo-1573652636601-d6fdcfc59640?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NTh8fGhvdXNlfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                          alt="card"
                        />
                        <div className="singlepropage-overlay">
                          <h1>Honorable Babatunde’s Residence, Lekki</h1>
                          <Link to="/">15 photos</Link>
                        </div>
                      </div>
                      <div className="singlepropage-card">
                        <img
                          src="https://images.unsplash.com/photo-1574620845949-61a08a005988?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzB8fGhvdXNlfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                          alt="card"
                        />
                        <div className="singlepropage-overlay">
                          <h1>Honorable Babatunde’s Residence, Lekki</h1>
                          <Link to="/">15 photos</Link>
                        </div>
                      </div>
                    </Carousel>
                  </div>
                </div>
              </div>
              <div className="singlepropage-inquiry">
                <SendMessageForm homeServiceId={state?.service.homeServiceID} />
              </div>
              <div className="singlepropage-quotes">
                <h1>Reviews From Our Customers</h1>
                <div className="container">
                  <div className="row">
                    {data
                      ?.reduce((curr, next) => [...curr, ...next.data], [])
                      ?.map((review) => (
                        <div className="col-md-4" key={review.reviewID}>
                          <div className="quotes-box">
                            <div className="justify-center">
                              <img src={DoubleQuote} alt="Double quotes" />
                            </div>
                            <p>{review.message}</p>
                            <div className="justify-center">
                              <img
                                className="customer-quote_image"
                                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                                alt="customer"
                              />
                            </div>
                            <h1 className="text-center fs-14">
                              {review.reviewer_name}
                            </h1>
                            <span>Client</span>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="justify-center loadmore-btn">
                    <button
                      className="btn br-0"
                      type="button"
                      onClick={() => fetchMore()}
                      disabled={isFetchingMore || !canFetchMore}
                    >
                      {isFetchingMore ? 'Loading More...' : 'LOAD MORE REVIEWS'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="singlepropage-ratings">
                <h1 className="text-center">Rate & review Daniel Williams</h1>
                <RateProfessionalForm homeServiceId={state?.service.homeServiceID} />
              </div>
            </TabPanel>
          </div>
        </div>
      </TabProvider>

      <Footer />
    </div>
  );
};

export default ProfessionalDetailsPage;
