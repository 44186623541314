import React from 'react';
import { Link } from 'react-router-dom';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { useAuth } from 'context/auth-context';

// Style
import './DashboardBannerStyle.css';

import BannerImage from '../../static/images/banner2.jpg';

const DashboardBanner = () => {
  const { user } = useAuth();

  const renderTooltip = (props) => (
    <Tooltip {...props}>Click to submit property</Tooltip>
  );

  return (
    <div className="dashboardbanner">
      <img src={BannerImage} alt="banner" />
      <div className="secondarybanner-overlay">
        <div className="container">
          <h1 className="dashboardbanner-header">MY HOME VIEW</h1>
          <div className="dashboardbanner-flex">
            <div className="dashboardbanner-userintro">
              <span>
                Welcome Back <span className="username">{user.name}</span>
              </span>
              {/* <span>
                Does it have pet-friendly rentals? What are the crime rates? How are
                the schools
              </span> */}
            </div>
            <div className="dashboardbanner-btn">
              <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                <Link to="/dashboard/listing" className="btn btn-danger br-0">
                  + SUBMIT LISTING
                </Link>
              </OverlayTrigger>
              {/* <Link
                to="/dashboard/myhomeservices"
                className="btn btn-danger br-0 ml-2"
              >
                MY HOME SERVICES
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBanner;
