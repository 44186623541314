import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import { moneyFormat } from 'utils/moneyFormat';
import './InputStyle.css';

export const Input = ({
  id,
  label,
  name,
  value,
  placeholder,
  containerClassName,
  validation,
  labelClassName,
  moneyFormat: formatWithMoneyStyle,
  ...rest
}) => {
  const { register, errors, watch } = useFormContext() || {};
  const isError = errors?.[name];
  const classes = `${rest.className} ${
    rest.moneyFormat ? 'moneyFormat-input' : ''
  } ${isError ? 'is-invalid' : ''}`;

  const getMoneyFormat = () => {
    return watch(name);
  };

  return (
    <div className={`${containerClassName} input-wrapper`}>
      {label ? (
        <label htmlFor={id} className={`form-label ${labelClassName}`}>
          {label}
        </label>
      ) : null}
      <input
        ref={register ? register({ ...validation }) : null}
        id={id}
        placeholder={placeholder}
        name={name}
        {...rest}
        type={rest.type ? rest.type : 'text'}
        className={classes}
      />
      {formatWithMoneyStyle ? (
        <span className="moneyFormat-text">
          {moneyFormat(Number(getMoneyFormat()).toFixed(2))}
        </span>
      ) : null}

      <span style={{ color: '#dc3545' }}>
        {errors && <ErrorMessage name={name} errors={errors} />}
      </span>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  validation: PropTypes.shape({}),
  moneyFormat: PropTypes.bool,
  labelClassName: PropTypes.string,
};

Input.defaultProps = {
  id: '',
  label: '',
  value: '',
  placeholder: '',
  containerClassName: '',
  labelClassName: '',
  validation: {},
  moneyFormat: false,
};
