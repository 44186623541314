import React from 'react';

const CondominiumIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 11.5C16 11.2239 16.2239 11 16.5 11H17.5C17.7761 11 18 11.2239 18 11.5V12.5C18 12.7761 17.7761 13 17.5 13H16.5C16.2239 13 16 12.7761 16 12.5V11.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M16.5 15C16.2239 15 16 15.2239 16 15.5V16.5C16 16.7761 16.2239 17 16.5 17H17.5C17.7761 17 18 16.7761 18 16.5V15.5C18 15.2239 17.7761 15 17.5 15H16.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M6 7.5C6 7.22386 6.22386 7 6.5 7H7.5C7.77614 7 8 7.22386 8 7.5V8.5C8 8.77614 7.77614 9 7.5 9H6.5C6.22386 9 6 8.77614 6 8.5V7.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M6.5 11C6.22386 11 6 11.2239 6 11.5V12.5C6 12.7761 6.22386 13 6.5 13H7.5C7.77614 13 8 12.7761 8 12.5V11.5C8 11.2239 7.77614 11 7.5 11H6.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M6 15.5C6 15.2239 6.22386 15 6.5 15H7.5C7.77614 15 8 15.2239 8 15.5V16.5C8 16.7761 7.77614 17 7.5 17H6.5C6.22386 17 6 16.7761 6 16.5V15.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M10.5 15C10.2239 15 10 15.2239 10 15.5V16.5C10 16.7761 10.2239 17 10.5 17H11.5C11.7761 17 12 16.7761 12 16.5V15.5C12 15.2239 11.7761 15 11.5 15H10.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M10 7.5C10 7.22386 10.2239 7 10.5 7H11.5C11.7761 7 12 7.22386 12 7.5V8.5C12 8.77614 11.7761 9 11.5 9H10.5C10.2239 9 10 8.77614 10 8.5V7.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M10.5 11C10.2239 11 10 11.2239 10 11.5V12.5C10 12.7761 10.2239 13 10.5 13H11.5C11.7761 13 12 12.7761 12 12.5V11.5C12 11.2239 11.7761 11 11.5 11H10.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V10C21 8.34315 19.6569 7 18 7H15V6C15 4.34315 13.6569 3 12 3H6ZM15 9V19H18C18.5523 19 19 18.5523 19 18V10C19 9.44772 18.5523 9 18 9H15ZM13 6C13 5.44772 12.5523 5 12 5H6C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H13V6Z"
      fill="#2B2B2B"
    ></path>
  </svg>
);

export default CondominiumIcon;
