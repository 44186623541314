import React from 'react';

// components
import Navbar from 'components/Navbar';
import DashboardBanner from 'components/DashboardBanner';
import Footer from 'components/Footer';
import SubmitListingForm from './SubmitListingForm';

// style
import './SubmitListingStyle.css';

const SubmitListingPage = () => {
  return (
    <div className="submitlistingpage">
      <Navbar />
      <DashboardBanner />
      <div className="container submitlistingpage-card">
        <h1 className="submitlistingpage-header">SUBMIT LISTING</h1>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <p className="submitlistingpage-subheader">
              Our home affordability payments calculator can help you estimate what
              you can afford to spend on your new home.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            <div className="submitlistingpage-form">
              <SubmitListingForm />
            </div>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SubmitListingPage;
