import React from 'react';
import ReactDatePicker from 'react-datepicker';

import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, Controller } from 'react-hook-form';
import './InputStyle.css';

export const DatePicker = ({
  id,
  label,
  name,
  value,
  placeholder,
  containerClassName,
  validation,
  labelClassName,
  ...rest
}) => {
  const { errors, control } = useFormContext() || {};
  const isError = errors?.[name];
  const classes = `${rest.className} ${
    rest.moneyFormat ? 'moneyFormat-input' : ''
  } ${isError ? 'is-invalid' : ''}`;

  return (
    <div className={`${containerClassName} input-wrapper`}>
      {label ? (
        <label htmlFor={id} className={`form-label ${labelClassName}`}>
          {label}
        </label>
      ) : null}

      <Controller
        name={name}
        control={control}
        render={({ onChange, ...restProps }) => (
          <ReactDatePicker
            selected={restProps.value}
            onChange={(date) => {
              onChange(date);
            }}
            className={classes}
            wrapperClassName={classes}
          />
        )}
      />
      <span style={{ color: '#dc3545' }}>
        {errors && <ErrorMessage name={name} errors={errors} />}
      </span>
    </div>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  validation: PropTypes.shape({}),
  labelClassName: PropTypes.string,
};

DatePicker.defaultProps = {
  id: '',
  label: '',
  value: '',
  placeholder: '',
  containerClassName: '',
  labelClassName: '',
  validation: {},
};
