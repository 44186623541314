import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'components/common/Radio';
import useNotificationSettings from 'hooks/useNotificationSettings';

const defaultSettings = {
  in_real_time: 0,
  once_a_day: 0,
  send_promo_info: 0,
  new_post: 0,
  slash_in_price: 0,
  status_change: 0,
  promo: 0,
  discount: 0,
};

export default function NotificationSettings({
  notificationSettings,
  userID,
  updateUserDetails,
}) {
  const [settings, updateSettings] = useState(defaultSettings);
  const [updateNotification, { isLoading }] = useNotificationSettings();

  const handleChange = (values) => {
    console.log({ values });
    const update = { ...settings, ...values };
    updateSettings(update);
    updateNotification(
      {
        ...update,
        userID,
      },
      {
        onSuccess: (data) => {
          updateUserDetails(data.userInfo);
        },
      }
    );
  };

  useEffect(() => {
    updateSettings(notificationSettings);
  }, [notificationSettings]);

  return (
    <>
      <div className="userdetails-settings">
        <h1>Notification Settings</h1>
        { isLoading && <p>updating....</p> }
        <span>Send me interested listings and searches</span>
        <div className="form-check form-check-inline">
          <Radio
            className="form-check-input"
            type="radio"
            name="in_real_time"
            id="inlineRadio1"
            checked={settings?.in_real_time}
            label="In Realtime"
            labelClassName="form-check-label"
            onChange={() => handleChange({ in_real_time: 1, once_a_day: 0 })}
          />
        </div>
        <div className="form-check form-check-inline">
          <Radio
            className="form-check-input"
            type="radio"
            name="in_real_time"
            id="inlineRadio2"
            checked={settings?.once_a_day}
            label="Once a day"
            onChange={() => handleChange({ once_a_day: 1, in_real_time: 0 })}
            labelClassName="form-check-label"
          />
        </div>
        <div className="form-check form-check-inline">
          <Radio
            className="form-check-input"
            type="radio"
            name="in_real_time"
            id="inlineRadio3"
            value="option1"
            checked={!settings?.in_real_time && !settings?.once_a_day}
            label="No, thanks"
            onChange={() => handleChange({ in_real_time: 0, once_a_day: 0 })}
            labelClassName="form-check-label"
          />
        </div>

        <div className="pair">
          <div className="first-pair">
            <span className="send-tipsandguides">News Post</span>
            <br />
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="new_post"
                checked={settings?.new_post}
                label="Yes"
                labelClassName="form-check-label"
                onChange={() => handleChange({ new_post: 1 })}
              />
            </div>
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="new_post"
                checked={!settings?.new_post}
                label="No"
                labelClassName="form-check-label"
                onChange={() => handleChange({ new_post: 0 })}
              />
            </div>
          </div>

          <div className="second-pair">
            <span className="send-tipsandguides">Slash in Price</span>
            <br />
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="slash_in_price"
                checked={settings?.slash_in_price}
                label="Yes"
                labelClassName="form-check-label"
                onChange={() => handleChange({ slash_in_price: 1 })}
              />
            </div>
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="slash_in_price"
                checked={!settings?.slash_in_price}
                label="No"
                labelClassName="form-check-label"
                onChange={() => handleChange({ slash_in_price: 0 })}
              />
            </div>
          </div>
        </div>

        <div className="pair">
          <div className="first-pair">
            <span className="send-tipsandguides">Promo</span>
            <br />
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="send_promo_info"
                checked={settings?.send_promo_info}
                label="Yes"
                labelClassName="form-check-label"
                onChange={() => handleChange({ send_promo_info: 1 })}
              />
            </div>
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="send_promo_info"
                checked={!settings?.send_promo_info}
                label="No"
                labelClassName="form-check-label"
                onChange={() => handleChange({ send_promo_info: 0 })}
              />
            </div>
          </div>
          <div className="second-pair">
            <span className="send-tipsandguides">Discount</span>
            <br />
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="discount"
                checked={settings?.discount}
                label="Yes"
                labelClassName="form-check-label"
                onChange={() => handleChange({ discount: 1 })}
              />
            </div>
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="discount"
                checked={!settings?.discount}
                label="No"
                labelClassName="form-check-label"
                onChange={() => handleChange({ discount: 0 })}
              />
            </div>
          </div>
        </div>

        <div className="pair">
          <div className="second-pair">
            <span className="send-tipsandguides">
              Change of status of favorite properties
            </span>
            <br />
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="fav_prop_status_change"
                checked={settings?.fav_prop_status_change}
                label="Yes"
                labelClassName="form-check-label"
                onChange={() => handleChange({ fav_prop_status_change: 1 })}
              />
            </div>
            <div className="form-check form-check-inline">
              <Radio
                className="form-check-input"
                type="radio"
                name="fav_prop_status_change"
                checked={!settings?.fav_prop_status_change}
                label="No"
                labelClassName="form-check-label"
                onChange={() => handleChange({ fav_prop_status_change: 0 })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

NotificationSettings.propTypes = {
  notificationSettings: PropTypes.shape({
    in_real_time: PropTypes.number,
    once_a_day: PropTypes.number,
    send_promo_info: PropTypes.number,
  }).isRequired,
  userID: PropTypes.number.isRequired,
  updateUserDetails: PropTypes.func.isRequired,
};
