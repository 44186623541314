import { queryCache } from 'react-query';
import { logout } from 'utils/auth';

async function client(endpoint, { data, headers, params, ...customConfig } = {}) {
  const apiURL =
    process.env.REACT_APP_NODE_ENV === 'development'
      ? 'https://admin.100bricks.ng/api/vst'
      : 'https://admin.100bricks.ng/api/vst';
  const token = customConfig.token || localStorage.getItem('token');

  const isFormData = data instanceof FormData;

  const body = isFormData ? data : JSON.stringify(data);
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? body : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': data ? 'application/json' : undefined,
    },
    ...customConfig,
  };

  if (isFormData) {
    delete config.headers['Content-Type'];
  }

  const url = new URL(`${apiURL}/${endpoint}`);
  url.search = new URLSearchParams(params).toString();

  return window.fetch(url, config).then(async (response) => {
    if (response.status === 401) {
      queryCache.clear();
      logout();
    }
    if (response.status === 500) {
      return Promise.reject(new Error('Server Error, please try again'));
    }
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    }
    return Promise.reject(responseData);
  });
}

export { client };
