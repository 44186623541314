const discoverTabs = ['Buying', 'Renting', 'Selling'];
const discoverTabContent = {
  Buying: [
    {
      id: 1,
      title: 'Find out how much you can afford',
      description: `We'll help you estimate your budget range. Save to your buyer profile to help in your search.`,
      link: {
        text: 'Try our affordability calculator',
        href: '/finance/affordability',
      },
    },
    {
      id: 2,
      title: 'Understand your monthly costs',
      description: `Get an in-depth look at what your monthly and closing costs will look like based on your financial situation and goals.`,
      link: {
        text: 'Try our mortgage calculator',
        href: '#',
      },
    },
    {
      id: 3,
      title: 'Get help with your down payment',
      description: `You may be able to buy a home with just 3.5% down. Saving for that can be challenging–down payment assistance programs can help.`,
      link: {
        text: 'Find down payment help',
        href: '#',
      },
    },
  ],
  Renting: [
    {
      id: 11,
      title: 'Rent with the option to buy',
      description: `Home Partners of America will buy a home that you love and lease it to you with the option to buy, rent, or walk away without penalty.`,
      link: {
        text: 'Check program availability',
        href: '#',
      },
    },
    {
      id: 12,
      title: `Find out if it's better to rent or buy`,
      description: `Try our rent or buy calculator to determine if buying or renting a home makes more financial sense for your situation.`,
      link: {
        text: 'Try our rent or buy calculator',
        href: '#',
      },
    },
    {
      id: 13,
      title: 'Save time with a renter profile',
      description: `Simplify the home search process with an Avail renter profile. Create yours for free to share with multiple landlords.`,
      link: {
        text: 'Create a profile',
        href: '#',
      },
    },
  ],
  Selling: [
    {
      id: 111,
      title: 'Buy now, sell later',
      description: `Skip the repairs and showings. Our partners can help you buy your new home first, then sell your old one.`,
      link: {
        text: 'Search options from our partners',
        href: '#',
      },
    },
    {
      id: 112,
      title: `Track your home value`,
      description: `See your RealEstimate℠ valuation information over time compared to homes in your area.`,
      link: {
        text: 'Get your RealEstimate℠',
        href: '#',
      },
    },
    {
      id: 113,
      title: 'Get offers for your home',
      description: `Visit Seller’s Marketplace to find out how you can sell without listing or stay in your home while you finance the purchase of your next one.`,
      link: {
        text: 'Explore my offers',
        href: '#',
      },
    },
  ],
};

const servicesData = [
  {
    title: 'Engineers',
    services: [
      { count: 17, name: 'Civil Engineers' },
      { count: 5, name: 'Mechanical Engineers' },
      { count: 7, name: 'Electrical Engineers' },
    ],
  },
  {
    title: 'Contractors',
    services: [
      { count: 5, name: 'Building Contractors' },
      { count: 11, name: 'Interior Decorators' },
      { count: 3, name: 'Estate Developers' },
    ],
  },
  {
    title: 'Vendors',
    services: [
      { count: 28, name: 'General Construction Vendors' },
      { count: 34, name: 'Building Materials Vendors' },
    ],
  },
  {
    title: 'Property Agents',
    services: [
      { count: 40, name: 'Real Estate Property Agents' },
      { count: 23, name: 'Land Document Agents' },
    ],
  },
  {
    title: 'Legal Practitioners',
    services: [
      { count: 5, name: 'Lawyers' },
      { count: 7, name: 'Clerks' },
      { count: 8, name: 'Legal Aids' },
    ],
  },
];

export { discoverTabs, discoverTabContent, servicesData };
