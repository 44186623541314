/* eslint-disable camelcase */
import React from 'react';

import {
  useForm,
  // Controller
} from 'react-hook-form';

// import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';

import {
  // useHistory,
  useRouteMatch,
  // useLocation,
} from 'react-router-dom';

// import { useAuth } from 'context/auth-context';

import { useCreatePropertySale } from 'hooks/useCreatePropertySale';

// components
import Form from '../../components/common/Form';

import { Input } from '../../components/common/Input';

import { Radio } from '../../components/common/Radio';

import { TextField } from '../../components/common/TextField';

import { FileInput } from '../../components/common/FileInput';

import { Button } from '../../components/common/Button';

// hooks

import {
  usePreloadData,
  // useUpdateListing,
  // useSubmitListing,
} from '../SubmitListing/hooks';
import {
  conversionSale,
  // getFormDefaultValues
} from '../SubmitListing/utils';

const SellForm = () => {
  // const { state } = useLocation();
  const [
    createSellData,
    {
      isLoading,
      // error
    },
  ] = useCreatePropertySale(conversionSale);

  /* const defaultValues = React.useMemo(() => {
    return getFormDefaultValues(state?.property);
  }, [state]); */

  const methods = useForm({
    defaultValues: {
      kitchen: 1,
      bathroom: 1,
      year_built: null,
    },
  });
  // const { setError, reset } = methods;
  // const { user } = useAuth();
  const watchFields = methods.watch([
    'property_type_id',
    'country_id',
    'state_id',
    'city_id',
    'featureIDs',
  ]);

  usePreloadData(watchFields);

  /* React.useEffect(() => {
    if (preloadData.countryList) {
      reset({ ...defaultValues });
    }
  }, [preloadData.countryList, reset, defaultValues]); */
  /* const [submitListing, { error, isLoading }] = useSubmitListing(
    convertFormValues,
    user.userID
  ); */
  // const propertyId = state?.property?.propertyID;
  /* const [
    // updateListing,
    { isLoading: 
     // isUpdating, 
      // error: updateError },
  ] = useUpdateListing(convertFormValues, user.userID, 
    // propertyId
    ); */
  const isEdit = useRouteMatch('/dashboard/listing/:id');
  // const history = useHistory();
  const onSubmit = async (values) => {
    // console.log(values);
    // const fn = createSellData;
    /* const onSuccess = (data) => {
      methods.reset();
      toast.success(
        'Property was uploaded successfully, an admin will contact you.',
        {
          style: {
            background: '#d4edda',
            color: '#155724',
          },
        }
      );
      fn(values, { onSuccess });
    }; */

    createSellData(
      { ...values },
      {
        onSuccess: () => {
          methods.reset();
          toast.success(
            'Property was uploaded successfully, an admin will contact you.',
            {
              style: {
                background: '#d4edda',
                color: '#155724',
              },
            }
          );
        },
      }
    );
  };
  /* React.useEffect(() => {
    const responseError =
   //   error?.ResponseMessage || updateError?.ResponseMessage || {};
   // if (!responseError) return;
    Object.keys(responseError).forEach((key) => {
      if (['latitude', 'longitude'].includes(key)) {
        setError('propertyLocation', {
          type: 'manual',
          message: 'Property location was not provided',
        });
      } else {
        setError('propertyLocation', {
          type: 'manual',
         // message: error.ResponseMessage[key][0],
        });
      }
    });
  }, [error, updateError, setError]); */
  // const onSubmit = () => { };
  return (
    <Form className="row g-3" onSubmit={onSubmit} methods={methods}>
      <div className="col-12 form-block" />
      <p>
        We have the power tools to help sell your home as fast as you bring it. All
        you need to do is fill the form below and leave the rest to us.
      </p>
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="Title"
        placeholder="Mr/Mrs/Chief"
        name="title"
        // validation={{ required: true }}
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="Last Name"
        name="lastName"
        // validation={{ required: true, pattern: /[0-9]/ }}
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="First Name"
        name="firstName"
        // validation={{ required: true }}
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="Middle Name"
        name="middleName"
        // validation={{ required: true }}
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="E-mail"
        placeholder="enter your e-mail"
        name="email"
        validation={{ required: true }}
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="Contact Phone"
        name="phoneNumber"
        validation={{ required: true, pattern: /[0-9]/ }}
      />

      <div className="col-12 form-block" />

      <div className="h5 mb-2 mt-4">Property Details</div>
      <div className="row" />
      <Input
        containerClassName="col-12 mb-3"
        className="form-control br-0"
        label="Property Location"
        id="PROPERTY ADDRESS"
        rows="3"
        name="propertyAddress"
        validation={{ required: true }}
        placeholder="E.g Lagos"
        maxlength="25"
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="House Type"
        name="houseType"
        validation={{ required: true }}
        placeholder="E.g Terrace Bungalow"
      />
      <Input
        containerClassName="col-md-6"
        className="form-control br-0"
        label="Selling Price"
        name="price"
        validation={{ required: true, pattern: /[0-9]/ }}
      />
      <div className="row" />
      <div className="row" />
      <TextField
        containerClassName="col-12 mb-3"
        className="form-control br-0"
        label="Brief Description (low, medium or high density, amenities and proximity to major landmark etc.)"
        id="PROPERTY DESCRIPTION"
        rows="3"
        name="description"
        validation={{ required: true }}
        maxlength="400"
        placeholder="Maximum of 400 characters"
      />
      <div className="h5 mb-2 mt-2">Please tick as appropriate</div>
      <div className="d-flex flex-row">
        <Radio
          containerClassName="form-check form-check-inline"
          type="radio"
          name="ownership"
          id="ownership1"
          label="Owner"
          defaultValue="on"
          validation={{ required: true }}
          labelClassName="form-check-label"
        />
        <Radio
          containerClassName="form-check form-check-inline"
          type="radio"
          name="ownership"
          id="ownership2"
          label="Agent"
          defaultValue="on"
          validation={{ required: true }}
          labelClassName="form-check-label"
        />
        <Radio
          containerClassName="form-check form-check-inline"
          type="radio"
          name="ownership"
          id="ownership3"
          label="Others"
          defaultValue="off"
          labelClassName="form-check-label"
          validation={{ required: true }}
        />
      </div>
      <FileInput
        label="Proof of Ownership"
        id="proof_of_ownership"
        description="Upload proof of ownership/Authority to sell"
        name="proof_of_ownership"
        accept="image/*"
        validation={{
          required: true,
        }}
      />

      <FileInput
        label="Property Image"
        id="primary_image"
        description="Upload property Image"
        name="photo"
        accept="image/*"
        validation={{
          required: true,
        }}
      />

      <div className="col-12">
        <div className="submitlistingpage-btns">
          <div className="flexend-btns mb-3">
            <Button className="btn btn-outline br-0" type="button">
              CANCEL
            </Button>
            <Button
              className="btn btn-danger br-0"
              type="submit"
              isLoading={
                isLoading // || isUpdating
              }
            >
              {isEdit ? 'UPDATE' : 'SUBMIT'}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default SellForm;
