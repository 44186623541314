import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'context/auth-context';
// import usePageBanner from 'hooks/usePageBanner';

// component
// import SearchBar from '../SearchBar';
// style
import './HomeServicesBannerStyle.css';

import HomeServices from '../../static/images/hs.png';

const HomeServicesBanner = ({ bannerHeader }) => {
  // const { data } = usePageBanner('getHomeServiceBanner');

  const { user } = useAuth();
  return (
    <section className="homeservicesbanner">
      {/*data?.banner?.primary_image && (
        <img src={data?.banner?.primary_image} alt="banner" />
      )*/}
      <img src={HomeServices} alt="banner" />
      <div className="secondarybanner-overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <div className="secondarybanner-content pt-2">
                <h1>{bannerHeader}</h1>
                <p>
                We connect you to a list of reliable service providers who make your experience hassle free.
                </p>
                <div className="homeservices-flex">
                  <div className="homeservices-search">
                    {/* <SearchBar /> */}
                  </div>
                  <div className="homeservices-search-btn">
                    {user?.serviceProvider ? (
                      <Link to="/join_as_pro" className="btn btn-danger br-0">
                        Add Service
                      </Link>
                    ) : (
                      <Link to="/join_as_pro" className="btn btn-danger br-0">
                        JOIN AS A PRO
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeServicesBanner;
