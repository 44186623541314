import useFetchPropertyTypes from 'hooks/useFetchPropertyTypes';
import useFetchFeatures from 'hooks/useFetchFeatures';
import useCountryList from 'hooks/useCountryList';
import useStateList from 'hooks/useStateList';
import useCityList from 'hooks/useCityList';
import useTownList from 'hooks/useTownList';
import usePropertyCategories from 'hooks/usePropertyCategories';
import useGetPaymentPlanOptions from 'hooks/useGetPaymentPlanOptions';
import useCurrencyList from 'hooks/useCurrencyList';
import { fieldNumberOptions } from 'mock/fieldOptions';

import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

export const usePreloadData = (watchFields) => {
  const propertyTypes = useFetchPropertyTypes();
  const propertyFeatures = useFetchFeatures();
  const paymentPlanOptions = useGetPaymentPlanOptions();
  const countryList = useCountryList();
  const stateList = useStateList({
    countryID: watchFields.country_id,
  });
  const cityList = useCityList({ stateID: watchFields.state_id });
  const townList = useTownList({ cityID: watchFields.city_id });
  const propertyCategory = usePropertyCategories({
    propertyTypeID: watchFields.property_type_id,
  });
  const currencyList = useCurrencyList();

  return {
    propertyTypes: propertyTypes?.data?.property_types,
    propertyCategory: propertyCategory?.data?.property_categories,
    fieldNumberOptions,
    countryList: countryList?.data?.countries,
    stateList: stateList?.data?.states,
    cityList: cityList?.data?.cities,
    townList: townList?.data?.towns,
    propertyFeatures: propertyFeatures?.data?.property_features,
    currencyList: currencyList?.data?.currencies,
    paymentPlanOptions: paymentPlanOptions?.data?.paymentPlanOptions?.map(
      ({ id, description }) => ({ id, name: description })
    ),
  };
};

export function useSubmitListing(convertFormValues, userID) {
  return useMutation((values) =>
    client('profile/submitListing', {
      data: convertFormValues(values, userID),
      isFormData: true,
    }).then((res) => res)
  );
}

export function useUpdateListing(convertFormValues, userId, propertyId) {
  const cache = useQueryCache();
  return useMutation((values) =>
    client('profile/modifyProperty', {
      data: convertFormValues(values, userId, propertyId),
      isFormData: true,
    }).then((res) => {
      cache.invalidateQueries(['city-list', { userID: userId }]);
      return res;
    })
  );
}
