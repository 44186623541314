import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function usePropertyCategories(params) {
  return useQuery(
    ['property-category-list', params],
    (_, fieldParams) =>
      client('params/getPropertyCategory', { params: fieldParams }).then(
        (res) => res
      ),
    {
      enabled: params.propertyTypeID,
    }
  );
}
