/* eslint-disable camelcase */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab, TabPanel, TabList } from 'react-tabs';
import gtag from 'ga-gtag';
import 'react-tabs/style/react-tabs.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HomeServicesBanner from 'components/HomeServicesBanner';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import './HomeServicesPageStyle.css';
import { useHomeServiceCategories } from 'hooks/useHomeServiceCategories';
import HomeServiceCategory from './HomeServiceCategory';
import ServiceCategoryTypes from './ServiceCategoryTypes';
import DisclaimerNote from './DisclaimerNote';
import HomeServiceHero from './HomeServiceHero';
import HowItWorks from './HowItWorks';
import CustomerFeedback from './CustomerFeedback';
import AllHomeServices from './AllHomeServices';
import HowItWorksItem from './HowItWorksItem';
import HomeServiceCard from './HomeServiceCard';
import { servicesData } from 'data';


const HomeServicesPage = () => {

  

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const {
    data,
    // isLoading
  } = useHomeServiceCategories();

  const trackHomeServicesPage = () => {
    gtag('event', 'homeServices_page', {
      method: 'homeServices_page',
    });
  };

  const tabs = [
    'SHOW ALL',
    'Vendor',
    'Contractors',
    'Legal Practitioners',
    'Property Agent',
    'Engineers',
    'Others',
  ];

  React.useEffect(() => {
    trackHomeServicesPage();
  }, []);

  return (
    <div className="homeservicespage">
      <Navbar />
      <HomeServiceHero />
      <HowItWorks />

      <div className="container homeservices-container">
        <Tabs className="my-tabs" selectedTabClassName="selected-tab" tabIndex={0}>
          <div className="navigation-tabs">
            <TabList className="tab-list">
              {tabs.map((tab, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tab className="tab-btn" key={index}>
                  {tab.toUpperCase()}
                </Tab>
              ))}
            </TabList>
          </div>
          <div className="wrapper">
            <TabPanel>
              <div className="container p-0">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <AllHomeServices />
                  </div>
                  <HowItWorksItem />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <HomeServiceCard
                    title="Vendors"
                    services={
                      servicesData.find((item) => item.title == 'Vendors').services
                    }
                  />
                </div>
                <HowItWorksItem />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <HomeServiceCard
                    title="Contractors"
                    services={
                      servicesData.find((item) => item.title == 'Contractors')
                        .services
                    }
                  />
                </div>
                <HowItWorksItem />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <HomeServiceCard
                    title="Legal Practitioners"
                    services={
                      servicesData.find(
                        (item) => item.title == 'Legal Practitioners'
                      ).services
                    }
                  />
                </div>
                <HowItWorksItem />
              </div>
            </TabPanel>

            <TabPanel>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <HomeServiceCard
                    title="Property Agents"
                    services={
                      servicesData.find((item) => item.title == 'Property Agents')
                        .services
                    }
                  />
                </div>
                <HowItWorksItem />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <HomeServiceCard
                    title="Engineers"
                    services={
                      servicesData.find((item) => item.title == 'Engineers').services
                    }
                  />
                </div>
                <HowItWorksItem />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <section className="service-directory-card">
                    <h3 className="title">Others</h3>

                    <div className="d-flex justify-content-between">
                      <p className="service-item">
                        <span className="count">4</span> Quantity Surveyors
                      </p>
                      <p className="service-item">
                        <span className="count">43</span> Bricklayers
                      </p>
                    </div>

                    <div className="d-flex justify-content-between">
                      <p className="service-item">
                        <span className="count">27</span> Carpenters
                      </p>
                      <p className="service-item">
                        <span className="count">9</span> Electricians
                      </p>
                    </div>

                    <div className="d-flex justify-content-between">
                      <p className="service-item">
                        <span className="count">19</span> Plumbers
                      </p>
                      <p className="service-item">
                        <span className="count">21</span> Iron workers
                      </p>
                    </div>
                  </section>
                </div>
                <HowItWorksItem />
              </div>
            </TabPanel>
          </div>
        </Tabs>
      </div>
      <CustomerFeedback />

      <Footer />
    </div>
  );
};

export default HomeServicesPage;
