import React from 'react';

const FinalStep = ({ setFormStep }) => {
  const handleNext = () => {
    setFormStep(2);
  };

  return (
    <main>
      <div className='response-container'>
        <h2 className="response-header">Thank You!</h2>
        <p className="response-message">
          Your information is submitted, an agent will contact you soon.
        </p>
      </div>
    </main>
  );
};

export default FinalStep;
