/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
// import OldBricksLogo from '../../static/images/aso-logo.svg';
import { useAuth } from '../../context/auth-context';
import LoginForm from './LoginForm';
import BricksLogo from '../../static/images/logo.jpg';

const Login = () => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) return <Redirect to="/dashboard" />;

  return (
    <section className="auth">
      <div className="container">
        <div className="row">
          <div className="col-md-4" />
          <div className="col-md-4">
            <div className="register-container">
              <div className="app-logo">
                <Link to="/">
                  <img className="logo-image" src={BricksLogo} alt="App Logo" />
                </Link>
              </div>
              <p className="register-intro">
                Welcome back to 100Bricks, please sign in to your account to continue
              </p>
              <LoginForm />
            </div>
          </div>
          <div className="col-md-4" />
        </div>
      </div>
    </section>
  );
};
export default Login;
