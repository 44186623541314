import React from 'react';
import { useHistory } from 'react-router-dom';

const HowItWorksItem = () => {
  const history = useHistory();

  const onContact = () => {
    history.push('/advert');
  };

  return (
    <div className="col mx-auto">
      <div className="how-it-works-action text-center w-100 d-flex flex-column justify-content-center align-item-center">
        <h2 className="title">HOW IT WORKS</h2>
        <p className="description">Get professional service in 4 easy steps</p>
        <div>
          <button className="book-service-button" onClick={onContact}>
            Book a service
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksItem;
