import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

export const TextField = ({
  id,
  label,
  name,
  value,
  placeholder,
  containerClassName,
  validation,
  ...rest
}) => {
  const { register, errors } = useFormContext();

  const isError = errors[name];

  return (
    <div className={containerClassName}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <textarea
        id={id}
        placeholder={placeholder}
        name={name}
        {...rest}
        type={rest.type ? rest.type : 'text'}
        className={`${rest.className} ${isError ? 'is-invalid' : ''}`}
        ref={register({ ...validation })}
      />
      <span style={{ color: '#dc3545' }}>
        <ErrorMessage name={name} errors={errors} />
      </span>
    </div>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  validation: PropTypes.shape({}),
};

TextField.defaultProps = {
  id: '',
  label: '',
  value: '',
  placeholder: '',
  containerClassName: '',
  validation: {},
};
