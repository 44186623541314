/* eslint-disable camelcase */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import usePageBanner from 'hooks/usePageBanner';

// style
import './BannerStyle.css';

// component
import FilterProperties from '../FilterProperties';

const Banner = () => {
  const { data } = usePageBanner('getHomePageBanner');
  const history = useHistory();

  const renderTooltip = (props) => (
    <Tooltip {...props}>Click to submit property</Tooltip>
  );

  const handleCalculator = () => {
    history.push('/finance/affordability');
  };

  const handleSearch = (values) => {
    const { buy_or_rent, ...rest } = values;
    history.push({
      pathname: `/properties/${buy_or_rent}`,
      state: {
        search: rest,
      },
    });
  };

  return (
    <div className="hero-container">
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <div className="hero-text">
          <h1>WELCOME TO 100BRICKS</h1>
          <p>Let Us Guide You Home, Brick by Brick</p>
        </div>
        <section className="search-filter">
          <div className="row">
            <div className="col-lg-12">
              <div className="select-fields-row ">
                <FilterProperties onSearch={handleSearch} />
              </div>
            </div>
          </div>
        </section>

        <div className="hero-cta">
          <h4 className="title">CHECK FOR PROPERTIES YOU CAN AFFORD</h4>
          <p className="description">
            Use our Affordability Calculator to learn about houses you can afford
          </p>
          <button className="cta-button" onClick={handleCalculator}>
            Check Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
