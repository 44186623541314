import React from 'react';
import './HomeServiceHero.css';
import { useHistory } from 'react-router-dom';

const HomeServiceHero = () => {
  const history = useHistory();

  const handleCalculator = () => {
    history.push('/finance/affordability');
  };

  const onRegister = () => {
    history.push('/register');
  };

  const onContact = () => {
    history.push('/advert');
  };

  return (
    <section className="service-section">
      <div className="home-service-container">
        <div className="service-content">
          <div className="service-text">
            <h2>HIRE PROFESSIONALS</h2>
            <p>
              Connect with certified and trusted service experts for your real estate
              projects. Our experts range from Engineers, Architects, and Surveyors
              to Plumbers, Bricklayers, and Carpenters.
            </p>
            <div className="service-buttons">
              <button className="service-btn">Book a service</button>
              <button className="service-btn" onClick={onRegister}>
                Register as a pro
              </button>
            </div>
          </div>

          <div className="service-image-container">
            <img
              src="/assets/home-service2.jpeg"
              alt="Professional Worker"
              className="service-image"
            />
          </div>
        </div>
      </div>
      <div className="hero-cta">
        <h4 className="title">CHECK FOR PROPERTIES YOU CAN AFFORD</h4>
        <p className="description">
          Use our Affordability Calculator to learn about houses you can afford
        </p>
        <button className="cta-button" onClick={handleCalculator}>
          Check Now
        </button>
      </div>
    </section>
  );
};

export default HomeServiceHero;
