/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { mergeRefs } from 'utils/mergeRefs';

export const FileInput = ({
  label,
  name,
  description,
  validation,
  preview,
  containerClassName,
  allowedFormats,
  ...rest
}) => {
  const inputRef = React.useRef(null);

  const triggerFileSelection = () => {
    inputRef.current.click();
  };

  const { register, errors, watch } = useFormContext();
  const isError = errors[name];
  const files = watch(name);

  const imagePreview = files?.length
    ? Array.from(files).map(URL.createObjectURL)
    : preview;

  return (
    <div className={containerClassName || 'col-12'}>
      <label htmlFor="" className="form-label">
        {label}
      </label>
      <div
        className={`submitlist-uploader ${isError ? 'is-invalid' : ''}`}
        onClick={triggerFileSelection}
        role="presentation"
        style={{ border: `${isError ? '1px solid #dc3545' : ''}` }}
      >
        <input
          id="list-input"
          type="file"
          name={name}
          {...rest}
          ref={mergeRefs(inputRef, register({ ...validation }))}
        />
        <>
          {imagePreview?.length ? (
            <div className="d-flex w-100">
              {imagePreview?.map((url, index) => {
                return (
                  <div key={index} className="mr-2">
                    <img
                      src={url}
                      alt="preview"
                      style={{ width: '100px', height: '100px' }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <label htmlFor="list-input">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M39.0376 13.4041C38.5244 10.3541 37.0142 7.57064 34.7045 5.45836C32.1382 3.10919 28.802 1.81616 25.3276 1.81616C22.6428 1.81616 20.0271 2.58606 17.7866 4.03701C15.921 5.24121 14.3714 6.86983 13.2758 8.7847C12.802 8.69587 12.3085 8.64651 11.8149 8.64651C7.61999 8.64651 4.20481 12.0617 4.20481 16.2566C4.20481 16.7995 4.26403 17.3226 4.36274 17.8359C1.64837 19.81 0 22.9883 0 26.3738C0 29.108 1.01666 31.7631 2.8723 33.8655C4.7773 36.0173 7.29426 37.2906 9.97903 37.4386C10.0086 37.4386 10.0284 37.4386 10.058 37.4386H18.5466C19.2869 37.4386 19.8791 36.8464 19.8791 36.1061C19.8791 35.3658 19.2869 34.7736 18.5466 34.7736H10.0975C6.06046 34.5268 2.66502 30.6872 2.66502 26.364C2.66502 23.5706 4.16533 20.9648 6.58359 19.5534C7.14621 19.2276 7.3831 18.5466 7.16595 17.9346C6.96854 17.4016 6.86983 16.839 6.86983 16.2369C6.86983 13.5126 9.09068 11.2918 11.8149 11.2918C12.3973 11.2918 12.9698 11.3905 13.5028 11.5879C14.1542 11.8248 14.8748 11.5287 15.1709 10.9068C17.0167 6.98828 21.0043 4.46144 25.3374 4.46144C31.161 4.46144 35.9679 8.82418 36.5207 14.6083C36.5799 15.2104 37.0339 15.694 37.6262 15.7927C42.0185 16.5429 45.335 20.5996 45.335 25.2289C45.335 30.1345 41.4756 34.3985 36.7181 34.7637H29.4436C28.7033 34.7637 28.111 35.356 28.111 36.0962C28.111 36.8365 28.7033 37.4287 29.4436 37.4287H36.7674C36.797 37.4287 36.8267 37.4287 36.8661 37.4287C39.8766 37.2116 42.6897 35.8297 44.7822 33.5201C46.8649 31.2301 48 28.2887 48 25.2289C47.9901 19.6915 44.2098 14.7662 39.0376 13.4041Z"
                    fill="#5A5B5C"
                    fillOpacity="0.7"
                  />
                  <path
                    d="M31.9997 27.6372C32.5228 27.1141 32.5228 26.2751 31.9997 25.752L24.9423 18.6946C24.6955 18.4479 24.3501 18.2998 24.0046 18.2998C23.6591 18.2998 23.3137 18.438 23.0669 18.6946L16.0095 25.752C15.4864 26.2751 15.4864 27.1141 16.0095 27.6372C16.2662 27.8939 16.6116 28.0321 16.9472 28.0321C17.2828 28.0321 17.6283 27.9037 17.8849 27.6372L22.6721 22.8501V44.8513C22.6721 45.5916 23.2643 46.1838 24.0046 46.1838C24.7449 46.1838 25.3371 45.5916 25.3371 44.8513V22.8501L30.1243 27.6372C30.6375 28.1604 31.4765 28.1604 31.9997 27.6372Z"
                    fill="#5A5B5C"
                    fillOpacity="0.7"
                  />
                </svg>
              </label>
              <span>{description}</span>
              {allowedFormats && (
                <div className="flex justify-center mt-5">
                  <div className="file-format">
                    {allowedFormats.map((format) => (
                      <span>{format}</span>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

FileInput.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  validation: PropTypes.shape({}),
  name: PropTypes.string,
  preview: PropTypes.arrayOf(PropTypes.string),
  allowedFormats: PropTypes.arrayOf(PropTypes.string),
  containerClassName: PropTypes.string,
};

FileInput.defaultProps = {
  label: '',
  description: '',
  validation: {},
  name: '',
  preview: [],
  containerClassName: '',
  allowedFormats: [],
};
