import React from 'react';

const MultiFamilyIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5835 8.00001C15.3074 8.00001 15.0835 8.22387 15.0835 8.50001V9.50001C15.0835 9.77615 15.3074 10 15.5835 10H16.5835C16.8596 10 17.0835 9.77615 17.0835 9.50001V8.50001C17.0835 8.22387 16.8596 8.00001 16.5835 8.00001H15.5835Z"
      fill="#2B2B2B"
    ></path>
    <path
      d="M15.0835 12.5C15.0835 12.2239 15.3074 12 15.5835 12H16.5835C16.8596 12 17.0835 12.2239 17.0835 12.5V13.5C17.0835 13.7762 16.8596 14 16.5835 14H15.5835C15.3074 14 15.0835 13.7762 15.0835 13.5V12.5Z"
      fill="#2B2B2B"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0756 22C12.0782 22 12.0809 22 12.0835 22L12.0914 22H18.0835C19.7404 22 21.0835 20.6569 21.0835 19V8.00001C21.3394 8.00001 21.5953 7.90238 21.7906 7.70712C22.1811 7.31659 22.1811 6.68343 21.7906 6.29291L18.2904 2.79275C17.0699 1.5722 15.0737 1.63087 13.9269 2.92098L12.0835 4.99481L10.2401 2.92098C9.09332 1.63087 7.09709 1.5722 5.87654 2.79275L2.37639 6.29291C1.98586 6.68343 1.98586 7.31659 2.37639 7.70712C2.57165 7.90238 2.82757 8.00001 3.0835 8.00001V19C3.0835 20.6569 4.42664 22 6.0835 22H12.0756ZM15.0835 20H13.0835V6.88021L15.4217 4.24971C15.804 3.81967 16.4694 3.80012 16.8762 4.20696L19.0835 6.41423V19C19.0835 19.5523 18.6358 20 18.0835 20H17.0835V17.5C17.0835 17.2239 16.8596 17 16.5835 17H15.5835C15.3074 17 15.0835 17.2239 15.0835 17.5V20ZM7.0835 8.5C7.0835 8.22386 7.30735 8 7.5835 8H8.5835C8.85964 8 9.0835 8.22386 9.0835 8.5V9.5C9.0835 9.77614 8.85964 10 8.5835 10H7.5835C7.30735 10 7.0835 9.77614 7.0835 9.5V8.5ZM7.5835 12C7.30735 12 7.0835 12.2239 7.0835 12.5V13.5C7.0835 13.7761 7.30735 14 7.5835 14H8.5835C8.85964 14 9.0835 13.7761 9.0835 13.5V12.5C9.0835 12.2239 8.85964 12 8.5835 12H7.5835ZM7.0835 17.5C7.0835 17.2239 7.30735 17 7.5835 17H8.5835C8.85964 17 9.0835 17.2239 9.0835 17.5V20H7.0835V17.5Z"
      fill="#2B2B2B"
    ></path>
  </svg>
);
export default MultiFamilyIcon;
