import React from 'react';
import PropTypes from 'prop-types';
import { ReactQueryConfigProvider, QueryCache } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider } from './auth-context';
import { FormProvider } from './approval-form-context';

const queryCache = new QueryCache();

// Override some react query defaults to suit
// our use case.
// Feel free to override these for your request's usecase
// if needed
const reactQueryConfig = {
  queries: {
    // react query refetches on window focus to avoid
    // stale data, but that doesn't apply to us
    refetchOnWindowFocus: false,
    // useErrorBoundary: true,
  },
  mutations: {
    // in order to avoid unhandled errors, if all you do
    // is show an alert, using the `error` returned from `useMutation`
    // for example
    throwOnError: false,
  },
};
function AppProviders({ children }) {
  return (
    <ReactQueryConfigProvider config={reactQueryConfig} queryCache={queryCache}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AuthProvider>
          <FormProvider>{children}</FormProvider>
        </AuthProvider>
      </Router>
    </ReactQueryConfigProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppProviders };
