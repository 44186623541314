/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import useFetchPropertyTypes from 'hooks/useFetchPropertyTypes';
import useStateList from 'hooks/useStateList';
import Form from 'components/common/Form';

// style
import './SelectPropertyFieldsStyle.css';

const SelectPropertyFields = ({ onSearch }) => {
  const { data } = useFetchPropertyTypes();
  const countryIdForNigeria = 161;

  const { data: stateData } = useStateList({ countryID: countryIdForNigeria });
  const methods = useForm();

  return (
    <Form
      className="d-block d-sm-block d-lg-flex select-flex"
      onSubmit={onSearch}
      methods={methods}
    >
      <div className="select-flex-item looking-for">
        <label htmlFor="e" className="form-label select-label">
          LOOKING FOR?
        </label>
        <select
          className="form-select form-select-lg br-0 searchformfields"
          aria-label=".form-select-lg example"
          name="buy_or_rent"
          ref={methods.register}
        >
          <option value="buy">BUY</option>
          <option value="rent">RENT</option>
        </select>
      </div>
      <div className="select-flex-item">
        <label htmlFor="e" className="form-label select-label">
          PROPERTY TYPE
        </label>
        <select
          className="form-select form-select-lg br-0"
          aria-label=".form-select-lg example"
          name="property_type_id"
          ref={methods.register}
        >
          {data?.property_types?.map(({ id, name }) => (
            <option value={id} key={id}>
              {name.toUpperCase()}
            </option>
          ))}
        </select>
      </div>
      <div className="select-flex-item">
        <label htmlFor="e" className="form-label select-label">
          LOCATION
        </label>
        <select
          className="form-select form-select-lg br-0 searchformfields"
          aria-label=".form-select-lg example"
          name="state_id"
          ref={methods.register}
        >
          <option value="">SELECT</option>
          {stateData?.states?.map(({ id, name }) => (
            <option value={id} key={id}>
              {name?.toUpperCase()}
            </option>
          ))}
        </select>
      </div>
      <div className="select-flex-item">
        <label htmlFor="e" className="form-label select-label">
          MAXIMUM PRICE
        </label>
        <input
          className="form-control form-control-lg br-0"
          aria-label="form-select-lg example"
          placeholder="PRICE"
          type="number"
          name="max_price"
          ref={methods.register}
        />
      </div>
      <div className="select-flex-iems select-btn">
        <button type="submit" className="btn btn-danger">
          SEARCH
        </button>
      </div>
    </Form>
  );
};

SelectPropertyFields.propTypes = {
  onSearch: PropTypes.func,
};

SelectPropertyFields.defaultProps = {
  onSearch: () => {},
};

export default SelectPropertyFields;
