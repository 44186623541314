import React from 'react';
import PropTypes from 'prop-types';
import { moneyFormat } from 'utils/moneyFormat';

// components
import Modal from 'components/Modal';
import Loader from 'components/Loader';

import { useFetchPropertyOffers } from 'hooks/useFetchPropertyOffers';

function ViewOffers(props) {
  const { open, closeModal, propertyId } = props;
  const { data, isLoading } = useFetchPropertyOffers({ property_id: propertyId });
  return (
    <Modal size="lg" open={open} closeModal={closeModal} title="OFFERS">
      <div className="table-stiped">
        {isLoading ? (
          <Loader color="primary" small />
        ) : (
          <>
            {data?.propertyOffers?.length ? (
              <table className="table table-striped">
                <thead className="table-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone No</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.propertyOffers?.map((offer, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{offer?.name || offer?.offer_by?.name}</td>
                      <td>{offer?.phone_no}</td>
                      <td>{offer?.amount ? moneyFormat(offer?.amount) : 'N/A'}</td>
                      <td>{offer?.note}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No offers</div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

ViewOffers.propTypes = {
  propertyId: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

ViewOffers.defaultProps = {
  propertyId: 0,
};

export default ViewOffers;
