import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function useFetchPropertyTypes(params) {
  return useQuery(['property-features', params], () =>
    client('payments/viewBackgroundInfo', {
      params,
    }).then((res) => res)
  );
}
