import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { TextField } from 'components/common/TextField';
import { Button } from 'components/common/Button';
import { useSendMessage } from './hooks/useSendMessage';

export default function RateProfessionalForm(props) {
  const { homeServiceId } = props;

  const methods = useForm({
    market_expert: 0,
    responsiveness: 0,
    negotiation_skill: 0,
    professionalism_communication: 0,
    home_service_id: homeServiceId,
  });

  const [sendMessage, { isLoading }] = useSendMessage();

  const onSubmit = (values) => {
    sendMessage(values, {
      onSuccess: (data) => {
        toast.success(`${data?.Detail}`, {
          style: {
            background: '#d4edda',
            color: '#155724',
          },
        });
      },
    });
  };

  return (
    <Form className="mt-4" methods={methods} onSubmit={onSubmit}>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="singlepropage-inquiry_card">
              <img
                src="https://images.unsplash.com/photo-1597065924382-e6ebee63126d?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAwfHxob3VzZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                alt="overlay"
              />
              <div className="singlepropage-overlay">
                <h1>
                  Become an interior designer in just three months & get certified
                </h1>
                <Link to="/">GET STARTED</Link>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="singlepropage-inquiry_contact">
              <h1>Have a question? Contact us</h1>
              <form className="mt-4">
                <div className="row">
                  <Input
                    name="home_service_id"
                    type="hidden"
                    className="form-control form-control-lg br-0 solid-black-2 "
                    placeholder="Name"
                    validation={{ required: true }}
                    defaultValue={homeServiceId}
                  />
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Input
                        type="text"
                        name="name"
                        className="form-control form-control-lg br-0 solid-black-2 "
                        id="h"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Input
                        type="email"
                        name="email"
                        className="form-control form-control-lg br-0 solid-black-2 "
                        id="h"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <TextField
                    className="form-control br-0 solid-black-2 "
                    id="exampleFormControlTextarea1"
                    rows="8"
                    name="message"
                    placeholder="Message"
                  />
                </div>
                <Button
                  type="submit"
                  className="btn btn-lg bg-btn-black br-0"
                  isLoading={isLoading}
                >
                  SEND MESSAGE
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}
