import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';

import { useFormContext } from 'react-hook-form';

export const Select = ({
  id,
  label,
  name,
  placeholder,
  containerClassName,
  validation,
  options,
  ...rest
}) => {
  const { register, errors } = useFormContext();
  const isError = errors[name];

  return (
    <div className={containerClassName}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        placeholder={placeholder}
        ref={register({ ...validation })}
        defaultValue={rest.defaultValue}
        name={name}
        className={`${rest.className} ${isError ? 'is-invalid' : ''}`}
      >
        <option disabled>Choose...</option>
        {options?.length &&
          options.map(({ id: optionId, name: optionName }) => (
            <option value={optionId} key={optionId}>
              {optionName}
            </option>
          ))}
      </select>
      <span style={{ color: '#dc3545' }}>
        <ErrorMessage name={name} errors={errors} />
      </span>
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  validation: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

Select.defaultProps = {
  id: '',
  label: '',
  value: '',
  placeholder: '',
  containerClassName: '',
  validation: {},
  options: [],
};
