import Navbar from 'components/Navbar';
import React, { useState } from 'react';
import './ApprovalFormStyle.css';
import 'react-input-range/lib/css/index.css';
import PropertyLocation from 'components/GetApproval/PropertyLocation';
import { FiCheck } from 'react-icons/fi';
import FinalStep from 'components/GetApproval/FinalStep';
import Details from 'components/GetApproval/Details';
import { useFormData } from 'context/approval-form-context';
import Footer from 'components/GetApproval/Footer/index';
import Contact from 'components/GetApproval/Contact';
import HomeType from 'components/GetApproval/HomeType';

const ApprovalFormPage = () => {
  const { formStep, data } = useFormData();

  const steps = [
    { id: 1, title: 'Property' },
    { id: 2, title: 'Details' },
    { id: 3, title: 'Wrap-Up' },
  ];

  return (
    <div className="">
      <Navbar />
      <div className="container">
        {formStep === 0 ? (
          <HomeType />
        ) : (
          <main>
            <section className="get-approval-page">
              <section className="step-indicator">
                {steps.map((step, index) => (
                  <React.Fragment key={step.id}>
                    <div
                      className={`step step${formStep} ${
                        formStep == index + 1 ? 'active' : ''
                      } ${formStep > index + 1 ? 'complete' : ''}`}
                      key={step.id}
                    >
                      <div className="step-icon">
                        {formStep > index + 1 ? <FiCheck /> : null}
                      </div>
                      <p className="">{step.title}</p>
                    </div>
                    {index !== steps.length - 1 && (
                      <div
                        className={`indicator-line ${
                          formStep > index + 1 ? 'active' : ''
                        }`}
                      ></div>
                    )}
                  </React.Fragment>
                ))}
              </section>
              <main className="d-flex flex-column mx-auto col-sm-12 col-md-10 mt-5">
                {formStep === 1 ? <PropertyLocation /> : null}
                {formStep === 2 ? <Details /> : null}
                {formStep === 3 ? <Contact /> : null}
                {formStep === 4 ? <FinalStep /> : null}
              </main>
              <Footer />
            </section>
          </main>
        )}
      </div>
    </div>
  );
};

export default ApprovalFormPage;

const approvalSteps = [
  {
    image: '/assets/form/1.png',
    step: 1,
  },
  {
    image: '/assets/form/2.png',
    step: 2,
  },
  {
    image: '/assets/form/3.png',
    step: 3,
  },
  {
    image: '/assets/form/4.png',
    step: 4,
  },
  {
    image: '/assets/form/5.png',
    step: 5,
  },
  {
    image: '/assets/form/6.png',
    step: 6,
  },
];
