import { useMutation } from 'react-query';
import { client } from 'utils/api-client';

export function useUploadAdvert() {
  return useMutation((values) =>
    client('payments/advertPayment', {
      data: values,
    }).then((res) => res)
  );
}
