/* eslint-disable camelcase */

const removeNullProperties = (object) => {
  const outObject = {};
  Object.keys(object).forEach((key) => {
    if (object[key]) outObject[key] = object[key];
  });
  return outObject;
};

export const convertFormValues = (values, userID, propertyID) => {
  // remove null properties for update
  const { address, lat, lng } = values.propertyLocation;

  const {
    sale_or_rent,
    for_sale_aso_or_owner,
    primary_image,
    optional_images,
    documents,
    property_docs,
    floor_plans,
    featureIDs,
    town_id,
    ...rest
  } = removeNullProperties(values);

  const payload = {
    ...rest,
    town_id: town_id || 1,
    userID,
    for_sale: radioValueToInt(sale_or_rent),
    for_rent: radioValueToInt(!sale_or_rent),
    for_sale_aso: radioValueToInt(for_sale_aso_or_owner),
    for_sale_by_owner: radioValueToInt(!for_sale_aso_or_owner),
    address,
    latitude: lat,
    longitude: lng,
  };

  if (propertyID) payload.propertyID = propertyID;

  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });

  for (const image of primary_image) {
    formData.append('primary_image', image, image.name);
  }

  for (const doc of property_docs) {
    formData.append('property_docs', doc, doc.name);
  }

  for (const floor_plan of floor_plans) {
    formData.append('floor_plans', floor_plan, floor_plan.name);
  }

  Array.from(optional_images).forEach((image) =>
    formData.append('optional_images[]', image, image.name)
  );
  featureIDs.forEach((id, index) => {
    formData.append(`featureIDs[${index}]`, Number.parseInt(id, 10));
  });

  return formData;
};

export const convertAdvertValues = (values) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    advert_image,
    companyName,
    productInformation,
    ...rest
  } = removeNullProperties(values);

  const payload = {
    ...rest,
    firstName,
    lastName,
    email,
    phoneNumber,
    advert_image,
    companyName,
    productInformation,
  };

  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });

  for (const file of advert_image) {
    formData.append('advert_image', file, file.name);
  }

  return formData;
};

export const conversionSale = (values) => {
  const { ownership, proof_of_ownership, photo, ...rest } = removeNullProperties(
    values
  );

  const payload = {
    ...rest,
    ownership: radioValueToInt(ownership),
    agent: radioValueToInt(!ownership),
    others: radioValueToInt(!ownership),
  };

  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });

  for (const image of photo) {
    formData.append('photo', image, image.name);
  }

  for (const doc of proof_of_ownership) {
    formData.append('proof_of_ownership', doc, doc.name);
  }

  return formData;
};

export const convertSellFormValues = (values) => {
  // remove null properties for update
  // const { address, lat, lng } = values.propertyLocation;

  const {
    photo,
    primary_image,
    optional_images,
    proof_of_ownership,
    town_id,
    ...rest
  } = removeNullProperties(values);

  const payload = {
    ...rest,
    town_id: town_id || 1,
  };

  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });

  for (const image of primary_image) {
    formData.append('primary_image', image, image.name);
  }

  for (const own of proof_of_ownership) {
    formData.append('proof_of_ownership', own, own.name);
  }

  for (const pic of photo) {
    formData.append('photo', pic, pic.name);
  }

  // Array.from(optional_images).forEach((image) =>
  //   formData.append('optional_images[]', image, image.name)
  // );

  return formData;
};

const radioValueToInt = (value) => {
  switch (value) {
    case true:
      return 1;
    case false:
      return 0;
    case 'on':
      return 1;
    case 'off':
      return 0;
    default:
      return 0;
  }
};

const intToRadioValue = (intValue) => {
  switch (intValue) {
    case 0:
      return 'off';
    case 1:
      return 'on';
    default:
      return 'off';
  }
};

export const getFormDefaultValues = (property) => {
  if (!property) {
    return {
      propertyLocation: {
        address: '',
        lat: 9.072264,
        lng: 7.491302,
      },
    };
  }

  const {
    price,
    for_sale,
    for_sale_aso,
    property_features,
    town,
    city,
    country,
    currency,
    state,
    property_type,
    property_category,
    no_bedroom,
    no_bathroom,
    no_kitchen,
    square_feet,
    year_built,
    address,
    latitude,
    longitude,
    ...rest
  } = property;

  return {
    ...rest,
    propertyLocation: {
      address,
      lat: latitude,
      lng: longitude,
    },
    sale_or_rent: intToRadioValue(for_sale),
    for_sale_aso_or_owner: intToRadioValue(for_sale_aso),
    price: price.split('.')[0],
    featureIDs: property_features.map(({ id }) => `${id}`),
    country_id: country?.id,
    state_id: state?.id,
    property_type_id: property_type?.id,
    property_category_type: property_category?.id,
    bedrooms: no_bedroom,
    bathrooms: no_bathroom || 1,
    kitchen: no_kitchen || 1,
    square_feet: square_feet || 1,
    year_built: year_built ? new Date(year_built) : new Date(),
  };
};
