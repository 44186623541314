import React, { useEffect, useState } from 'react';

const PropertyDetailScript = ({ id }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.dataset.short = id;
    script.dataset.path = 'tours';
    script.dataset.isSelfHosted = 'undefined';
    script.width = '100%';
    script.height = '500px';
    script.src = 'https://app.cloudpano.com/public/shareScript.js';

    const scriptDiv = document.getElementById(id);
    if (scriptDiv) {
      scriptDiv.appendChild(script);
    }

    script.onload = () => {
      setLoading(false);
    };

    script.onerror = () => {
      setLoading(false);
      console.error('Error loading the script');
    };

    return () => {
      if (scriptDiv) {
        scriptDiv.removeChild(script);
      }
    };
  }, []);

  return (
    <div id={id} style={{ width: '100%', height: '500px' }}>
      {loading && <div>Loading...</div>}
    </div>
  );
};
export default PropertyDetailScript;
