import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { Radio } from 'components/common/Radio';
import './RegisterForm.css';
import { ReactComponent as LoadingIcon } from 'static/images/oval.svg';

const RegisterForm = (props) => {
  const { signUp, error, isLoading, authProvider } = props;
  const methods = useForm({ defaultValues: {} });
  const { setError } = methods;

  useEffect(() => {
    if (!error?.ResponseMessage || typeof error?.ResponseMessage !== 'object') {
      return;
    }
    Object.keys(error.ResponseMessage).forEach((key) => {
      setError(key, { type: 'manual', message: error.ResponseMessage[key][0] });
    });
  }, [error, setError]);

  const { fullWidth } = props;

  return (
    <>
      <div className="social-btns">
        <button
          className="btn facebook-btn"
          type="button"
          onClick={() => authProvider.FacebookSignin()}
        >
          <span>
            <svg
              width="9"
              height="15"
              viewBox="0 0 9 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.0075 8.43703L8.452 5.7225H5.6735V3.96094C5.6735 3.21844 6.0615 2.49422 7.306 2.49422H8.569V0.183281C8.569 0.183281 7.423 0 6.327 0C4.039 0 2.5435 1.30031 2.5435 3.65391V5.72297H0V8.4375H2.5435V15H5.6735V8.4375L8.0075 8.43703Z"
                fill="black"
              />
            </svg>
          </span>
          <span>FACEBOOK</span>
        </button>
        <button
          className="btn google-btn"
          type="button"
          onClick={() => authProvider.GoogleSignin()}
        >
          <span>
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8623 6.13485H7.65265V9.03713H11.7713C11.5937 9.97524 11.0549 10.7687 10.2428 11.3003C9.55634 11.7498 8.68032 12.0156 7.65066 12.0156C5.65718 12.0156 3.971 10.6964 3.36837 8.92378C3.21671 8.47427 3.12891 7.99349 3.12891 7.49902C3.12891 7.00456 3.21671 6.52378 3.36837 6.07427C3.97299 4.30358 5.65917 2.98437 7.65265 2.98437C8.77611 2.98437 9.78382 3.36352 10.578 4.10619L12.773 1.9544C11.4461 0.742671 9.71598 0 7.65265 0C4.66143 0 2.0733 1.68078 0.814155 4.1316C0.295331 5.14397 0 6.28925 0 7.50098C0 8.7127 0.295331 9.85603 0.814155 10.8684C2.0733 13.3192 4.66143 15 7.65265 15C9.71997 15 11.452 14.3277 12.7172 13.1824C14.1639 11.8769 15 9.95375 15 7.66906C15 7.13746 14.9521 6.62736 14.8623 6.13485Z"
                fill="black"
              />
            </svg>
          </span>
          <span>GOOGLE</span>
        </button>
      </div>
      <p className="secondary-intro">or create account with</p>
      <Form className="form-fields" onSubmit={signUp} methods={methods}>
        {error && (
          <div
            className="alert alert-danger mb-2"
            style={{ textAlign: 'center' }}
            role="alert"
          >
            {error?.ResponseMessage
              ? 'Please review the field errors'
              : error?.message}
          </div>
        )}
        <div className="row mb-3">
          <Input
            id="fullname"
            name="name"
            label="YOUR FULL NAME"
            containerClassName={`${fullWidth ? 'col-md-12' : 'col-md-6'}`}
            className="form-control form-control-lg br-0"
            placeholder=""
            validation={{ required: 'This is required.' }}
          />
          <Input
            id="email"
            name="email"
            label="YOUR EMAIL ADDRESS"
            containerClassName={`${fullWidth ? 'col-md-12' : 'col-md-6'}`}
            className="form-control form-control-lg br-0"
            type="email"
            placeholder=""
            validation={{ required: 'This is required.' }}
          />
        </div>
        <div className="row mb-3">
          <Input
            id="password"
            name="password"
            label="YOUR PASSWORD"
            containerClassName={`${fullWidth ? 'col-md-12' : 'col-md-6'}`}
            className="form-control form-control-lg br-0"
            type="password"
            placeholder=""
            validation={{
              required: true,
              minLength: { value: 6, message: 'Password is too short' },
            }}
          />
          <Input
            id="phoneNumber"
            name="phoneNumber"
            label="YOUR PHONE NUMBER"
            containerClassName={`${fullWidth ? 'col-md-12' : 'col-md-6'}`}
            className="form-control form-control-lg br-0"
            placeholder=""
            validation={{ required: 'This is required.' }}
          />
        </div>
        <Radio
          containerClassName="form-check mb-4"
          className="form-check-input"
          type="radio"
          name="termsAgreement"
          label="I ACCEPT THE TERMS OF PRIVACY POLICIES"
          id="flexRadioDefault1"
          labelClassName="form-check-label register-check-label"
          validation={{ required: 'You must agree to the terms of privacy' }}
        />{' '}
        <span className="note">
          By accepting the T&C, you agree to receiving update e-mails.
        </span>
        <center>
          <Link to="/privacy">Our Privacy and Policy</Link>
        </center>
        <button
          type="submit"
          disabled={isLoading}
          to="/dashboard"
          className="btn btn-block btn-danger br-2 btn-lg btn-create-account"
        >
          {isLoading ? <LoadingIcon style={{ height: '30px' }} /> : 'CREATE ACCOUNT'}
        </button>
      </Form>
      <p className="already-have-an-account">
        ALREADY HAVE AN ACCOUNT?{' '}
        <span>
          <Link to="/login">SIGN IN</Link>
        </span>
      </p>
    </>
  );
};

RegisterForm.propTypes = {
  signUp: PropTypes.func.isRequired,
  error: PropTypes.shape({
    ResponseMessage: PropTypes.shape({ email: PropTypes.string }),
    message: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  authProvider: PropTypes.shape({
    GoogleSignin: PropTypes.func,
    FacebookSignin: PropTypes.func,
  }).isRequired,
  fullWidth: PropTypes.bool.isRequired,
};

RegisterForm.defaultProps = {
  error: null,
};

export default RegisterForm;
