/* eslint-disable camelcase */
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuth } from 'context/auth-context';

// components
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import { getBase64 } from 'utils/fileToBase64';
import JoinHomeServiceProfessional from './HomeServiceProfessionalForm';

// style
import './ProFormPageStyle.css';

const ProFormPage = () => {
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  React.useEffect(() => {
    if (!isAuthenticated) {
      history.push({
        pathname: 'login',
        state: { from: '/join_as_pro' },
      });
    }
  }, [history, isAuthenticated]);

  const onSubmit = async (values) => {
    const { image_link, ...rest } = values;
    const imageStringData = await getBase64(image_link[0]);
    localStorage.setItem(
      'homeServiceData',
      JSON.stringify({
        ...rest,
        image_link: imageStringData,
      })
    );
    history.push('/join_as_pro/subscription');
  };

  return (
    <div className="proformpage">
      <Navbar />
      <div className="container proformpage-card">
        <div className="breadcrumb-wrapper">
          <NavLink to="/services">Home service</NavLink>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span>Join as a professional</span>
        </div>
        <h1 className="submitlistingpage-header">
          Join Home Services As a Professional
        </h1>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <p className="proformpage-subheader">
              Our home affordability payments calculator can help you estimate what
              you can afford to spend on your new home.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            <div className="proformpage-form">
              <JoinHomeServiceProfessional onSubmit={onSubmit} />
            </div>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProFormPage;
