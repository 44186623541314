import { servicesData } from 'data';
import React from 'react';
import HomeServiceCard from './HomeServiceCard';


const AllHomeServices = () => {
  return (
    <div className="all-services-directory">
      {servicesData.map((serviceCategory, index) => (
        <HomeServiceCard
          key={index}
          title={serviceCategory.title}
          services={serviceCategory.services}
        />
      ))}

      <section className="service-directory-card">
        <h3 className="title">Others</h3>

        <div className="d-flex justify-content-between">
          <p className="service-item">
            <span className="count">4</span> Quantity Surveyors
          </p>
          <p className="service-item">
            <span className="count">43</span> Bricklayers
          </p>
        </div>

        <div className="d-flex justify-content-between">
          <p className="service-item">
            <span className="count">27</span> Carpenters
          </p>
          <p className="service-item">
            <span className="count">9</span> Electricians
          </p>
        </div>

        <div className="d-flex justify-content-between">
          <p className="service-item">
            <span className="count">19</span> Plumbers
          </p>
          <p className="service-item">
            <span className="count">21</span> Iron workers
          </p>
        </div>
      </section>
    </div>
  );
};

export default AllHomeServices;


