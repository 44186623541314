import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import SalesCard from 'components/SalesCard';
import Pagination from 'components/Pagination';
import Loader from 'components/Loader';
import { useAuth } from 'context/auth-context';
import { formateDate } from 'utils/dateUtils';
import ViewComment from 'components/MyProperties/ViewComment';
import ViewOffers from 'components/MyProperties/ViewOffers';
import PaymentEvidenceModal from 'components/MyProperties/PaymentEvidenceModal';
import { usePropertyAction } from './hooks/usePropertyAction';
import { useMyPropertyList } from './hooks';

export default function MyProperties() {
  const { user } = useAuth();
  const [page, handlePageChange] = React.useState(1);
  const [currentTab, setCurrentTabTab] = React.useState(undefined);
  const [propertyAction, setPropertyAction] = usePropertyAction();
  const history = useHistory();

  const queryCacheParams = [
    'myProperties',
    { userID: user.userID, page_type: currentTab, page },
  ];

  const { data, isLoading } = useMyPropertyList(queryCacheParams);

  return (
    <div className="userdashboard-tabcontent">
      <TabProvider
        defaultTab="allproperties"
        onChange={(tab) => setCurrentTabTab(tab)}
      >
        <div className="my-tabs">
          <div className="container">
            <div className="newlistings-flex">
              <span className="userdashboard_tabheader">All Properties</span>
              <div className="newlistings-tabs">
                <TabList className="tablist">
                  <Tab tabFor="allproperties" className="tab-btn">
                    All
                  </Tab>
                  <Tab tabFor="interested" className="tab-btn">
                    Interested
                  </Tab>
                  <Tab tabFor="purchased" className="tab-btn">
                    Purchased
                  </Tab>
                  <Tab tabFor="uploaded" className="tab-btn">
                    Uploaded
                  </Tab>
                </TabList>
              </div>
            </div>
          </div>
          <div className="wrapper userdashboardpage-wrapper">
            <TabPanel tabId="allproperties">
              {isLoading ? (
                <Loader color="primary" />
              ) : (
                <div className="container">
                  {data?.myProperties?.meta?.total ? (
                    <div className="row">
                      {data?.myProperties?.data?.map((item) => (
                        <div className="col-md-4 aux-margin" key={item.propertyID}>
                          <span className="misc-date">
                            {formateDate(item.date_created)}
                          </span>
                          <SalesCard {...item} queryCacheParams={queryCacheParams} />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center mb-5">
                      You don&apos;t have property
                    </div>
                  )}
                </div>
              )}
            </TabPanel>
            <TabPanel tabId="interested">
              {isLoading ? (
                <Loader color="primary" />
              ) : (
                <div className="container">
                  {data?.myProperties?.meta?.total ? (
                    <div className="row">
                      {data?.myProperties?.data?.map((item) => (
                        <div className="col-md-4 aux-margin" key={item.propertyID}>
                          <span className="misc-date">
                            {formateDate(item.date_created)}
                          </span>
                          <SalesCard {...item} queryCacheParams={queryCacheParams} />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      You don&apos;t have property
                    </div>
                  )}
                </div>
              )}
            </TabPanel>
            <TabPanel tabId="purchased">
              {isLoading ? (
                <Loader color="primary" />
              ) : (
                <div className="container">
                  {data?.myProperties?.meta?.total ? (
                    <div className="row">
                      {data?.myProperties?.data?.map((item) => (
                        <div className="col-md-4 aux-margin" key={item.propertyID}>
                          <span className="misc-date">
                            {formateDate(item.date_created)}
                          </span>
                          <SalesCard
                            {...item}
                            queryCacheParams={queryCacheParams}
                            propertyType="purchased"
                            handleAction={setPropertyAction}
                          >
                            <button
                              className="btn btn-danger br-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                setPropertyAction({
                                  type: 'paymentEvidence',
                                  propertyId: item.propertyID,
                                });
                              }}
                              type="button"
                            >
                              Uplaoad Payment Evidence
                            </button>
                          </SalesCard>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center mb-4">
                      No properties available
                    </div>
                  )}
                </div>
              )}
            </TabPanel>
            <TabPanel tabId="uploaded">
              {isLoading ? (
                <Loader color="primary" />
              ) : (
                <div className="container">
                  {data?.myProperties?.meta?.total ? (
                    <div className="row">
                      {data?.myProperties?.data?.map((item) => (
                        <div className="col-md-4 aux-margin" key={item.propertyID}>
                          <span className="misc-date">
                            {formateDate(item.date_created)}
                          </span>
                          <SalesCard
                            {...item}
                            queryCacheParams={queryCacheParams}
                            propertyType="uploaded"
                          >
                            <div
                              style={{ justifyContent: 'space-between' }}
                              className="btn-flex"
                            >
                              <button
                                style={{ borderRadius: '20px' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPropertyAction({
                                    type: 'viewOffers',
                                    propertyId: item.propertyID,
                                  });
                                }}
                                type="button"
                                className="btn btn-outline-danger fs-12 mr-8"
                              >
                                OFFERS
                              </button>
                              <button
                                style={{ borderRadius: '20px' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPropertyAction({
                                    type: 'viewComment',
                                    propertyId: item.propertyID,
                                  });
                                }}
                                type="button"
                                className="btn btn-outline-danger fs-12 "
                              >
                                COMMENTS
                              </button>
                              <button
                                style={{ borderRadius: '20px' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  history.push({
                                    pathname: '/property-advert/subscription',
                                    state: {
                                      propertyId: item.propertyID,
                                    },
                                  });
                                }}
                                type="button"
                                className="btn btn-danger fs-12"
                              >
                                PROMOTE
                              </button>
                            </div>
                          </SalesCard>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center mb-4">
                      No properties available
                    </div>
                  )}
                </div>
              )}
            </TabPanel>
          </div>
        </div>
      </TabProvider>

      <PaymentEvidenceModal
        open={propertyAction.paymentEvidenceModal}
        closeModal={() => setPropertyAction()}
        propertyId={propertyAction.propertyId}
      />
      <ViewComment
        open={propertyAction.commentModal}
        closeModal={() => setPropertyAction()}
        propertyId={propertyAction.propertyId}
      />
      <ViewOffers
        open={propertyAction.offerModal}
        closeModal={() => setPropertyAction()}
        propertyId={propertyAction.propertyId}
      />

      <div className="centralize-div">
        {data?.myProperties?.meta?.total ? (
          <Pagination
            {...{ ...data?.myProperties?.meta }}
            handlePageChange={handlePageChange}
          />
        ) : null}
      </div>
    </div>
  );
}
