import React from 'react';
import Navbar from 'components/Navbar';
import FeaturedListing from 'components/FeaturedListing';
import NewListingsForSale from 'components/NewListingsForSale';
// import DistrictBasedList from 'components/DistrictBasedList';
import Footer from 'components/Footer';
import HomeBadges from 'components/HomeBadges';
import TipsAndGuides from 'components/TipsAndGuides';
// import LocationInfo from 'components/LocationInfo';
import SponsoredAdvert from 'components/SponsoredAdvert';
// import SearchBar from 'components/SearchBar';
import BannerSection from './BannerSection';
import PreApprovalSection from './PreApprovalSection';
// import { Link, useHistory } from 'react-router-dom';
import thumbnailUrl from '../../static/images/video thumbnail.png';
import FeaturedProperties from './FeaturedProperties';
import DiscoverHelp from './DiscoverHelp';
import PreApproval from './PreApproval';
import PartnerSection from './PartnerSection';

const Home = () => {
  return (
    <>
      <Navbar />
      <BannerSection />
      <FeaturedProperties isHome={true} />

      <DiscoverHelp />
      <PreApproval />
      <PartnerSection />

      <Footer />
    </>
  );
};

export default Home;
