import * as React from 'react';
import DatePicker from 'react-datepicker';
import { useAuth } from 'context/auth-context';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'components/common/Button';
import { format } from 'date-fns';

import { useUpdateProfileImage, useUpdateProfileInfo } from './hooks';

import NotificationSettings from './NotificationSettings';

// style for react date picker
import 'react-datepicker/dist/react-datepicker.css';
import { FileInput } from 'components/common/FileInput';
import { Select } from 'components/common/Select';
import useStateList from 'hooks/useStateList';
import useCountryList from 'hooks/useCountryList';

export default function UserProfile() {
  const { user, updateUserDetails } = useAuth();
  const [image, setImage] = React.useState(user.profile_image);
  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const [updateProfileImage, { isLoading }] = useUpdateProfileImage();
  const [updateProfileInfo, { isLoading: updatingProfile }] = useUpdateProfileInfo();

  const methods = useForm({
    defaultValues: {
      ...user,
      date_of_birth: new Date(user.date_of_birth),
      first_name: user.firstName,
      last_name: user.lastName,
      phoneNumber: user.phone_number,
    },
  });

  const countryID = methods.watch('country');
  const { data: countryData } = useCountryList();
  const { data } = useStateList({ countryID });

  const handleProfileImageUpdate = ({ target: { files } }) => {
    const formData = new FormData();
    setImage(URL.createObjectURL(files[0]));
    formData.append('profile_image', files[0], files[0].name);
    formData.append('userID', user.userID);
    updateProfileImage(
      { values: formData, isFormData: true },
      {
        onSuccess: (data) => {
          updateUserDetails(data.userInfo);
        },
      }
    );
  };

  const handleProfileUpdate = (values) => {
    const { valid_id_card, utility_bill, date_of_birth, ...rest } = values;
    const formData = new FormData();
    if (utility_bill.length) {
      formData.append('utility_bill', Array.from(utility_bill)[0]);
    }
    if (valid_id_card.length) {
      formData.append('valid_id_card', Array.from(valid_id_card)[0]);
    }
    formData.append('userID', user.userID);
    formData.append('date_of_birth', format(date_of_birth, 'yyyy-MM-dd'));

    Object.keys(rest).forEach((key) => {
      formData.append(key, values[key]);
    });

    updateProfileInfo(formData, {
      onSuccess: (data) => {
        updateUserDetails(data.userInfo);
        toggleModal();
      },
    });
  };

  return (
    <div className="userdashboard-tabcontent">
      <div className="myuserprofilecard">
        <h1 className="myuserprofile-header">MY PROFILE</h1>
        <div className="row">
          <div className="col-md-6">
            <div className="userdetailscard">
              <div className="userdetailscard-img">
                <img
                  src={`${
                    image ||
                    'https://res.cloudinary.com/www-nairaproducts-ng/image/upload/v1610463126/img_s6iaru.png'
                  }`}
                  alt="user profile"
                />
                {isLoading ? (
                  <Loader color="primary" className="image-loader" />
                ) : null}
                <div className="image-upload">
                  <label htmlFor="file-input">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9931 17.5557C15.9567 17.5557 17.5486 15.9638 17.5486 14.0001C17.5486 12.0365 15.9567 10.4446 13.9931 10.4446C12.0294 10.4446 10.4375 12.0365 10.4375 14.0001C10.4375 15.9638 12.0294 17.5557 13.9931 17.5557Z"
                        fill="white"
                      />
                      <path
                        d="M10.6704 2.88892L8.63702 5.11114H5.1148C3.89258 5.11114 2.89258 6.11114 2.89258 7.33336V20.6667C2.89258 21.8889 3.89258 22.8889 5.1148 22.8889H22.8926C24.1148 22.8889 25.1148 21.8889 25.1148 20.6667V7.33336C25.1148 6.11114 24.1148 5.11114 22.8926 5.11114H19.3704L17.337 2.88892H10.6704ZM14.0037 19.5556C10.937 19.5556 8.44813 17.0667 8.44813 14C8.44813 10.9334 10.937 8.44447 14.0037 8.44447C17.0704 8.44447 19.5592 10.9334 19.5592 14C19.5592 17.0667 17.0704 19.5556 14.0037 19.5556Z"
                        fill="white"
                      />
                    </svg>
                  </label>

                  <input
                    id="file-input"
                    type="file"
                    name="profile_image"
                    onChange={handleProfileImageUpdate}
                  />
                </div>
              </div>
              <div className="userdetailscard-desc">
                <div className="userdetailscard-name">
                  <h1>Unique Id</h1>
                  <span style={{ fontWeight: 'bold' }}>{user.uniqueCode}</span>
                </div>
                <div className="userdetailscard-name">
                  <h1>
                    {user.firstName} {user.lastName}
                  </h1>
                  <span>Client</span>
                </div>
                <div className="userdetailscard-kyc">
                  <span>{user.email}</span>
                  <span>{user.phone}</span>
                  <span>{user.address || ''}</span>
                  <button
                    className="btn btn-danger br-0"
                    type="button"
                    onClick={toggleModal}
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-1" /> */}
          <div className="col-md-6">
            <NotificationSettings
              notificationSettings={user.notificationSettings}
              userID={user.userID}
              updateUserDetails={updateUserDetails}
            />
          </div>
        </div>
      </div>
      <Modal open={showModal} closeModal={toggleModal} title="Edit Profile">
        <Form methods={methods} onSubmit={methods.handleSubmit(handleProfileUpdate)}>
          <div className="row">
            <Input
              label="FIRST NAME"
              containerClassName="col-md-6 mb-3"
              className="form-control br-0"
              id="first_name"
              name="first_name"
              validation={{ required: true }}
            />

            <Input
              label="LAST NAME"
              containerClassName="col-md-6 mb-3"
              className="form-control br-0"
              id="last_name"
              name="last_name"
              validation={{ required: true }}
            />
          </div>
          <div className="row">
            <Input
              label="YOUR EMAIL ADDRESS"
              containerClassName="col-md-6 mb-3"
              className="form-control br-0"
              id="email"
              name="email"
              disabled
              validation={{ required: true }}
            />

            <Input
              label="YOUR PHONE NUMBER"
              containerClassName="col-md-6 mb-3"
              className="form-control br-0"
              id="phoneNumber"
              name="phoneNumber"
              validation={{ required: true }}
            />
          </div>
          <div className="row">
            <Input
              label="ALT PHONE NUMBER"
              containerClassName="col-md-6 mb-3"
              className="form-control br-0"
              name="alt_phoneNumber"
            />

            <Select
              containerClassName="col-md-6"
              className="form-select br-0"
              label="RELIGION"
              options={[
                { id: 'christian', name: 'CHRISTIAN' },
                { id: 'muslim', name: 'MUSLIM' },
              ]}
              name="religion"
              validation={{ required: true }}
            />
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <h3
                style={{
                  fontSize: '1rem',
                  color: '#212529',
                  fontWeight: '400',
                  marginBottom: '.9rem',
                }}
              >
                DATE OF BIRTH
              </h3>
              <Controller
                name="date_of_birth"
                control={methods.control}
                render={({ onChange, ...rest }) => (
                  <DatePicker
                    selected={rest.value}
                    onChange={(date) => {
                      onChange(date);
                    }}
                  />
                )}
              />
            </div>

            <Select
              containerClassName="col-md-6"
              className="form-select br-0"
              label="GENDER"
              options={[
                { id: 'male', name: 'MALE' },
                { id: 'femaile', name: 'Female' },
              ]}
              name="gender"
              validation={{ required: true }}
            />
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <Select
                containerClassName="col-md-6"
                className="form-select br-0"
                label="COUNTRY"
                options={countryData?.countries}
                name="country"
                validation={{ required: true }}
              />
            </div>

            <div className="col-md-6 mb-3">
              <Select
                containerClassName="col-md-6"
                className="form-select br-0"
                label="STATE"
                options={data?.states}
                name="state"
                validation={{ required: true }}
              />
            </div>
          </div>
          <div className="row">
            <Input
              label="YOUR ADDRESS"
              containerClassName="col-md-12 mb-3"
              className="form-control br-0"
              id="address"
              name="address"
              validation={{ required: true }}
            />
          </div>
          <div className="row">
            <FileInput
              label="UPLOAD VALID ID"
              description="Click to upload valid id"
              name="valid_id_card"
              validation={{ required: !user?.valid_id_link }}
              preview={[user?.valid_id_link]}
            />
          </div>
          <div className="row">
            <FileInput
              label="UPLOAD UTILITY BILL"
              description="Click to upload utility bill"
              name="utility_bill"
              validation={{ required: !user?.utility_bill_link }}
              preview={[user?.utility_bill_link]}
            />
          </div>
          <div className="justify-between mt-4">
            <div className="flex">
              <Button
                type="button"
                className="btn br-0 mb-3 fs-14 btn-outline-default"
                onClick={toggleModal}
                disabled={updatingProfile}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                isLoading={updatingProfile}
                className="ml-2 btn btn-danger app-bg-red br-0 white mb-3 fs-14"
              >
                SAVE CHANGES
              </Button>
            </div>
            <div>
              <Button
                type="button"
                className="btn br-0 mb-3 color6 fs-14"
                disabled={updatingProfile}
              >
                Delete my account
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
