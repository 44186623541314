import React from 'react';
import PropTypes from 'prop-types';

// components
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Comment from 'components/Comment';
import { Button } from 'components/common/Button';
import Form from 'components/common/Form';
import { TextField } from 'components/common/TextField';

// hooks
import { useForm } from 'react-hook-form';
import { formatDistance } from 'date-fns';
import { useFetchPropertyComments } from 'hooks/useFetchPropertyComments';
import { useMakeComment } from './hooks';

import LoginForm from '../Login/LoginForm';

export default function PropertyComment({ auth, propertyId, open, closeModal }) {
  const [makeComment, { isLoading }] = useMakeComment();
  const { data, isLoading: loadingComments } = useFetchPropertyComments({
    property_id: propertyId,
  });

  const methods = useForm({
    defaultValues: {
      comment: '',
    },
  });

  const handleSubmit = (values) => {
    makeComment(
      { ...values, property_id: propertyId },
      {
        onSuccess: () => {
          methods.reset();
        },
      }
    );
  };

  return (
    <Modal
      open={open}
      title={!auth.isAuthenticated ? 'Please Login to Comment' : 'Comments'}
      closeModal={closeModal}
    >
      <>
        {auth.isAuthenticated ? (
          <>
            {loadingComments ? (
              <Loader color="primary" />
            ) : (
              <>
                {data?.propertyComments?.map((commentData) => (
                  <Comment
                    imageUrl="https://react.semantic-ui.com/images/avatar/small/stevie.jpg"
                    name={commentData?.offer_by?.name}
                    date={`${formatDistance(
                      new Date(commentData.date),
                      new Date()
                    )} ago`}
                    text={commentData?.comment}
                  />
                ))}

                <Form onSubmit={handleSubmit} methods={methods}>
                  <div className="form-group">
                    <label htmlFor="comment">Comment</label>
                    <TextField
                      placeholder="comment"
                      className="form-control"
                      id="comment"
                      name="comment"
                      rows="3"
                      ref={methods.register({ required: true })}
                    />
                  </div>
                  <p />
                  <Button
                    className="btn btn-danger br-0"
                    type="submit"
                    disabled={!methods.formState.dirtyFields.comment}
                    isLoading={isLoading}
                  >
                    Comment
                  </Button>
                </Form>
              </>
            )}
          </>
        ) : (
          <LoginForm />
        )}
      </>
    </Modal>
  );
}

PropertyComment.propTypes = {
  propertyId: PropTypes.string.isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
};
