/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import PropTypes from 'prop-types';

// style
import './PriceFilterStyle.css';

const PriceFilter = ({ setPriceParams, searchParams }) => {
  return (
    <div className="pricefilter">
      <h1>Price</h1>
      <div className="pricefilter-content">
        <div className="pricefilter-flex">
          <div className="pricefilter-flex_item">
            <label htmlFor="e" className="form-label pricefilter-label">
              Min Price
            </label>
            <input
              type="number"
              className="form-control form-control br-0"
              id="min_price"
              value={searchParams.min_price}
              onChange={({ target }) =>
                setPriceParams(() => ({ ...searchParams, min_price: target.value }))
              }
              placeholder=""
            />
          </div>
          <div className="pricefilter-flex_item">
            <label htmlFor="e" className="form-label pricefilter-label">
              Max Price
            </label>
            <input
              type="number"
              className="form-control form-control br-0"
              id="max_price"
              onChange={({ target }) =>
                setPriceParams(() => ({ ...searchParams, max_price: target.value }))
              }
              value={searchParams.max_price}
              placeholder=""
            />
          </div>
        </div>
        <div className="pricefilter-flex">
          <div className="pricefilter-btn_container">
            <label htmlFor="" className="form-label pricefilter-label">
              Bedroom
            </label>
            <div className="pricefilter-btns">
              <button
                className="btn btn-pricefilter"
                type="button"
                onClick={() =>
                  setPriceParams((prevParams) => ({
                    ...searchParams,
                    no_bedroom: prevParams.no_bedroom + 1,
                  }))
                }
              >
                +
              </button>
              <div className="pricefilter-count">{searchParams.no_bedroom}</div>
              <button
                className="btn btn-pricefilter"
                type="button"
                onClick={() =>
                  setPriceParams((prevParams) => ({
                    ...searchParams,
                    no_bedroom: prevParams.no_bedroom - 1,
                  }))
                }
              >
                -
              </button>
            </div>
          </div>
          <div className="pricefilter-btn_container">
            <label htmlFor="" className="form-label pricefilter-label">
              Bathroom
            </label>
            <div className="pricefilter-btns">
              <button
                className="btn btn-pricefilter"
                type="button"
                onClick={() =>
                  setPriceParams((prevParams) => ({
                    ...searchParams,
                    no_bathroom: prevParams.no_bathroom + 1,
                  }))
                }
              >
                +
              </button>
              <div className="pricefilter-count">{searchParams.no_bathroom}</div>
              <button
                className="btn btn-pricefilter"
                type="button"
                onClick={() =>
                  setPriceParams((prevParams) => ({
                    ...searchParams,
                    no_bathroom: prevParams.no_bathroom - 1,
                  }))
                }
              >
                -
              </button>
            </div>
          </div>
        </div>
        <div className="pricefilter-flex">
          <div className="pricefilter-btn_container">
            <label htmlFor="" className="form-label pricefilter-label">
              Toilet
            </label>
            <div className="pricefilter-btns">
              <button
                className="btn btn-pricefilter"
                type="button"
                onClick={() =>
                  setPriceParams((prevParams) => ({
                    ...searchParams,
                    no_toilet: prevParams.no_toilet + 1,
                  }))
                }
              >
                +
              </button>
              <div className="pricefilter-count">{searchParams.no_toilet}</div>
              <button
                className="btn btn-pricefilter"
                type="button"
                onClick={() =>
                  setPriceParams((prevParams) => ({
                    ...searchParams,
                    no_toilet: prevParams.no_toilet - 1,
                  }))
                }
              >
                -
              </button>
            </div>
          </div>
          <div className="pricefilter-btn_container">
            <label htmlFor="" className="form-label pricefilter-label">
              Car Parking Space
            </label>
            <div className="pricefilter-btns">
              <button className="btn btn-pricefilter" type="button">
                +
              </button>
              <div className="pricefilter-count">{searchParams.no_car_parking}</div>
              <button className="btn btn-pricefilter" type="button">
                -
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PriceFilter.propTypes = {
  setPriceParams: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    min_price: PropTypes.number,
    max_price: PropTypes.number,
    no_bedroom: PropTypes.number,
    no_bathroom: PropTypes.number,
    no_toilet: PropTypes.number,
    no_car_parking: PropTypes.number,
  }).isRequired,
};

export default PriceFilter;
