import React from 'react';
import './FeedbackCard.css';

const FeedbackCard = ({ title, feedback }) => {
  return (
    <div className="feedback-card">
      <h3 className="feedback-title">{title}</h3>
      <p className="feedback-text">{feedback}</p>
    </div>
  );
};

export default FeedbackCard;
