import React from 'react';
import PropTypes from 'prop-types';

export default function PaymentTypeButton(props) {
  const { isActive, logo, name, onClick } = props;
  console.log(isActive, name);
  return (
    <button
      type="button"
      className={`paymentcard ${isActive ? 'selected-gateway' : ''}`}
      onClick={onClick}
    >
      <div className="paymentcard-content">
        <img src={logo} alt="paystack logo" />
        <span>{name}</span>
      </div>
      <div className="selected-check-mark">
        <svg
          width="12"
          height="9"
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6654 1.5L4.2487 7.91667L1.33203 5"
            stroke="#cc5500"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </button>
  );
}

PaymentTypeButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  logo: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
