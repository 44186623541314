import * as React from 'react';

// components
import Navbar from 'components/Navbar';
// import SecondaryBanner from 'components/SecondaryBanner';
import Footer from 'components/Footer';
import TipsCard from 'components/TipsCard';
import Pagination from 'components/Pagination';
import Loader from 'components/Loader';
// import SearchBar from 'components/SearchBar';
import TipsAndGuideBanner from 'components/TipsAndGuideBanner';

// hooks
import { useTipsAndGuides } from 'hooks/useTipsAndGuides';
import usePageBanner from 'hooks/usePageBanner';

// style
import './TipsAndGuidesPageStyle.css';

const TipsAndGuidesPage = () => {
  const [page, handlePageChange] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const { pageData } = usePageBanner('getTipsNGuidesPageBanner');

  const { resolvedData, isLoading } = useTipsAndGuides({ page, query: searchValue });

  const total = resolvedData?.tips_and_guides?.meta?.total;
  const from = resolvedData?.tips_and_guides?.meta?.from;
  const to = resolvedData?.tips_and_guides?.meta?.to;

  return (
    <section className="tipsandguidespage">
      <Navbar />
      <TipsAndGuideBanner />

      {/* <SecondaryBanner
        title="tips & guides"
        searchComponent={<SearchBar handleSearch={setSearchValue} />}
        banner={pageData?.banner?.primary_image}
      /> */}

      <div className="container">
        <h1 className="tipsandguidespage-header">
          LATEST TIPS & GUIDE ON YOUR ENVIRONMENT
        </h1>
        <span className="tipsandguidespage-span">
          {isLoading ? (
            'Loading...'
          ) : (
            <span>
              Displaying {from} - {to} results of {total}
            </span>
          )}
        </span>
        {isLoading ? (
          <Loader color="primary" />
        ) : (
          <div className="row">
            {resolvedData?.tips_and_guides?.data?.map((item) => (
              <div className="col-md-4">
                <TipsCard {...item} />
              </div>
            ))}
          </div>
        )}
        <div className="centralize-div">
          <Pagination
            {...{ ...resolvedData?.tips_and_guides?.meta }}
            handlePageChange={({ selected }) => handlePageChange(selected)}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default TipsAndGuidesPage;
