import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

export const Radio = ({
  id,
  label,
  name,
  value,
  placeholder,
  containerClassName,
  labelClassName,
  validation,
  ...rest
}) => {
  const { register, errors } = useFormContext() || {};
  return (
    <div className={containerClassName}>
      <span style={{ color: '#dc3545' }}>
        {errors && <ErrorMessage name={name} errors={errors} />}
      </span>
      <label htmlFor={id} className={`${labelClassName || 'form-label'}`}>
        {label}
      </label>
      <input
        ref={register?.({ ...validation }) || null}
        id={id}
        placeholder={placeholder}
        name={name}
        {...rest}
        type="radio"
        className="form-check-input"
      />
    </div>
  );
};

Radio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  validation: PropTypes.shape({}),
  labelClassName: PropTypes.string,
};

Radio.defaultProps = {
  id: '',
  label: '',
  value: '',
  placeholder: '',
  containerClassName: '',
  labelClassName: '',
  validation: {},
};
