/* eslint-disable camelcase */
import * as React from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';

// components
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import { Button } from 'components/common/Button';
import FlutterwaveLogo from '../../static/images/flutterwave.svg';
import PaystackLogo from '../../static/images/paystack.svg';
import PaymentTypeButton from './PaymentTypeButton';
import { useInitializePayment } from './hooks';

// style
import './PaymentMethodStyle.css';

const paymentTypeOptions = [
  {
    name: 'Paystack',
    logo: PaystackLogo,
    payment_gateway: 'paystack',
  },
  {
    name: 'Flutterwave',
    logo: FlutterwaveLogo,
    payment_gateway: 'flutterwave',
  },
  // ,
  // {
  //   name: 'Stripe',
  //   logo: StripeLogo,
  //   payment_gateway: 'strip',
  // },
];

const PaymentMethodPage = () => {
  const [paymentGateway, setPaymentGateWay] = React.useState('');
  const { state } = useLocation();
  const [initializePayment, { isLoading, error, status }] = useInitializePayment(
    state?.paymentData?.paymentPath
  );

  const handleInitializePayment = () => {
    initializePayment(
      {
        ...state?.paymentData,
        payment_gateway: paymentGateway,
        currency: 'NGN',
      },
      {
        onSuccess: (data) => {
          window.location.href = data.paymentUrl;
        },
      }
    );
  };

  return (
    <div className="proformpage">
      <Navbar />
      <div className="container proformpage-card">
        <div className="breadcrumb-wrapper">
          <NavLink to="/services">Home service</NavLink>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/join_as_pro">Join as a professional</Link>
          <span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke="black"
                strokeOpacity="0.4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/join_as_pro/subscription/payment">Select payment gateway</Link>
        </div>
        <h1 className="submitlistingpage-header">Select Payment Method</h1>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <p className="proformpage-subheader">
              Our home affordability payments calculator can help you estimate what
              you can afford to spend on your new home.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6">
            <div className="paymentmethodcard">
              {error && (
                <div
                  className="alert alert-danger mb-2 mt-4"
                  style={{ textAlign: 'center' }}
                  role="alert"
                >
                  {status === 500
                    ? 'Server Error, please try again'
                    : error?.message}
                </div>
              )}
              <div className="paymentmethodcard-content">
                <div className="paymentmethodcard-flex">
                  {paymentTypeOptions.map(({ name, logo, payment_gateway }) => (
                    <PaymentTypeButton
                      key={name}
                      logo={logo}
                      name={name}
                      onClick={() => setPaymentGateWay(payment_gateway)}
                      isActive={paymentGateway === payment_gateway}
                    />
                  ))}
                </div>
                <p>
                  Our home affordability payments calculator can help you estimate
                  what you can afford to spend on your new home.
                </p>
                <div className="paymentmethodcard-btn">
                  <Button
                    className="btn btn-danger"
                    type="button"
                    onClick={handleInitializePayment}
                    isLoading={isLoading}
                    disabled={isLoading || !paymentGateway}
                  >
                    CONTINUE
                    <span>
                      <svg
                        width="25"
                        height="12"
                        viewBox="0 0 25 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.5303 6.53033C24.8232 6.23744 24.8232 5.76256 24.5303 5.46967L19.7574 0.696699C19.4645 0.403806 18.9896 0.403806 18.6967 0.696699C18.4038 0.989593 18.4038 1.46447 18.6967 1.75736L22.9393 6L18.6967 10.2426C18.4038 10.5355 18.4038 11.0104 18.6967 11.3033C18.9896 11.5962 19.4645 11.5962 19.7574 11.3033L24.5303 6.53033ZM0 6.75H24V5.25H0V6.75Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentMethodPage;
