import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'context/auth-context';
import { LoadingIcon } from 'static';

export default function SocialAuthCallback() {
  const { search, pathname } = useLocation();
  const auth = useAuth();

  const { socialSignInInfo, socialLogin } = auth;
  const provider = pathname.split('/')[2];

  const code = new URLSearchParams(search).get('code');
  const state = new URLSearchParams(search).get('state');
  const pattern = /login-redirect.*/;
  const redirectAfterLogin = state?.match(pattern)?.[0]?.split('=')[1];

  React.useEffect(() => {
    if (code) {
      socialLogin({
        provider,
        code,
        redirectAfterLogin,
      });
    }
  }, [provider, socialLogin, code, redirectAfterLogin]);

  if (socialSignInInfo.isLoading) {
    return (
      <div className="confirm-account-page">
        <LoadingIcon />
      </div>
    );
  }

  if (socialSignInInfo.error) {
    return (
      <div className="container text-center">Problem signing with {provider}</div>
    );
  }
  return null;
}
