import React from 'react';
import gtag from 'ga-gtag';
// import { Link } from 'react-router-dom';

// components
import Navbar from 'components/Navbar';
import SecondaryBanner from 'components/SecondaryBanner';
import Footer from 'components/Footer';
import { useAuth } from 'context/auth-context';
import LoginForm from '../Login/LoginForm';
import SellForm from './SellForm';

// style
// import './SellPageStyle.css';

const SellPage = () => {
  const {
    isAuthenticated,
    //  provider
  } = useAuth();

  const trackSellPage = () => {
    gtag('event', 'sell_page', {
      method: 'sell_page',
    });
  };

  React.useEffect(() => {
    trackSellPage();
  }, []);

  return (
    <section className="sellpage">
      <Navbar />
      <SecondaryBanner title="sell" />
      <div className="container">
        <div className="row justify-content-center">
          <div className={`col-md-${isAuthenticated ? '8' : '5'}`}>
            <SellForm className="mt-3" />
          </div>
          {!isAuthenticated && (
            <>
              <div className="col-md-2" />
              <div className="col-md-5">
                <div className="sellpage-form mt-4">
                  <div className="register-container">
                    <p className="sellpage-intro">
                      If you would like to discuss the sale of your property, please
                      login to your account
                    </p>
                    <LoginForm />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default SellPage;
