import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import useSearchProperties from 'hooks/useSearchProperties';
import gtag from 'ga-gtag';

// components
import Navbar from 'components/Navbar';
import SecondaryBanner from 'components/SecondaryBanner';
// import PropertyCard from 'components/PropertyCard';
import CategoryFilter from 'components/CategoryFilter';
import PriceFilter from 'components/PriceFilter';
import SalesCard from 'components/SalesCard';
import Loader from 'components/Loader';
import Footer from 'components/Footer';

// style
import './BuyPageStyle.css';
import Pagination from 'components/Pagination';
import usePageBanner from 'hooks/usePageBanner';
import FilterProperties from 'pages/Home/FilterProperties';
import getPaginationStats from 'utils/getPaginationStats';
import StateFilter from '../../components/StateFilter/index';

const tabPanelIds = ['most_recent', 'highest_price', 'lowest_price'];

const BuyPage = () => {
  const [page, setPage] = React.useState(1);
  const { state } = useLocation();
  // eslint-disable-next-line camelcase
  const current_page = useRouteMatch('/properties/buy')
    ? 'getBuyPageBanner'
    : 'getRentPageBanner';
  const { data: pageData } = usePageBanner(current_page);

  const [searchParams, setParams] = React.useState({
    property_type_id: undefined,
    property_category_id: undefined,
    state_id: undefined,
    min_price: 1000,
    max_price: 1000000000,
    no_bedroom: 1,
    no_bathroom: 1,
    no_toilet: 1,
    no_car_parking: 1,
    page_type: 'most_recent',
    ...state?.search,
  });

  const transactionType = useRouteMatch('/properties/buy') ? 'BUY' : 'RENT';

  const { data, isLoading } = useSearchProperties({
    page,
    transaction_type: transactionType,
    ...searchParams,
    property_type_id: searchParams.property_type_id,
  });

  const { to, from, total } = getPaginationStats(data?.properties?.meta);

  const trackBuyPage = () => {
    gtag('event', 'buy_page', {
      method: 'buy_page',
    });
  };

  React.useEffect(() => {
    trackBuyPage();
  }, []);

  return (
    <section className="buypage">
      <Navbar />
      <SecondaryBanner title={transactionType} banner={pageData?.banner}>
        <FilterProperties
          onSearch={(filterValues) =>
            setParams({ ...searchParams, ...filterValues })
          }
        />
      </SecondaryBanner>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 pr-0 pl-0">
            <div className="row mr-0 ml-0">
              <div className="col-md-6">
                <div className="buypage-header mb-4" />
              </div>
            </div>
            <TabProvider
              defaultTab="most_recent"
              onChange={(pageType) =>
                setParams({ ...searchParams, page_type: pageType })
              }
            >
              <div className="my-tabs">
                <div className="container">
                  <div className="newlistings-flex">
                    <span className="newlistings-subheading">
                      {isLoading ? (
                        'Loading...'
                      ) : (
                        <span>
                          {total ? (
                            <>
                              Displaying {from} - {to} results of {total}
                            </>
                          ) : null}
                        </span>
                      )}
                    </span>
                    <div className="newlistings-tabs">
                      <TabList className="tablist">
                        <Tab tabFor="most_recent" className="tab-btn">
                          Most Recent
                        </Tab>
                        <Tab tabFor="highest_price" className="tab-btn">
                          Highest Price
                        </Tab>
                        <Tab tabFor="lowest_price" className="tab-btn">
                          Lowest Price
                        </Tab>
                      </TabList>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  {tabPanelIds.map((tabId) => (
                    <TabPanel tabId={tabId} key={tabId}>
                      {isLoading ? (
                        <Loader color="primary" />
                      ) : (
                        <div className="container">
                          {data?.properties?.meta?.total ? (
                            <div className="row">
                              {data?.properties?.data?.map((item) => (
                                <div className="col-md-6" key={item.propertyID}>
                                  <SalesCard {...item} />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center">
                              No Properties available
                            </div>
                          )}
                        </div>
                      )}
                    </TabPanel>
                  ))}
                </div>
              </div>
            </TabProvider>
            <div className="centralize-div">
              <Pagination
                {...{ ...data?.properties?.meta }}
                handlePageChange={() => setPage((prev) => prev + 1)}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 relative">
            <div className="card-container">
              <CategoryFilter
                setPropertyType={(propertyCategory) =>
                  setParams({
                    ...searchParams,
                    property_category_id: propertyCategory,
                  })
                }
              />
              <StateFilter
                setStateType={(stateList) =>
                  setParams({ ...searchParams, state_id: stateList })
                }
              />
              <PriceFilter setPriceParams={setParams} searchParams={searchParams} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default BuyPage;
