import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';

import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { useAuth } from 'context/auth-context';
import { ReactComponent as LoadingIcon } from 'static/images/oval.svg';
import SocialAuth from 'components/SocialAuth';

// eslint-disable-next-line react/prop-types
const LoginForm = ({ redirectPath }) => {
  const methods = useForm({ defaultValues: {} });
  const { login, loginInfo, setRedirectAfterLogin } = useAuth();
  const { error, isLoading } = loginInfo;

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname !== '/login') {
      setRedirectAfterLogin(redirectPath || pathname);
    }
  }, [pathname, setRedirectAfterLogin, redirectPath]);

  return (
    <Form className="form-fields" onSubmit={login} methods={methods}>
      {error && (
        <div
          className="alert alert-danger mb-2"
          style={{ textAlign: 'center' }}
          role="alert"
        >
          Invalid email or password
        </div>
      )}
      <div>
        <SocialAuth />
      </div>
      <p className="secondary-intro">or sign in with</p>
      <p />
      <Input
        id="fullname"
        name="email"
        label="YOUR EMAIL ADDRESS"
        containerClassName="mb-3"
        className="form-control form-control-lg br-0"
        placeholder=""
        validation={{ required: 'This is required.' }}
      />
      <Input
        id="password"
        name="password"
        label="YOUR PASSWORD"
        containerClassName="mb-3"
        className="form-control form-control-lg br-0"
        type="password"
        placeholder=""
        validation={{ required: 'This is required.' }}
      />
      <p className="forgot-password-link">
        <Link to="/forgot-password">FORGOT PASSWORD?</Link>
      </p>

      <button
        type="submit"
        disabled={isLoading}
        to="/dashboard"
        className="btn btn-block btn-danger br-2 btn-lg btn-create-account"
      >
        {isLoading ? <LoadingIcon style={{ height: '30px' }} /> : 'SIGN IN'}
      </button>
      <p className="already-have-an-account">
        DON&#x27;T HAVE AN ACCOUNT?{' '}
        <span>
          <Link to="/register">SIGN UP</Link>
        </span>
      </p>
    </Form>
  );
};

export default LoginForm;
