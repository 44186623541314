import { useMutation, useQuery } from 'react-query';
import { client } from 'utils/api-client';

export function useMyPropertyList(queryCacheParams) {
  return useQuery(queryCacheParams, (_, fieldParams) =>
    client('profile/myProperties', { params: fieldParams }).then((res) => res)
  );
}

export function useUpdateProfileImage() {
  return useMutation(({ values, isFormData }) =>
    client('profile/changeImage', {
      data: values,
      isFormData,
    }).then((res) => res)
  );
}

export function useUpdateProfileInfo() {
  return useMutation((values) =>
    client('profile/modifyInfo', {
      data: values,
    }).then((res) => res)
  );
}

export function usePurchasedProperties() {
  return useMutation((values) =>
    client('profile/changeImage', {
      data: values,
      isFormData: true,
    }).then((res) => res)
  );
}
