import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function useNigerianState(params) {
  return useQuery(
    ['state-list', params],
    (_, fieldParams) =>
      client(`params/getState`, { params: fieldParams }).then((res) => res),
    {
      enabled: params.countryID,
    }
  );
}
