/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
// import Logo from 'static/images/logo.jpeg';
import OldBricksLogo from 'static/images/logo.jpg';
import { useAuth } from 'context/auth-context';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) return <Redirect to="/dashboard" />;

  return (
    <section className="auth">
      <div className="container">
        <div className="row">
          <div className="col-md-4" />
          <div className="col-md-4">
            <div className="register-container">
              <div className="app-logo">
                <Link to="/">
                  <img className="logo-image" src={OldBricksLogo} alt="App Logo" />
                </Link>
              </div>
              <p className="register-intro">
                Kindly enter your email address to retrieve your password.
              </p>

              <ForgotPasswordForm />
              <p className="already-have-an-account">
                DON'T HAVE AN ACCOUNT?{' '}
                <span>
                  <Link to="/register">SIGN UP</Link>
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-4" />
        </div>
      </div>
    </section>
  );
};
export default ForgotPassword;
