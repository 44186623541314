import { useMutation } from 'react-query';
import { client } from 'utils/api-client';

export function useUploadPaymentEvidence() {
  return useMutation((values) =>
    client('payments/savePropertyPaymentEvidence', { data: values }).then(
      (res) => res
    )
  );
}
