import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { ReactComponent as LoadingIcon } from 'static/images/oval.svg';
import useForgotPassword from 'hooks/useForgotPassword';


const ForgotPasswordForm = (props) => {
  const methods = useForm({ defaultValues: {} });
  const [forgotPassword, { isLoading, error }] = useForgotPassword();

  const handleSubmit = (values) => {
    forgotPassword(values, {
      onSuccess: (data) => {
        toast.success(`${data?.Detail}`, {
          style: {
            background: '#d4edda',
            color: '#155724',
          },
        });
      },
    });
  };

  return (
    <Form className="form-fields" onSubmit={handleSubmit} methods={methods}>
      {error && (
        <div
          className="alert alert-danger mb-2"
          style={{ textAlign: 'center' }}
          role="alert"
        >
          Reset password request failed, please try again
        </div>
      )}
      <Input
        id="fullname"
        name="email"
        label="YOUR EMAIL ADDRESS"
        containerClassName="mb-3"
        className="form-control form-control-lg br-0"
        placeholder=""
        validation={{ required: 'This is required.' }}
      />

      <p className="forgot-password-link">
        <Link to="/login">Already have an account ?</Link>
      </p>

      <button
        type="submit"
        disabled={isLoading}
        to="/dashboard"
        className="btn btn-block btn-danger br-2 btn-lg btn-create-account"
      >
        {isLoading ? <LoadingIcon style={{ height: '30px' }} /> : 'FORGOT PASSWORD'}
      </button>
      {props.children}
    </Form>
  );
};

export default ForgotPasswordForm;
