import React from 'react';
import './PartnerSection.css';

const PartnerSection = () => {
  return (
    <div className="partner-section">
      <h2 className="header">OUR PARTNERS</h2>
      <p className="description">
        We partner with all major stakeholders in a drive to solve the housing
        problem of Nigeria. This is why we are a giant in the business.
      </p>
      <div className="partner-logos">
        <img src="/assets/federal-morgage.jpg" alt="Federal morgage" />
        <img src="/assets/federal-housing.jpg" alt="Fderal housing" />
        <img src="/assets/aso.jpg" alt="Aso" />
      </div>
    </div>
  );
};

export default PartnerSection;
