import React from 'react';
import { Link } from 'react-router-dom';
import Star from '../../static/images/star.svg';
import Telephone from '../../static/images/telephone.svg';
import Message from '../../static/images/message.svg';
import Website from '../../static/images/website.svg';

// style
import './ProfessionalBannerStyle.css';

const ProfessionalBanner = ({ service }) => {
  return (
    <div className="professionalbanner">
      <img
        src="https://images.unsplash.com/photo-1489410342162-b4b300a0bb15?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
        alt="banner"
      />
      <div className="secondarybanner-overlay">
        <div className="container">
          <div className="justify-between professionalbanner-desc">
            <div className="professionalbanner-details">
              <h1>{service?.owner?.name}</h1>
              <h3>{service?.service_title}</h3>
              <div className="professionalbanner-ratings">
                <span>5.0</span>
                <span className="ml-1">
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                </span>
                <span className="ml-1">150 reviews</span>
              </div>
              <p className="white fs-14 mt-1">
                <span className="mr-2">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.86627 0.492188C4.99133 0.492188 2.65234 2.83118 2.65234 5.70611C2.65234 9.31961 7.8714 15.4922 7.8714 15.4922C7.8714 15.4922 13.0802 9.14191 13.0802 5.70611C13.0802 2.83118 10.7413 0.492188 7.86627 0.492188ZM9.43942 7.23276C9.00565 7.66644 8.436 7.88333 7.86627 7.88333C7.29663 7.88333 6.72681 7.66644 6.29321 7.23276C5.42575 6.36539 5.42575 4.95401 6.29321 4.08655C6.71326 3.66632 7.272 3.43488 7.86627 3.43488C8.46054 3.43488 9.0192 3.66641 9.43942 4.08655C10.3069 4.95401 10.3069 6.36539 9.43942 7.23276Z"
                      fill="white"
                    />
                  </svg>
                </span>
                {service?.business_address}
              </p>
            </div>
            <div className="professionalbanner-contact">
              <p className="white">
                <img className="mr-2" src={Telephone} alt="Telephone Icon" />
                <Link className="white underline-none " to="/">
                  Click to call
                </Link>
              </p>
              <p className="white">
                <img className="mr-2" src={Message} alt="Message Icon" />
                <Link className="white underline-none " to="/">
                  Send Message
                </Link>
              </p>
              <p className="white">
                <img className="mr-2" src={Website} alt="Website Icon" />
                <Link className="white underline-none " to="/">
                  Visit Website
                </Link>
              </p>
            </div>
            <div>s</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalBanner;
