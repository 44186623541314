import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { TextField } from 'components/common/TextField';
import { Button } from 'components/common/Button';
import { toast } from 'react-toastify';
import StarRating from './StarRating';
import { useSubmitReview } from './hooks/useSubmitReview';

export default function RateProfessionalForm(props) {
  const { homeServiceId } = props;

  const methods = useForm({
    defaultValues: {
      market_expert: 0,
      responsiveness: 0,
      negotiation_skill: 0,
      professionalism_communication: 0,
      home_service_id: homeServiceId,
    },
  });

  const [submitReview, { isLoading }] = useSubmitReview();

  const onSubmit = (values) => {
    submitReview(values, {
      onSuccess: () => {
        toast.success('Your review has been submitted', {
          style: {
            background: '#d4edda',
            color: '#155724',
          },
        });
        methods.reset({});
      },
    });
  };

  return (
    <div className="container">
      <Form className="mt-4" methods={methods} onSubmit={onSubmit}>
        <div className="row">
          <Input
            name="home_service_id"
            type="hidden"
            className="form-control form-control-lg br-0 solid-black-2 "
            placeholder="Name"
            validation={{ required: true }}
            defaultValue={homeServiceId}
          />
          <div className="col-md-4">
            <div className="mb-4">
              <Input
                name="reviewer_name"
                className="form-control form-control-lg br-0 solid-black-2 "
                placeholder="Name"
                validation={{ required: true }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <Input
                name="reviewer_email"
                className="form-control form-control-lg br-0 solid-black-2 "
                placeholder="Email"
                validation={{ required: true }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <Input
                name="reviewer_phone_no"
                className="form-control form-control-lg br-0 solid-black-2 "
                placeholder="Phone Number"
                validation={{ required: true }}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4">
            <div className="flex items-center">
              <span>Over Rating</span>
              <div className="ml-4">
                <Controller
                  name="market_expert"
                  control={methods.control}
                  render={({ onChange, value }) => (
                    <StarRating
                      numberOfStar={5}
                      onSelectStar={onChange}
                      numberOfSelectedStar={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="flex items-center">
              <span>Market Expert</span>
              <div className="ml-4">
                <Controller
                  name="market_expert"
                  control={methods.control}
                  render={({ onChange, value }) => (
                    <StarRating
                      numberOfStar={5}
                      onSelectStar={onChange}
                      numberOfSelectedStar={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="flex items-center">
              <span>Reponsiveness</span>
              <div className="ml-4">
                <Controller
                  name="responsiveness"
                  control={methods.control}
                  render={({ onChange, value }) => (
                    <StarRating
                      numberOfStar={5}
                      onSelectStar={onChange}
                      numberOfSelectedStar={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4">
            <div className="flex items-center">
              <span>Negotiation Skills</span>
              <div className="ml-4">
                <Controller
                  name="negotiation_skill"
                  control={methods.control}
                  render={({ onChange, value }) => (
                    <StarRating
                      numberOfStar={5}
                      onSelectStar={onChange}
                      numberOfSelectedStar={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="flex items-center">
              <span>Professionalism & Communication</span>
              <div className="ml-4">
                <Controller
                  name="professionalism_communication"
                  control={methods.control}
                  render={({ onChange, value }) => (
                    <StarRating
                      numberOfStar={5}
                      onSelectStar={onChange}
                      numberOfSelectedStar={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <TextField
            className="form-control br-0 solid-black-2 "
            id="exampleFormControlTextarea1"
            rows="8"
            name="message"
            placeholder="Message"
            validation={{ required: true }}
          />
        </div>
        <Button
          type="submit"
          className="btn btn-lg bg-btn-black br-0"
          isLoading={isLoading}
        >
          SUBMIT REVIEW
        </Button>
      </Form>
    </div>
  );
}
