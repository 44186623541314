import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

// components
import Modal from 'components/Modal';
import { Button } from 'components/common/Button';
import Form from 'components/common/Form';
import { TextField } from 'components/common/TextField';
import { Input } from 'components/common/Input';
import { useForm } from 'react-hook-form';
import LoginForm from '../Login/LoginForm';

// hooks
import { useMakePropertyOffer } from './hooks';




export default function MakeOffer({ auth, propertyId, open, closeModal }) {
  const [makeOffer, { isLoading }] = useMakePropertyOffer();
  const methods = useForm({
    defaultValues: {},
  });

  const handleSubmit = (values) => {
    makeOffer(
      { ...values, property_id: propertyId },
      {
        onSuccess: () => {
          closeModal();
          toast.success('Your offer has been sent to property owner', {
            style: {
              background: '#d4edda',
              color: '#155724',
            },
          });
        },
      }
    );
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={!auth.isAuthenticated ? 'Please Log in to Make Offer' : 'Make Offer'}
    >
      {auth.isAuthenticated ? (
        <Form methods={methods} onSubmit={handleSubmit}>
          <div className="row">
            <Input
              containerClassName="col-md-6 mb-3"
              className="form-control br-0"
              id="name"
              name="name"
              label="Name"
              placeholder="Name"
              validation={{ required: true }}
            />
            <Input
              containerClassName="col-md-6 mb-3"
              className="form-control br-0"
              id="phone no"
              name="phone_no"
              label="Phone No"
              placeholder="Phone No"
              validation={{ required: true }}
            />
          </div>
          <div className="row">
            <Input
              containerClassName="col-md-12 mb-3"
              className="form-control br-0"
              id="amount"
              type="number"
              name="amount"
              label="Amount"
              placeholder="Amount"
              validation={{ required: true }}
            />
          </div>
          <div className="row">
            <TextField
              containerClassName="col-12 mb-3"
              className="form-control br-0"
              label="NOTES"
              id="note"
              rows="3"
              name="note"
              validation={{ required: true }}
            />
          </div>
          <Button
            className="btn btn-danger br-0"
            type="submit"
            isLoading={isLoading}
          >
            Make Offer
          </Button>
        </Form>
      ) : (
        <LoginForm />
      )}
    </Modal>
  );
}

MakeOffer.propTypes = {
  propertyId: PropTypes.string.isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
