import React from 'react';
import gtag from 'ga-gtag';

// components
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import { useAuth } from 'context/auth-context';
import AdvertiseForm from './AdvertiseForm';
import LoginForm from '../Login/LoginForm';
import BannerImage from '../../static/images/advert.png';
import './adverties.css';

const AdvertPage = () => {
  const trackAdvertPage = () => {
    gtag('event', 'advert_page', {
      method: 'advert_page',
    });
  };

  React.useEffect(() => {
    trackAdvertPage();
  }, []);

  const { isAuthenticated } = useAuth();
  return (
    <section className="sellpage">
      <Navbar />
      <div className="advertbanner">
        <img src={BannerImage} alt="banner" />
        <div className="advertbanner-overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <div className="secondarybanner-content pt-5">
                  <h1>ADVERTISE WITH US</h1>
                  <p>
                    When you advertise here, your property gets the right exposures
                    and response.
                  </p>
                </div>
              </div>
              <div className="col-md-2" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className={`col-md-${isAuthenticated ? '8' : '5'}`}>
            <div className="sellpage-desc mt-3">
              <p>
                Invest in your business! Advertise and get access to a large network
                and start connecting with active home shoppers. Fill the form below
                and a contact person will keep in touch.
              </p>
              <br />
              <p>Fill the form below and a contact person will keep in touch.</p>
              <AdvertiseForm />
              <div>
                {/* <Link
                  to="/advert/subscription"
                  type="button"
                  className="btn btn-orange btn-lg br-0"
                >
                  Advertisement Plans
                </Link> */}
              </div>
            </div>
          </div>
          {!isAuthenticated && (
            <>
              <div className="col-md-2" />
              <div className="col-md-5">
                <div className="sellpage-form mt-3">
                  <div className="register-container">
                    <p className="sellpage-intro">
                      If you would like to discuss the sale of your property, please
                      login to your account
                    </p>
                    <LoginForm />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default AdvertPage;
