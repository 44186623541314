import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export function useVerifyPayment(params) {
  return useQuery(['verify-payment', params], (_, fieldParams) =>
    client(`payments/handleGatewayCallback`, { params: fieldParams }).then((res) => {
      return res;
    })
  );
}
