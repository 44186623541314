/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import Navbar from 'components/Navbar';
import DashboardBanner from 'components/DashboardBanner';
import Footer from 'components/Footer';
import { useMyHomeServices } from './hooks';

// Style
import './MyHomeServicesPageStyle.css';

const MyHomeServicesPage = () => {
  const { data } = useMyHomeServices();
  return (
    <section className="userdashboardpage">
      <Navbar />
      <DashboardBanner />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1 className="pt-5 f-32">My Home Services</h1>
          <Link
            to="/join_as_pro"
            className="btn btn-danger br-0 ml-2 add-service-btn"
          >
            Add Home Service
          </Link>
        </div>
        <div className="row my-5">
          {data?.homeServices?.map(({ homeServiceID, image_link, ...rest }) => (
            <div className="col-md-3" key={homeServiceID}>
              <div className="homeservice-card shadow">
                <img src={image_link} alt="card" />
                <div className="agentcard-content">
                  <h3>{rest?.owner.name}</h3>
                  <span>{rest.service_title}</span>
                  <span>{rest.business_phone}</span>
                  <span>{rest.business_email}</span>
                  <div className="justify-between mt-3">
                    <Link
                      to={{
                        pathname: '/join_as_pro/single',
                        state: {
                          service: {
                            homeServiceID,
                            image_link,
                            ...rest,
                          },
                          viewMode: true,
                        },
                      }}
                      className="btn btn-danger app-bg-red white f-14"
                    >
                      VIEW PROFILE
                    </Link>
                    <Link
                      to={{
                        pathname: '/home-service/edit',
                        state: {
                          service: {
                            homeServiceID,
                            image_link,
                            ...rest,
                          },
                        },
                      }}
                      className="btn btn-outline-danger f-14"
                    >
                      EDIT
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default MyHomeServicesPage;
