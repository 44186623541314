import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { ReactComponent as LoadingIcon } from 'static/images/oval.svg';
import useResetPassword from 'hooks/useResetPassword';
import { toast } from 'react-toastify';

const ForgotPasswordForm = (props) => {
  const methods = useForm({ defaultValues: {} });
  const [resetPassword, { isLoading, error }] = useResetPassword();
  const history = useHistory();

  const handleSubmit = (values) => {
    const { userId, email, token } = props;
    const payload = { token, values: { ...values, email, userID: userId } };
    resetPassword(payload, {
      onSuccess: () => {
        toast.success('You have successfully reset your password', {
          style: {
            background: '#d4edda',
            color: '#155724',
          },
        });
        history.push('/login');
      },
    });
  };

  return (
    <Form className="form-fields" onSubmit={handleSubmit} methods={methods}>
      {error && (
        <div
          className="alert alert-danger mb-2"
          style={{ textAlign: 'center' }}
          role="alert"
        >
          Invalid email or password
        </div>
      )}
      <Input
        id="fullname"
        name="password"
        label="NEW PASSWORD"
        containerClassName="mb-3"
        className="form-control form-control-lg br-0"
        placeholder=""
        validation={{
          required: true,
          minLength: { value: 6, message: 'Password is too short' },
        }}
      />
      <Input
        id="fullname"
        name="password_confirmation"
        label="CONFIRM PASSWORD"
        containerClassName="mb-3"
        className="form-control form-control-lg br-0"
        placeholder=""
        validation={{
          required: true,
          validate: (value) =>
            value === methods.getValues('password') || "Password doesn't match",
          minLength: { value: 8, message: 'Password is too short' },
        }}
      />

      <p className="forgot-password-link">
        <Link to="/login">Already have an account ?</Link>
      </p>

      <button
        type="submit"
        disabled={isLoading}
        to="/dashboard"
        className="btn btn-block btn-danger br-2 btn-lg btn-create-account"
      >
        {isLoading ? <LoadingIcon style={{ height: '30px' }} /> : 'FORGOT PASSWORD'}
      </button>
      {props.children}
    </Form>
  );
};

export default ForgotPasswordForm;
