import { useMutation } from 'react-query';
import { client } from 'utils/api-client';
import gtag from 'ga-gtag';

export default function useSignUp() {
  gtag('event', 'register', { method: 'email' });
  return useMutation((values) =>
    client('register', { data: values }).then((res) => res)
  );
}
