import React from 'react';
import './DiscoverHelpCard.css';
import { Link } from 'react-router-dom/';

const DiscoverHelpCard = ({ title, description, icon, link, contact }) => {
  return (
    <Link className={`help-card ${contact ? 'help-card-help' : ''}`} to={link}>
      <img src={icon} alt={title} className="help-icon" />
      <h3 className="title">{title}</h3>
      <p className="description">{description}</p>
      {contact ? <p className="contact">{contact}</p> : null}
    </Link>
  );
};

export default DiscoverHelpCard;
