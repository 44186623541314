import Home from 'pages/Home';
import Register from 'pages/Register';
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import BuyPage from 'pages/BuyPage';
import RentPage from 'pages/RentPage';
import SellPage from 'pages/SellPage';
import TipsAndGuidesPage from 'pages/TipsAndGuidesPage';
import TipsDetailsPage from 'pages/TipsDetailsPage';
import ProductPage from 'pages/ProductPage';
import UserDashboardPage from 'pages/UserDashboardPage';
import PaymentOptionsPage from 'pages/PaymentOptionsPage';
import PaymentProfilePage from 'pages/PaymentProfilePage';
import PaymentProfileDetailsPage from 'pages/PaymentProfileDetailsPage';
import SubmitListingPage from 'pages/SubmitListing';
import ManageFinancePage from 'pages/ManageFinancePage';
import AffordabilityCalculatorPage from 'pages/AffordabilityCalculatorPage';
import ConfirmAccount from 'pages/ConfirmAccount';
import CategoryPage from 'pages/CategoryPage';
import HomeServicesPage from 'pages/HomeServicesPage';
import ProFormPage from 'pages/JoinHomeServiceProfessionals';
import EditHomeServices from 'pages/EditHomeServices';
import SubscriptionPlanPage from 'pages/SubscriptionPlanPage';
import SubscriptionPlanPage2 from 'pages/SubscriptionPlanPage2';
import PaymentMethodPage from 'pages/PaymentMethodPage';
import PaymentSuccessPage from 'pages/PaymentSuccessPage';
import MyHomeServicesPage from 'pages/MyHomeServicesPage';
import ProfessionalDetailsPage from 'pages/ProfessionalDetailsPage';
import TermsPage from 'pages/TermsPage';
import PrivacyPage from 'pages/PrivacyPage';
import HandlePaymentCallback from 'pages/HandlePaymentCallback';
import SocialAuthCallback from 'pages/SocialAuthCallback';
import AdvertPage from 'pages/AdvertPage';
import AdvertPlansPage from 'pages/AdvertPlansPage';
import PropertyAdvertPlansPage from 'pages/PropertyAdvertPlansPage';
import AdvertUploadPage from 'pages/AdvertUploadPage';
import AdvertUploadVerificationPage from 'pages/AdvertUploadVerificationPage';
import ViewBackgroundInfo from 'pages/viewbackgroundInfo';
import AllProperties from 'pages/AllProperties/AllProperties';
import ApprovalFormPage from 'pages/ApprovalFormPage';
import PropertiesPage from 'pages/PropertiesPage';

export const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/all_properties',
    component: AllProperties,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset_password',
    component: ResetPassword,
  },
  {
    path: '/properties',
    component: PropertiesPage,
  },

  {
    path: '/properties/(buy|rent)',
    component: BuyPage,
  },

  {
    path: '/get-approval',
    component: ApprovalFormPage,
  },
  {
    path: '/rent',
    component: RentPage,
  },
  {
    path: '/sell',
    component: SellPage,
  },
  {
    path: '/tipsandguides/details',
    component: TipsDetailsPage,
  },
  {
    path: '/tipsandguides',
    component: TipsAndGuidesPage,
  },
  {
    path: '/property/:id',
    exact: true,
    component: ProductPage,
  },
  {
    // path: '/property/subscription',
    // exact: true,
    // authRequired: true,
    // component: AdvertPlansPage,
    path: '/property-view/subscription',
    exact: true,
    authRequired: true,
    component: SubscriptionPlanPage2,
  },
  {
    path: '/property-info/viewbgdocs/:id',
    exact: true,
    authRequired: true,
    component: ViewBackgroundInfo,
  },
  {
    path: '/dashboard',
    exact: true,
    authRequired: true,
    component: UserDashboardPage,
  },
  {
    path: '/dashboard/myhomeservices',
    exact: true,
    authRequired: true,
    component: MyHomeServicesPage,
  },
  {
    path: '/finance/paymentoptions',
    component: PaymentOptionsPage,
  },
  {
    path: '/finance/paymentprofile',
    component: PaymentProfilePage,
  },
  // {
  //   path: '/profile/details',
  //   component: ProfileDetails,
  // },
  {
    path: '/finance/paymentprofile',
    component: PaymentProfilePage,
  },
  {
    path: '/dashboard/paymentprofile/details',
    component: PaymentProfileDetailsPage,
  },
  {
    path: '/dashboard/listing/:id?',
    component: SubmitListingPage,
  },
  {
    path: '/finance/affordability',
    component: AffordabilityCalculatorPage,
  },
  {
    path: '/finance',
    exact: true,
    component: ManageFinancePage,
  },
  {
    path: '/account/confirm/token',
    component: ConfirmAccount,
  },
  {
    path: '/services/categories',
    exact: true,
    component: CategoryPage,
  },
  {
    path: '/services',
    component: HomeServicesPage,
  },
  {
    path: '/join_as_pro',
    exact: true,
    component: ProFormPage,
  },
  {
    path: '/home-service/edit',
    component: EditHomeServices,
  },
  {
    path: '/home-service-pro/details',
    exact: true,
    component: ProfessionalDetailsPage,
  },
  {
    path: '/join_as_pro/subscription',
    exact: true,
    component: SubscriptionPlanPage,
  },
  {
    path: '/join_as_pro/subscription/payment',
    exact: true,
    component: PaymentMethodPage,
  },
  {
    path: '/join_as_pro/subscription/payment/success',
    exact: true,
    component: PaymentSuccessPage,
  },
  {
    path: '/terms',
    exact: true,
    component: TermsPage,
  },
  {
    path: '/advert',
    exact: true,
    component: AdvertPage,
  },
  {
    path: '/advert/subscription',
    exact: true,
    component: AdvertPlansPage,
  },
  {
    path: '/property-advert/subscription',
    exact: true,
    component: PropertyAdvertPlansPage,
  },
  {
    path: '/advert/upload',
    exact: true,
    component: AdvertUploadPage,
  },
  {
    path: '/advert/upload/verify',
    exact: true,
    component: AdvertUploadVerificationPage,
  },
  {
    path: '/privacy',
    exact: true,
    component: PrivacyPage,
  },
  {
    path: '/payments/callback',
    component: HandlePaymentCallback,
  },
  {
    path: '/social_auth/(facebook|google)',
    exact: true,
    component: SocialAuthCallback,
  },
];
