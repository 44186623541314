import React from 'react';
// style
import './ModalStyle.css';

const Modal = (props) => {
  const {
    open,
    closeModal,
    children,
    title,
    containerClassName,
    confirmAction,
    confirmActionText,
  } = props;
  return (
    <div
      className={`modal fade custom-modal show ${containerClassName}`}
      id="staticBackdrop"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      style={{ display: `${open ? 'block' : 'none'}` }}
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            />
          </div>
          <div className="modal-body">{children}</div>
          {confirmAction && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmAction}
              >
                {confirmActionText || 'Submit'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
