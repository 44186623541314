import React, { useState } from 'react';
import { Button } from 'components/common/Button';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useFormData } from 'context/approval-form-context';

const EmploymentStatus = ({ setDetailStep }) => {
  const { data, setFormStep, setFormValues } = useFormData();
  const [employmentStatus, setEmploymentStatus] = useState(data.employment_status);
  const [error, setError] = useState('');

  const handleNext = () => {
    if (employmentStatus) {
      setFormValues({ employment_status: employmentStatus });
      setFormStep(3);
    } else {
      setError('Employment status is required');
    }
  };
  const handleBack = () => {
    setDetailStep(2);
  };

  const onSelect = (value) => {
    if (value) {
      setEmploymentStatus(value);
      setError('');
    } else {
      setError('Employment status is required');
    }
  };

  const statuses = [
    'Employed',
    'Self-Employed/1099 Independent Contractor',
    'Retired',
  ];

 

  return (
    <main>
      <div>
        <p className="input-label text-center">
          What is your current employment status?
        </p>
        <div className="d-flex flex-column gap-2 mt-3">
          {statuses.map((status, index) => (
            <div
              onClick={() => onSelect(status)}
              className={`employment-status ${
                employmentStatus == status ? 'active' : ''
              }`}
              key={index}
            >
              {status}
            </div>
          ))}
        </div>
        {error ? <p className="form-error">{error}</p> : null}
      </div>
      <div className="d-flex flex-column justify-content-center mx-auto col-12 col-md-9 gap-5">
        <Button
          onClick={handleNext}
          className="btn btn-dark br-0 w-100 rounded-pill mt-5 no-border"
          type="submit"
        >
          Next
        </Button>

        <button
          onClick={handleBack}
          className="back-button text-center d-flex gap-2 justify-content-center align-items-center"
        >
          <IoIosArrowRoundBack />
          <span className="text-underline">Back</span>
        </button>
      </div>
    </main>
  );
};

export default EmploymentStatus;
