import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import usePageBanner from 'hooks/usePageBanner';


// components
import Navbar from 'components/Navbar';
import SecondaryBanner from 'components/SecondaryBanner';
import PropertyCard from 'components/PropertyCard';
import CategoryFilter from 'components/CategoryFilter';
import PriceFilter from 'components/PriceFilter';
import SalesCard from 'components/SalesCard';
import Footer from 'components/Footer';

// style 
import Pagination from 'components/Pagination';


const RentPage = () => {
  const { data: pageData } = usePageBanner('getRentPageBanner');
  const transactionType = useRouteMatch('/properties/rent') ? 'RENT' : 'BUY';
  return (
    <section className="buypage">
      <Navbar />
      <SecondaryBanner title={transactionType} banner={pageData?.banner} />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 pr-0 pl-0">
            <div className="row mr-0 ml-0">
              <div className="col-md-6">
                <div className="buypage-header">
                  
                </div>
              </div>
            </div>
            <TabProvider defaultTab="recent">
              <div className="my-tabs">
                <div className="container">
                  <div className="newlistings-flex">
                    <span className="newlistings-subheading">
                      Displaying 1 - 12 results 0f 1,224
                    </span>
                    <div className="newlistings-tabs">
                      <TabList className="tablist">
                        <Tab tabFor="recent" className="tab-btn" autofocus>
                          Most Recent
                        </Tab>
                        <Tab tabFor="highest" className="tab-btn">
                          Highest Price
                        </Tab>
                        <Tab tabFor="lowest" className="tab-btn">
                          Lowest Price
                        </Tab>
                      </TabList>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <TabPanel tabId="recent">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1576941089067-2de3c901e126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1143&q=80" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <PropertyCard image="https://images.unsplash.com/photo-1573652636601-d6fdcfc59640?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <PropertyCard image="https://images.unsplash.com/photo-1510627489930-0c1b0bfb6785?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1546062150-7bc3980ad46d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel tabId="highest">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1546062150-7bc3980ad46d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <PropertyCard image="https://images.unsplash.com/photo-1573652636601-d6fdcfc59640?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <PropertyCard image="https://images.unsplash.com/photo-1510627489930-0c1b0bfb6785?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1576941089067-2de3c901e126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1143&q=80" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel tabId="lowest">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1593006525478-f6997e240225?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1496159989940-9139c26fe816?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <PropertyCard image="https://images.unsplash.com/photo-1573652636601-d6fdcfc59640?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <PropertyCard image="https://images.unsplash.com/photo-1510627489930-0c1b0bfb6785?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1546062150-7bc3980ad46d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                        <div className="col-md-6 aux-margin">
                          <SalesCard image="https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </TabProvider>
            <div className="centralize-div">
              <Pagination />
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="card-container">
              <CategoryFilter />
              <PriceFilter />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default RentPage;
