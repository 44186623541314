import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';
import { transformData } from './utils';

export function useJoinHomeService() {
  const cache = useQueryCache();
  return useMutation((values) => {
    return client('services/addServiceProvider', {
      data: transformData(values),
    }).then((res) => {
      cache.invalidateQueries('my-services');
      return res;
    });
  });
}

export function useModifyHomeService() {
  const cache = useQueryCache();

  return useMutation((values) => {
    return client('services/modifyServiceProvider', {
      data: transformData(values),
    }).then((res) => {
      cache.invalidateQueries('my-services');
      return res;
    });
  });
}
