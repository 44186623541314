/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BiEdit } from 'react-icons/bi';
import { HiOutlineTrash } from 'react-icons/hi';
import { useParams, useHistory } from 'react-router-dom';
import useFetchPropertyBackgroundInfo from 'hooks/useFetchPropertyBackgroundInfo';
import gtag from 'ga-gtag';

import { Button } from 'components/common/Button';
import DashboardBanner from 'components/DashboardBanner';
import Loader from 'components/Loader';
import Footer from 'components/Footer';
import Modal from 'components/Modal';
import Navbar from 'components/Navbar';

import '../ProductPage/ProductPageStyle.css';
import './viewbackgroundInfo.css';
import 'components/common/Input/InputStyle.css';

function ViewBackgroundInfo() {
  const [documentAction, setDocumentAction] = React.useState({
    action: '',
    document: null,
  });

  const { id } = useParams();

  const { data, error, isLoading } = useFetchPropertyBackgroundInfo({
    property_id: id,
  });

  const history = useHistory();

  const methods = useForm();

  const trackViewBackgroundInfoPage = () => {
    gtag('event', 'background_info_page', {
      method: 'property_backgroundInfo_page',
    });
  };

  React.useEffect(() => {
    trackViewBackgroundInfoPage();
  }, []);

  const handleDocumentAction = (action = '', document = null) => {
    setDocumentAction({
      action,
      document,
    });
  };

  const renderError = () => (
    <div className="container view-bg-info">
      <div className="container doc-tied-property">
        {error?.Detail === 'The user does not have an active subscription' ? (
          <div className="alert alert-danger mb-2" style={{ textAlign: 'center' }}>
            <p>You don&apos;t have an active subscription</p>
            <button
              type="button"
              onClick={() => history.push('/property-view/subscription')}
            >
              Subscribe
            </button>
          </div>
        ) : (
          <p>{error.Detail}</p>
        )}
      </div>
    </div>
  );

  if (isLoading) {
    return <Loader color="primary" className="p-4" />;
  }

  const { action, document } = documentAction;

  return (
    <>
      <Navbar />
      <DashboardBanner />
      <>
        {error ? (
          renderError()
        ) : (
          <div className="container view-bg-info">
            <div className="container doc-tied-property">
              <h1>Document Tied To The Property</h1>
              <div className="table">
                <table className="table table-striped">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Title</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.backgroundFiles?.map((item, index) => (
                      <tr key={item.id}>
                        <th scope="row">{index + 1}</th>
                        <td onClick={() => handleDocumentAction('view', item)}>
                          Documnet tied to the property
                        </td>
                        <td>
                          <BiEdit
                            onClick={() => handleDocumentAction('edit', item)}
                          />{' '}
                          | <HiOutlineTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <h1>Outstanding Ground Rent</h1>
              <p />
              <p />
              <div className="paymentplan">
                <div className="paymentplan-flex">
                  <div className="paymentplan-item">
                    <span>Outstanding ground rent</span>
                    <p>{data?.outstandingGroundRent}</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h1>Physical Inspection Report</h1>
              <p />
              <p />
              <div className="paymentplan">
                <div className="paymentplan-flex">
                  <div className="paymentplan-item">
                    <span>physical Inspection Report</span>
                    <p>{data?.inspectionReport}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      <Modal
        open={document !== null && action === 'view'}
        closeModal={() => handleDocumentAction()}
        title="Background Document"
      >
        <img width="100%" height="100%" src={document?.doc_link} alt="property" />
      </Modal>
      <Modal
        open={document !== null && action === 'edit'}
        closeModal={() => handleDocumentAction()}
        title="Change Document"
      >
        <Form methods={methods}>
          <div className="input-wrapper">
            <input type="file" />
          </div>
          <p />
          <Button
            type="submit"
            className="ml-2 btn btn-danger app-bg-red br-0 white mb-3 fs-14"
          >
            SAVE CHANGES
          </Button>
        </Form>
      </Modal>
      <Footer />
    </>
  );
}

export default ViewBackgroundInfo;
