import { useMutation } from 'react-query';
import { client } from 'utils/api-client';

export function useSendMessage() {
  return useMutation((values) =>
    client('services/sendMessageToProvider', {
      data: values,
    }).then((res) => {
      return res;
    })
  );
}
