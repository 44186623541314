import React from 'react';
import './HowItWorks.css';
import Card from '../Card';
import { useHistory } from 'react-router-dom';

const HowItWorks = () => {
  const history = useHistory();

  const onContact = () => {
    history.push('/advert');
  };

  return (
    <main className="container">
      <section className="how-it-works">
        <div className="row row-cols-2 p-md-0 justify-content-center align-items-center gap-4">
          <div className="steps col-sm p-md-0">
            <Card
              stepNumber="1"
              title="Find an expert"
              description="Find certified and reliable service professionals anywhere anytime."
            />
            <Card
              stepNumber="2"
              title="Get Matched"
              description="Connect with experts based on your preference and requirements."
            />
            <Card
              stepNumber="3"
              title="Get the job done"
              description="Define scope of work, negotiate, agree and get the job done."
            />
            <Card
              stepNumber="4"
              title="Repeat"
              description="Come back anytime for more."
            />
          </div>
          <div className="col-sm-6 p-md-0 text-section">
            <div className="how-it-works-action text-center  d-flex flex-column justify-content-center align-item-center">
              <h2 className="title">HOW IT WORKS</h2>
              <p className="description">Get professional service in 4 easy steps</p>
              <div>
                <button className="book-service-button" >
                  Book a service
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HowItWorks;
