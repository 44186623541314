import React, { useState, createContext, useContext } from 'react';

const FormContext = createContext();

function FormProvider({ children }) {
  const [data, setData] = useState({
    location: '',
    down_payment: '',
    employment_status: '',
    email: '',
    homeType: '',
    price_range: {
      max: 0,
    },
  });
  const [formStep, setFormStep] = useState(0);

  const setFormValues = (values) => {
    setData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };
  const clearFormValues = () => {
    setData({});
  };

  return (
    <FormContext.Provider
      value={{ data, setFormValues, formStep, setFormStep, clearFormValues }}
    >
      {children}
    </FormContext.Provider>
  );
}

const useFormData = () => useContext(FormContext);

export { FormProvider, useFormData };
