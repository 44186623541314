import React, { useState } from 'react';
import { Input } from 'components/common/Input';
import { Button } from 'components/common/Button';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useFormData } from 'context/approval-form-context';

const EmailAddress = ({ setDetailStep }) => {
  const { data, setFormStep, setFormValues } = useFormData();
  const [email, setEmail] = useState(data.email);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) {
      setEmail(value);
      setError('');
    } else {
      setError('Email is required');
    }
  };

  const handleNext = () => {
    if (email) {
      setFormValues({ email });
      setDetailStep(2);
    } else {
      setError('Email is required');
    }
  };

  const handleBack = () => {
    setFormStep(2);
  };

  return (
    <main>
      <div>
        <p className="input-label">What is your email address?</p>
        <Input
          label=""
          type="text"
          placeholder="example@example.com"
          defaultValue={data.email}
          name="email"
          labelClassName="form-label"
          onChange={handleChange}
          className="form-control"
        />
        {error ? <p className="form-error">{error}</p> : null}
      </div>
      <div className="d-flex flex-column justify-content-center mx-auto col-12 col-md-9 gap-5">
        <Button
          onClick={handleNext}
          className="btn btn-dark br-0 w-100 rounded-pill mt-5 no-border"
          type="submit"
        >
          Next
        </Button>

        <button
          onClick={handleBack}
          className="back-button text-center d-flex gap-2 justify-content-center align-items-center"
        >
          <IoIosArrowRoundBack />
          <span className="text-underline">Back</span>
        </button>
      </div>
    </main>
  );
};

export default EmailAddress;
