import React from 'react';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';

// components
import Comment from 'components/Comment';
import Loader from 'components/Loader';
import Modal from 'components/Modal';

import { useFetchPropertyComments } from 'hooks/useFetchPropertyComments';

function ViewComment(props) {
  const { open, closeModal, propertyId } = props;
  const { data, isLoading } = useFetchPropertyComments({ property_id: propertyId });
  return (
    <div>
      <Modal open={open} title="Comments" closeModal={closeModal}>
        <>
          {isLoading ? (
            <Loader color="primary" />
          ) : (
            <>
              {data?.propertyComments?.length ? (
                <>
                  {data?.propertyComments?.map((commentData) => (
                    <Comment
                      imageUrl="https://react.semantic-ui.com/images/avatar/small/stevie.jpg"
                      name={commentData?.offer_by?.name}
                      date={`${formatDistance(
                        new Date(commentData.date),
                        new Date()
                      )} ago`}
                      text={commentData?.comment}
                    />
                  ))}
                </>
              ) : (
                <div>No Comments</div>
              )}
            </>
          )}
        </>
      </Modal>
    </div>
  );
}

ViewComment.propTypes = {
  propertyId: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

ViewComment.defaultProps = {
  propertyId: 0,
};

export default ViewComment;
