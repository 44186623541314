/* eslint-disable camelcase */
import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useHomeServiceCategoryTypes } from 'hooks/useHomeServiceCategoryTypes';
import { useHomeServiceCategories } from 'hooks/useHomeServiceCategories';

import Form from 'components/common/Form';
import { Input } from 'components/common/Input';
import { Select } from 'components/common/Select';
import { TextField } from 'components/common/TextField';
import { FileInput } from 'components/common/FileInput';
import { Button } from 'components/common/Button';

export default function JoinHomeServiceProfessional({ isLoading, onSubmit }) {
  const { data: homeServiceCategoryData } = useHomeServiceCategories();
  const { data } = useHomeServiceCategoryTypes();
  const { state } = useLocation();

  const getProps = () => {
    const { id, years_of_experience, image_link, ...rest } = state?.service || {};
    return {
      years_of_experience: years_of_experience?.split(' ')[0],
      ...rest,
    };
  };

  const methods = useForm({
    defaultValues: getProps(),
  });

  const isDisabled = state?.viewMode;

  return (
    <Form
      onSubmit={methods.handleSubmit(onSubmit)}
      methods={methods}
      disabled={isDisabled}
    >
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <Select
              label="SELECT SERVICE CATEGORY TYPE"
              name="home_service_category_type_id"
              className="form-select"
              aria-label="Default select example"
              placeholder="SELECT SERVICE CATEGORY"
              options={data?.category_types}
              validation={{ required: true }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <Select
              label="SELECT SERVICE CATEGORY"
              name="home_service_category_id"
              validation={{ required: true }}
              className="form-select"
              aria-label="Default select example"
              placeholder="SELECT SERVICE CATEGORY"
              options={homeServiceCategoryData?.categories}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <Input
              label="ENTER SERVICE TITLE"
              type="text"
              name="service_title"
              labelClassName="form-label"
              validation={{ required: true }}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <TextField
              label="ENTER BUSINESS DESCRIPTION"
              type="text"
              name="business_description"
              labelClassName="form-label"
              className="form-control"
              validation={{ required: true }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <Input
              label="ENTER EMAIL ADDRESS"
              type="email"
              name="business_email"
              labelClassName="form-label"
              className="form-control"
              validation={{ required: true }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <Input
              label="ENTER PHONE NUMBER"
              type="text"
              validation={{
                pattern: /^[0-9]+$/i,
                required: true,
                maxLength: 11,
                minLength: 11,
              }}
              name="business_phone"
              labelClassName="form-label"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <Input
              label="ENTER OFFICE ADDRESS"
              type="text"
              name="business_address"
              labelClassName="form-label"
              className="form-control"
              validation={{ required: true }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <Input
              label="ENTER WEBSITE ADDRESS"
              type="text"
              name="web_address"
              labelClassName="form-label"
              validation={{ required: true }}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <Input
              label="ENTER YEARS OF EXPERIENCE"
              type="NUMBER"
              name="years_of_experience"
              labelClassName="form-label"
              validation={{ required: true }}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <FileInput
              name="image_link"
              preview={[state?.service?.image_link]}
              label="UPLOAD IMAGE"
              validation={{ required: !state?.service?.image_link }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="facebook" className="form-label">
              <div className="socialflex-label">
                <div className="socialflex facebook">
                  <svg
                    width="11"
                    height="22"
                    viewBox="0 0 11 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.023 10.6263H7.16798V21.0858H2.84241V10.6263H0.785156V6.95049H2.84241V4.5718C2.84241 2.87078 3.65043 0.207153 7.20649 0.207153L10.4106 0.220558V3.7886H8.08581C7.70447 3.7886 7.16827 3.97912 7.16827 4.79056V6.95392H10.4009L10.023 10.6263Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <span>Facebook</span>
              </div>
            </label>
            <Input
              type="text"
              name="facebook_link"
              labelClassName="form-label"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="facebook" className="form-label">
              <div className="socialflex-label">
                <div className="socialflex twitter">
                  <svg
                    width="19"
                    height="16"
                    viewBox="0 0 19 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8011 2.49862C18.1154 2.80265 17.3779 3.00829 16.6044 3.10013C17.3941 2.62696 18.0002 1.87828 18.2863 0.984708C17.5473 1.42308 16.7284 1.74109 15.8577 1.91251C15.1601 1.16953 14.1661 0.7052 13.0655 0.7052C10.9537 0.7052 9.24104 2.4179 9.24104 4.52962C9.24104 4.82937 9.27498 5.12115 9.34058 5.40123C6.16217 5.24179 3.34398 3.71933 1.45759 1.40483C1.12845 1.96955 0.939642 2.62696 0.939642 3.32773C0.939642 4.65425 1.61531 5.82534 2.64093 6.51099C2.01432 6.49131 1.42422 6.31932 0.908839 6.03212C0.908554 6.04837 0.908554 6.06463 0.908554 6.0806C0.908554 7.93363 2.22738 9.4792 3.97659 9.83029C3.65601 9.91814 3.31746 9.96463 2.96921 9.96463C2.72222 9.96463 2.48293 9.94095 2.24962 9.89646C2.7362 11.4155 4.14828 12.5213 5.8222 12.5524C4.51307 13.5783 2.86426 14.1895 1.07198 14.1895C0.763951 14.1895 0.458774 14.1715 0.160156 14.1359C1.85175 15.2214 3.8625 15.8543 6.02213 15.8543C13.0566 15.8543 16.9036 10.0268 16.9036 4.97255C16.9036 4.80684 16.8999 4.6417 16.8924 4.47799C17.6403 3.93894 18.2886 3.26555 18.8011 2.49862Z"
                      fill="#F1F2F2"
                    />
                  </svg>
                </div>
                <span>Twitter</span>
              </div>
            </label>
            <Input
              type="text"
              name="twitter_link"
              labelClassName="form-label"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="instagram" className="form-label">
              <div className="socialflex-label">
                <div className="socialflex instagram">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 4.75C6.19063 4.75 4.71875 6.22188 4.71875 8.03125C4.71875 9.84062 6.19063 11.3125 8 11.3125C9.80937 11.3125 11.2812 9.84062 11.2812 8.03125C11.2812 6.22188 9.80937 4.75 8 4.75ZM8 4.75C6.19063 4.75 4.71875 6.22188 4.71875 8.03125C4.71875 9.84062 6.19063 11.3125 8 11.3125C9.80937 11.3125 11.2812 9.84062 11.2812 8.03125C11.2812 6.22188 9.80937 4.75 8 4.75ZM13.6562 0H2.34375C1.05 0 0 1.05 0 2.34375V13.6562C0 14.95 1.05 16 2.34375 16H13.6562C14.95 16 16 14.95 16 13.6562V2.34375C16 1.05 14.95 0 13.6562 0ZM8 12.25C5.67503 12.25 3.78125 10.3562 3.78125 8.03125C3.78125 5.70622 5.67503 3.8125 8 3.8125C10.325 3.8125 12.2188 5.70622 12.2188 8.03125C12.2188 10.3562 10.325 12.25 8 12.25ZM13.1562 3.8125C12.6406 3.8125 12.2188 3.39062 12.2188 2.875C12.2188 2.35938 12.6406 1.9375 13.1562 1.9375C13.6719 1.9375 14.0938 2.35938 14.0938 2.875C14.0938 3.39062 13.6719 3.8125 13.1562 3.8125ZM8 4.75C6.19063 4.75 4.71875 6.22188 4.71875 8.03125C4.71875 9.84062 6.19063 11.3125 8 11.3125C9.80937 11.3125 11.2812 9.84062 11.2812 8.03125C11.2812 6.22188 9.80937 4.75 8 4.75ZM8 4.75C6.19063 4.75 4.71875 6.22188 4.71875 8.03125C4.71875 9.84062 6.19063 11.3125 8 11.3125C9.80937 11.3125 11.2812 9.84062 11.2812 8.03125C11.2812 6.22188 9.80937 4.75 8 4.75ZM8 4.75C6.19063 4.75 4.71875 6.22188 4.71875 8.03125C4.71875 9.84062 6.19063 11.3125 8 11.3125C9.80937 11.3125 11.2812 9.84062 11.2812 8.03125C11.2812 6.22188 9.80937 4.75 8 4.75Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <span>Instagram</span>
              </div>
            </label>
            <Input
              type="text"
              name="instagram_link"
              labelClassName="form-label"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="linkedin" className="form-label">
              <div className="socialflex-label">
                <div className="socialflex linkedin">
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5584 11.2872V17.8836H14.7339V11.7292C14.7339 10.1839 14.1818 9.12865 12.797 9.12865C11.7403 9.12865 11.1126 9.83912 10.8353 10.5271C10.7347 10.7729 10.7087 11.1143 10.7087 11.4591V17.8833H6.88396C6.88396 17.8833 6.9353 7.45985 6.88396 6.38089H10.709V8.01089C10.7013 8.02372 10.6904 8.03627 10.6836 8.04854H10.709V8.01089C11.2172 7.22883 12.1236 6.11079 14.1558 6.11079C16.672 6.11079 18.5584 7.75477 18.5584 11.2872ZM2.88296 0.836304C1.57468 0.836304 0.71875 1.69509 0.71875 2.8234C0.71875 3.92775 1.54987 4.81135 2.83276 4.81135H2.85758C4.19153 4.81135 5.02094 3.92775 5.02094 2.8234C4.99555 1.69509 4.19153 0.836304 2.88296 0.836304ZM0.946066 17.8836H4.76938V6.38089H0.946066V17.8836Z"
                      fill="#F1F2F2"
                    />
                  </svg>
                </div>
                <span>Linkedin</span>
              </div>
            </label>
            <Input
              type="text"
              name="linkedin_link"
              labelClassName="form-label"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="flex-center">
        <Button className="btn btn-danger br-0" type="submit" isLoading={isLoading}>
          Continue
          <span>
            <svg
              width="25"
              height="12"
              viewBox="0 0 25 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.5303 6.53033C24.8232 6.23744 24.8232 5.76256 24.5303 5.46967L19.7574 0.696699C19.4645 0.403806 18.9896 0.403806 18.6967 0.696699C18.4038 0.989593 18.4038 1.46447 18.6967 1.75736L22.9393 6L18.6967 10.2426C18.4038 10.5355 18.4038 11.0104 18.6967 11.3033C18.9896 11.5962 19.4645 11.5962 19.7574 11.3033L24.5303 6.53033ZM0 6.75H24V5.25H0V6.75Z"
                fill="white"
              />
            </svg>
          </span>
        </Button>
      </div>
    </Form>
  );
}

JoinHomeServiceProfessional.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

JoinHomeServiceProfessional.defaultProps = {
  isLoading: false,
};
