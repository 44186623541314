import React from 'react';
import { useTipsAndGuides } from 'hooks/useTipsAndGuides';
import Loader from 'components/Loader';

import TipsCard from '../TipsCard';

// style
import './TipsAndGuidesStyle.css';

const TipsAndGuides = () => {
  const { resolvedData, isLoading } = useTipsAndGuides({ page: 1, per_page: 3 });

  return (
    <section className="tipsandguides">
      <div className="container">

        <h1 className="tipsandguides-header">TIPS AND GUIDES</h1>
          {isLoading ? (

          <Loader color="primary" />
        ) : (
          <div className="row">
            {resolvedData?.tips_and_guides?.data?.map((item) => (
              <div className="col-md-4">
                <TipsCard {...item} />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default TipsAndGuides;
