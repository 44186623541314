import { useMutation, useQuery } from 'react-query';
import { client } from 'utils/api-client';

export function useInitializePayment(paymentPath) {
  return useMutation((data) =>
    client(paymentPath || 'payments/subscriptionPayment', {
      data,
    }).then((res) => {
      return res;
    })
  );
}

export function useVerifyPayment(reference) {
  return useQuery(() =>
    client(`payments/handleGatewayCallback?reference=${reference} `).then((res) => {
      return res;
    })
  );
}
