import React from 'react';
import gtag from 'ga-gtag';

// Style
import './ManageFinancePageStyle.css';

// Components
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import { Link } from 'react-router-dom';
import ManageFinanceBanner from 'components/ManageFinanceBanner';

const ManageFinancePage = () => {
  const trackManageFinancePage = () => {
    gtag('event', 'manageFinance_page', {
      method: 'manageFinance_page',
    });
  };

  React.useEffect(() => {
    trackManageFinancePage();
  }, []);

  return (
    <section className="managefinancepage">
      <Navbar />
      <ManageFinanceBanner />
      <div className="managefinancepage-content">
        <div className="container">
          <div className="userdashboard-tabcontent">
            {/*            <div className="paymentoptions-card">
              <h1>FINANCING OPTIONS</h1>
              <p>
                With reality, starring in our faces, financing your dream may not
                come cheaply but we got you covered. Take advantage of this tool to
                check the various home financing options that is best suited for your
                budget.
              </p>
              <Link to="/finance/paymentoptions" className="btn btn-danger br-0">
                CHECK OPTIONS
              </Link>
            </div> */}
            <div className="row">
              <div className="col-md-6">
                <div className="paymentcalculator-card">
                  <h1>AFFORDABILITY CALCULATOR</h1>
                  <p>
                    Self-assessment is the next smart step toward setting yourself up
                    to achieve your homeownership goal. Use this calculator to
                    determine how much house you can afford. Simply input details of
                    your income, down payment (equity), and the installment option,
                    you can get an estimate of the worth of the property you can
                    afford.
                  </p>
                  <Link to="/finance/affordability" className="btn btn-danger br-0">
                    CALCULATE
                  </Link>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="paymentcalculator-card">
                  <h1>MY PAYMENT PROFILE</h1>
                  <p>
                    This gives you access to your payments’ information, past and
                    pending payments. It provides a statement of your transaction on
                    our platform for your tracking ease and convenient record-
                    keeping.
                  </p>
                  <Link to="finance/paymentprofile" className="btn btn-danger br-0">
                    CHECK PROFILE
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default ManageFinancePage;
